import React, { useState, useEffect } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	withStyles,
	FormControlLabel,
	Checkbox,
	Hidden,
	useMediaQuery,
	useTheme,
	Divider,
} from '@material-ui/core';
import Drawers from '../../components/Drawers';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import flightnotfound from '../../assets/images/flightnotfound.png';
import { AddSearchFlightRoundtripDispatch } from '../../reducers/HomeReducer';
import Login from '../../components/reusableComponents/Login';
import OneWayFlightForm from '../../form/OneWayFlightForm';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import CardFlight from '../../components/reusableComponents/CardFlight';
import InfiniteScroll from 'react-infinite-scroll-component';
import FlightDetailModal from '../../components/reusableComponents/FlightDetailModal';
import { useDispatch } from 'react-redux';
import flightrun from '../../assets/images/flightrun.png';
import { useSelector } from 'react-redux';
import CardFlightReturn from '../../components/reusableComponents/CardFlightReturn';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import Linear from '../../components/reusableComponents/Linear';
import SkeletonOneway from '../../components/reusableComponents/SkeletonOneway';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-between',
		},
	},
	searhform: {
		paddingTop: 42,
		paddingBottom: 10,
		paddingLeft: 20,
		paddingRight: 20,
		backgroundColor: 'midnightblue',
		[theme.breakpoints.down('xs')]: {
			paddingTop: 0,
		},
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
}));

const GreenCheckbox = withStyles({
	root: {
		color: 'rgb(239, 51, 57)',
		'&$checked': {
			color: 'rgb(239, 51, 57)',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const RoundTrip = () => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [expanded, setExpanded] = React.useState('');
	const [data, setData] = useState('');
	const dispatch = useDispatch();
	const [msearch, setMSearch] = useState(false);
	const [filter, setFilter] = useState(false);
	const [stopcheck1, setStopCheck1] = useState(false);
	const [stopcheck2, setStopCheck2] = useState(false);
	const [stopcheck3, setStopCheck3] = useState(false);
	const [stop2check1, setStop2Check1] = useState(false);
	const [stop2check2, setStop2Check2] = useState(false);
	const [stop2check3, setStop2Check3] = useState(false);
	const [refund2, set2Refund] = useState(false);
	const [refund, setRefund] = useState(false);
	const [nrefund2, setN2Refund] = useState(false);
	const [nrefund, setNRefund] = useState(false);
	const [airlineS, setairlineS] = useState(false);
	const [airlineOnward, setairlineOnward] = useState('');
	const [airlineReturn, setairlineReturn] = useState('');

	const [airline2S, setairline2S] = useState(false);
	const [airlineG, setairlineG] = useState(false);
	const [airline2G, setairline2G] = useState(false);
	const [airlineI, setairlineI] = useState(false);
	const [airline2I, setairline2I] = useState(false);
	const [airlineA, setairlineA] = useState(false);
	const [airline2A, setairline2A] = useState(false);
	const [airlineV, setairlineV] = useState(false);
	const [airline2V, setairline2V] = useState(false);
	const [scrollVal, SetScrollVal] = useState(10);
	const [scrollVal2, SetScrollVal2] = useState(10);
	const [fltT, setT] = useState(1);
	const [scrollValResult, SetScrollValResult] = useState(30);
	const [scrollValResult2, SetScrollValResult2] = useState(30);
	const [hasMoreresult, SethasMoreResult] = useState(true);
	const [hasMoreresult2, SethasMoreResult2] = useState(true);
	const [hasMorefilterData, SethasMoreFilterData] = useState(true);
	const [hasMorefilterData2, SethasMoreFilterData2] = useState(true);
	const [result, setResult] = useState(null);
	const [result2, setResult2] = useState(null);
	const [air, setAir] = useState(true);
	const [st, setSt] = useState(true);
	const [stp, setStp] = useState(true);
	const [out, setOut] = useState(true);
	const [rf, setRf] = useState(true);
	const {
		flightreturn,
		loading,
		roundTripEtrav,
		orderID,
		TJKRound,
		currency,
		ltbo,
		letrav,
		loading2,
	} = useSelector((state) => state.home);
	const { flightId, RflightId, selectedOrigin, selectedDepart, modify } =
		useSelector((state) => state.ui);
	const [check, setCheck] = useState(false);
	const [check2, setCheck2] = useState(false);
	const [check3, setCheck3] = useState(false);
	const [check4, setCheck4] = useState(false);
	const [checkR, setCheckR] = useState(false);
	const [check2R, setCheck2R] = useState(false);
	const [check3R, setCheck3R] = useState(false);
	const [check4R, setCheck4R] = useState(false);
	const [rule, SetRule] = useState(false);
	const [rule2, SetRule2] = useState(false);
	const [check5, setCheck5] = useState(false);
	const [check5R, setCheck5R] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [stop1, setStop1] = useState(false);
	const [stop2, setStop2] = useState(false);
	const [stop3, setStop3] = useState(false);
	const [stop1R, setStop1R] = useState(false);
	const [stop2R, setStop2R] = useState(false);
	const [stop3R, setStop3R] = useState(false);
	const [morning, setMorning] = useState(false);
	const [dophar, setDOphar] = useState(false);
	const [value, setValue] = React.useState([1000, 100000]);

	//  Filter Section Starting
	console.log('result', result?.length);
	console.log('scrollValResult', scrollValResult);
	console.log('hasMore', hasMoreresult);
	console.log('result2', result2?.length);
	console.log('scrollValResult', scrollValResult2);
	console.log('hasMore', hasMoreresult2);

	const handleStop = (event) => {
		setStopCheck1(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (stopcheck1) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[0].length > 0 &&
					flightreturn.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === 1) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[0] &&
					roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Segments.length === 1) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound &&
					TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.ONWARD &&
					TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
					TJKRound.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI.length === 1) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handle2Stop = (event) => {
		setStop2Check1(event.target.checked);
		SethasMoreResult2(true);
		SetScrollValResult2(10);
		if (stop2check1) {
			setResult2([]);
			SetRule2(event.target.checked);
		} else {
			SetRule2(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[1].length > 0 &&
					flightreturn.result.Response.Results[1].map((item) => {
						if (item.Segments[0].length === 1) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[1] &&
					roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
						if (item.Segments.length === 1) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound &&
					TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.RETURN &&
					TJKRound.result.searchResult.tripInfos.RETURN.length > 0 &&
					TJKRound.result.searchResult.tripInfos.RETURN.map((item) => {
						if (item.sI.length === 1) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult2(filterDataStop);
		}
	};
	const handleStop2 = (event) => {
		setStopCheck2(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (stopcheck2) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[0].length > 0 &&
					flightreturn.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === 2) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[0] &&
					roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Segments.length === 2) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound &&
					TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.ONWARD &&
					TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
					TJKRound.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI.length === 2) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handle2Stop2 = (event) => {
		setStop2Check2(event.target.checked);
		SethasMoreResult2(true);
		SetScrollValResult2(10);
		if (stop2check2) {
			setResult2([]);
			SetRule2(event.target.checked);
		} else {
			SetRule2(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[1].length > 0 &&
					flightreturn.result.Response.Results[1].map((item) => {
						if (item.Segments[0].length === 2) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[1] &&
					roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
						if (item.Segments.length === 2) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound &&
					TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.RETURN &&
					TJKRound.result.searchResult.tripInfos.RETURN.length > 0 &&
					TJKRound.result.searchResult.tripInfos.RETURN.map((item) => {
						if (item.sI.length === 2) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult2(filterDataStop);
		}
	};
	const handleStop3 = (event) => {
		setStopCheck3(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (stopcheck3) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[0].length > 0 &&
					flightreturn.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length > 2) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[0] &&
					roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Segments.length > 2) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound &&
					TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.ONWARD &&
					TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
					TJKRound.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI.length > 2) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handle2Stop3 = (event) => {
		setStop2Check3(event.target.checked);
		SethasMoreResult2(true);
		SetScrollValResult2(10);
		if (stop2check3) {
			setResult2([]);
			SetRule2(event.target.checked);
		} else {
			SetRule2(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[1].length > 0 &&
					flightreturn.result.Response.Results[1].map((item) => {
						if (item.Segments[0].length > 2) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[1] &&
					roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
						if (item.Segments.length > 2) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound &&
					TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.RETURN &&
					TJKRound.result.searchResult.tripInfos.RETURN.length > 0 &&
					TJKRound.result.searchResult.tripInfos.RETURN.map((item) => {
						if (item.sI.length > 2) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult2(filterDataStop);
		}
	};
	const handleAirline = (event) => {
		SethasMoreResult(true);
		SetScrollValResult(10);
		setairlineOnward(event.target.name);

		if (airlineOnward === event.target.name) {
			setResult([]);
			SetRule(event.target.checked);
			setairlineOnward('');
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturn) {
				flightreturn &&
					flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[0].length > 0 &&
					flightreturn.result.Response.Results[0].map((item) => {
						if (item.AirlineCode === event.target.name) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('filterAilrine', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handle2Airline = (event) => {
		SethasMoreResult2(true);
		SetScrollValResult2(10);
		setairlineReturn(event.target.name);

		if (airlineReturn === event.target.name) {
			setResult2([]);
			SetRule2(event.target.checked);
			setairlineReturn('');
		} else {
			SetRule2(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturn) {
				flightreturn &&
					flightreturn &&
					flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[1].length > 0 &&
					flightreturn.result.Response.Results[1].map((item) => {
						if (item.AirlineCode === event.target.name) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('filterAilrine', filterDataStop);
			setResult2(filterDataStop);
		}
	};

	const handleFareR = (event) => {
		setRefund(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (refund) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[0].length > 0 &&
					flightreturn.result.Response.Results[0].map((item) => {
						if (item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[0] &&
					roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Fares.Refundable) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.ONWARD &&
					TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
					TJKRound.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.totalPriceList.fd.ADULT.rT === 1) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleFareN = (event) => {
		setNRefund(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (nrefund) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[0].length > 0 &&
					flightreturn.result.Response.Results[0].map((item) => {
						if (!item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[0] &&
					roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
						if (!item.Fares.Refundable) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.ONWARD &&
					TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
					TJKRound.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.totalPriceList.fd.ADULT.rT === 0) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handle2FareR = (event) => {
		set2Refund(event.target.checked);
		SethasMoreResult2(true);
		SetScrollValResult2(10);
		if (refund2) {
			setResult2([]);
			SetRule2(event.target.checked);
		} else {
			SetRule2(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[1].length > 0 &&
					flightreturn.result.Response.Results[1].map((item) => {
						if (item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[0] &&
					roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Fares.Refundable) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.ONWARD &&
					TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
					TJKRound.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.totalPriceList.fd.ADULT.rT === 1) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult2(filterDataStop);
		}
	};
	const handle2FareN = (event) => {
		setN2Refund(event.target.checked);
		SethasMoreResult2(true);
		SetScrollValResult2(10);
		if (nrefund2) {
			setResult2([]);
			SetRule2(event.target.checked);
		} else {
			SetRule2(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (flightreturn) {
				flightreturn.result &&
					flightreturn.result.Response &&
					flightreturn.result.Response.Results[0].length > 0 &&
					flightreturn.result.Response.Results[0].map((item) => {
						if (!item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (roundTripEtrav) {
				roundTripEtrav &&
					roundTripEtrav.result &&
					roundTripEtrav.result.TripDetails[0] &&
					roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
					roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
						if (!item.Fares.Refundable) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (TJKRound) {
				TJKRound.result &&
					TJKRound.result.searchResult &&
					TJKRound.result.searchResult.tripInfos &&
					TJKRound.result.searchResult.tripInfos.ONWARD &&
					TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
					TJKRound.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.totalPriceList.fd.ADULT.rT === 0) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult2(filterDataStop);
		}
	};
	const handleCheck = (event) => {
		setCheck(event.target.checked);
		console.log(event.target.name);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO', checkDataTBO);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav.push({
							...item,
						});
					}
				});

			combineflightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('checkDataTBO', checkDataTBO);
			console.log('checkDataEtrav', checkDataEtrav);
			console.log('combineflightData', combineflightData);
			if (check) {
				setResult(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineflightData);
			}
		}
	};
	const handleCheckGO = (event) => {
		setCheck2(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO', checkDataTBO);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav.push({
							...item,
						});
					}
				});

			combineflightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('checkDataTBO', checkDataTBO);
			console.log('checkDataEtrav', checkDataEtrav);
			console.log('combineflightData', combineflightData);
			if (check2) {
				setResult(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineflightData);
			}
		}
	};
	const handleCheckIndigo = (event) => {
		setCheck3(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO', checkDataTBO);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav.push({
							...item,
						});
					}
				});

			combineflightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('checkDataTBO', checkDataTBO);
			console.log('checkDataEtrav', checkDataEtrav);
			console.log('combineflightData', combineflightData);
			if (check3) {
				setResult(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineflightData);
			}
		}
	};
	const handleCheckAir = (event) => {
		setCheck4(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO', checkDataTBO);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav.push({
							...item,
						});
					}
				});

			combineflightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('checkDataTBO', checkDataTBO);
			console.log('checkDataEtrav', checkDataEtrav);
			console.log('combineflightData', combineflightData);
			if (check4) {
				setResult(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineflightData);
			}
		}
	};
	const handleCheckVistara = (event) => {
		setCheck5(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO', checkDataTBO);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav.push({
							...item,
						});
					}
				});

			combineflightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('checkDataTBO', checkDataTBO);
			console.log('checkDataEtrav', checkDataEtrav);
			console.log('combineflightData', combineflightData);
			if (check5) {
				setResult(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineflightData);
			}
		}
	};
	const handleCheckR = (event) => {
		setCheckR(event.target.checked);
		console.log(event.target.name);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];

		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			// console.log('checkDataTBO1', checkDataTBO1);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});

			combineflightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			console.log('checkDataTBO1', checkDataTBO1);
			console.log('checkDataEtrav1', checkDataEtrav1);
			console.log('combineflightData1', combineflightData1);
			if (checkR) {
				setResult2(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineflightData1);
			}
		}
	};
	const handleCheckGOR = (event) => {
		setCheck2R(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];

		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			// console.log('checkDataTBO1', checkDataTBO1);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});

			combineflightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			console.log('checkDataTBO1', checkDataTBO1);
			console.log('checkDataEtrav1', checkDataEtrav1);
			console.log('combineflightData1', combineflightData1);
			if (check2R) {
				setResult2(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineflightData1);
			}
		}
	};
	const handleCheckIndigoR = (event) => {
		setCheck3R(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];

		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			// console.log('checkDataTBO1', checkDataTBO1);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});

			combineflightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			console.log('checkDataTBO1', checkDataTBO1);
			console.log('checkDataEtrav1', checkDataEtrav1);
			console.log('combineflightData1', combineflightData1);
			if (check3R) {
				setResult2(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineflightData1);
			}
		}
	};
	const handleCheckAirR = (event) => {
		setCheck4R(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];

		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			// console.log('checkDataTBO1', checkDataTBO1);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});

			combineflightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			console.log('checkDataTBO1', checkDataTBO1);
			console.log('checkDataEtrav1', checkDataEtrav1);
			console.log('combineflightData1', combineflightData1);
			if (check4R) {
				setResult2(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineflightData1);
			}
		}
	};
	const handleCheckVistaraR = (event) => {
		setCheck5R(event.target.checked);
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];

		if (flightreturn && roundTripEtrav && event.target.name) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.AirlineCode === event.target.name) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			// console.log('checkDataTBO1', checkDataTBO1);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Airline_Code === event.target.name) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});

			combineflightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			console.log('checkDataTBO1', checkDataTBO1);
			console.log('checkDataEtrav1', checkDataEtrav1);
			console.log('combineflightData1', combineflightData1);
			if (check5R) {
				setResult2(null);
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineflightData1);
			}
		}
	};
	const handleOne = () => {
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		if (flightreturn) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.Segments[0].length === 1) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO', checkDataTBO);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Segments.length === 1) {
						checkDataEtrav.push({
							...item,
						});
					}
				});
			console.log('checkDataEtrav', checkDataEtrav);
			combineflightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('combineflightData', combineflightData);
			if (stop1) {
				setResult('');
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineflightData);
			}

			// if (flightreturn) {
			//   flightreturn &&
			//     flightreturn.result &&
			//     flightreturn.result.Response &&
			//     flightreturn.result.Response.Results[1].length > 0 &&
			//     flightreturn.result.Response.Results[1].map((item) => {
			//       if (item.Segments[0].length === 1) {
			//         checkDataTBO1.push({
			//           ...item,
			//         });
			//       }
			//     });
			//   console.log("checkDataTBO1", checkDataTBO1);
			//   roundTripEtrav &&
			//     roundTripEtrav.result &&
			//     roundTripEtrav.result.TripDetails[1] &&
			//     roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
			//     roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
			//       if (item.Segments.length === 1) {
			//         checkDataEtrav1.push({
			//           ...item,
			//         });
			//       }
			//     });
			//   console.log("checkDataEtrav1", checkDataEtrav1);
			//   combineflightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			//   console.log("combineflightData1", combineflightData1);
			// if (stop1) {
			//   setResult2("");
			//   SetScrollVal(10);
			//   SetScrollValResult(10);
			// } else {
			//   setResult2(combineflightData1);
			// }
		}
	};
	const handleTwo = () => {
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineFlightData = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		let combineFlightData1 = [];
		if (flightreturn) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.Segments[0].length === 2) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO', checkDataTBO);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Segments.length === 2) {
						checkDataEtrav.push({
							...item,
						});
					}
				});
			console.log('checkDataEtrav', checkDataEtrav);
			combineFlightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('CombineFlightData', combineFlightData);
			if (stop2) {
				setResult('');
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineFlightData);
			}
		}
		// if (flightreturn) {
		//   flightreturn &&
		//     flightreturn.result &&
		//     flightreturn.result.Response &&
		//     flightreturn.result.Response.Results[1].length > 0 &&
		//     flightreturn.result.Response.Results[1].map((item) => {
		//       if (item.Segments[0].length === 2) {
		//         checkDataTBO1.push({
		//           ...item,
		//         });
		//       }
		//     });
		//   console.log("checkDataTBO1", checkDataTBO1);
		//   roundTripEtrav &&
		//     roundTripEtrav.result &&
		//     roundTripEtrav.result.TripDetails[1] &&
		//     roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
		//     roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
		//       if (item.Segments.length === 2) {
		//         checkDataEtrav1.push({
		//           ...item,
		//         });
		//       }
		//     });
		//   console.log("checkDataEtrav1", checkDataEtrav1);
		//   combineFlightData1 = [...checkDataTBO1, ...checkDataEtrav1];
		//   console.log("CombineFlightData1", combineFlightData1);
		//   if (stop2) {
		//     setResult2("");
		//     SetScrollVal(10);
		//     SetScrollValResult(10);
		//   } else {
		//     setResult2(combineFlightData1);
		//   }
		// }
	};
	const handleThree = () => {
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineFlightData = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		let combineFlightData1 = [];
		if (flightreturn) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[0].length > 0 &&
				flightreturn.result.Response.Results[0].map((item) => {
					if (item.Segments[0].length === 3) {
						checkDataTBO.push({
							...item,
						});
					}
				});
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[0] &&
				roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[0].Flights.map((item) => {
					if (item.Segments.length === 3) {
						checkDataEtrav.push({
							...item,
						});
					}
				});
			console.log('checkDataEtrav', checkDataEtrav);
			combineFlightData = [...checkDataTBO, ...checkDataEtrav];
			console.log('CombineFlightData', combineFlightData);
			if (stop3) {
				setResult('');
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult(combineFlightData);
			}
		}
		// if (flightreturn) {
		//   flightreturn &&
		//     flightreturn.result &&
		//     flightreturn.result.Response &&
		//     flightreturn.result.Response.Results[1].length > 0 &&
		//     flightreturn.result.Response.Results[1].map((item) => {
		//       if (item.Segments[0].length === 3) {
		//         checkDataTBO1.push({
		//           ...item,
		//         });
		//       }
		//     });
		//   roundTripEtrav &&
		//     roundTripEtrav.result &&
		//     roundTripEtrav.result.TripDetails[1] &&
		//     roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
		//     roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
		//       if (item.Segments.length === 3) {
		//         checkDataEtrav1.push({
		//           ...item,
		//         });
		//       }
		//     });
		//   console.log("checkDataEtrav1", checkDataEtrav1);
		//   combineFlightData1 = [...checkDataTBO, ...checkDataEtrav];
		//   console.log("combineFlightData1", combineFlightData1);
		//   if (stop3) {
		//     setResult2("");
		//     SetScrollVal(10);
		//     SetScrollValResult(10);
		//   } else {
		//     setResult2(combineFlightData1);
		//   }
		// }
	};
	const handleOneRound = () => {
		let combineflightData = [];
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineflightData1 = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];

		if (flightreturn) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.Segments[0].length === 1) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO1', checkDataTBO1);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Segments.length === 1) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});
			console.log('checkDataEtrav1', checkDataEtrav1);
			combineflightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			console.log('combineflightData1', combineflightData1);
			if (stop1R) {
				setResult2('');
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineflightData1);
			}
		}
	};
	const handleTwoRound = () => {
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineFlightData = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		let combineFlightData1 = [];

		if (flightreturn) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.Segments[0].length === 2) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			console.log('checkDataTBO1', checkDataTBO1);
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Segments.length === 2) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});
			console.log('checkDataEtrav1', checkDataEtrav1);
			combineFlightData1 = [...checkDataTBO1, ...checkDataEtrav1];
			console.log('CombineFlightData1', combineFlightData1);
			if (stop2R) {
				setResult2('');
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineFlightData1);
			}
		}
	};
	const handleThreeRound = () => {
		let checkDataTBO = [];
		let checkDataEtrav = [];
		let combineFlightData = [];
		let checkDataTBO1 = [];
		let checkDataEtrav1 = [];
		let combineFlightData1 = [];

		if (flightreturn) {
			flightreturn &&
				flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results[1].length > 0 &&
				flightreturn.result.Response.Results[1].map((item) => {
					if (item.Segments[0].length === 3) {
						checkDataTBO1.push({
							...item,
						});
					}
				});
			roundTripEtrav &&
				roundTripEtrav.result &&
				roundTripEtrav.result.TripDetails[1] &&
				roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
				roundTripEtrav.result.TripDetails[1].Flights.map((item) => {
					if (item.Segments.length === 3) {
						checkDataEtrav1.push({
							...item,
						});
					}
				});
			console.log('checkDataEtrav1', checkDataEtrav1);
			combineFlightData1 = [...checkDataTBO, ...checkDataEtrav];
			console.log('combineFlightData1', combineFlightData1);
			if (stop3R) {
				setResult2('');
				SetScrollVal(10);
				SetScrollValResult(10);
			} else {
				setResult2(combineFlightData1);
			}
		}
	};
	// const [result, setResult] = useState([]);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const matches2 = useMediaQuery(theme.breakpoints.down('xs'));
	useEffect(() => {
		if (location.state) {
			dispatch(AddSearchFlightRoundtripDispatch(location.state.returnBody));
			window.scrollTo(0, 0);
		}
	}, [dispatch]);
	console.log('Location', location);
	let flight = [];
	let Returnflight = [];
	if (loading) {
		console.log('loading.............');
	} else {
		if (flightreturn) {
			flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results &&
				flightreturn.result.Response.Results[0].map((app, index) => {
					if (index === flightId) {
						flight.push({
							...app,
						});
						// dispatch(setFlightData(..app))
					}
				});
		}
		if (flightreturn) {
			flightreturn.result &&
				flightreturn.result.Response &&
				flightreturn.result.Response &&
				flightreturn.result.Response.Results &&
				flightreturn.result.Response.Results[1].map((app, index) => {
					if (index === RflightId) {
						Returnflight.push({
							...app,
						});
					}
				});
		}
	}

	let filterData = [];
	filterData =
		flightreturn &&
		flightreturn.result &&
		flightreturn.result.Response &&
		flightreturn.result.Response.Results[0].length > 0 &&
		flightreturn.result.Response.Results[0].slice().sort((a, b) => {
			return a.Fare.OfferedFare - b.Fare.OfferedFare;
		});

	let ReturnfilterData = [];
	ReturnfilterData =
		flightreturn &&
		flightreturn.result &&
		flightreturn.result.Response &&
		flightreturn.result.Response.Results[1].length > 0 &&
		flightreturn.result.Response.Results[1].slice().sort((a, b) => {
			return a.Fare.OfferedFare - b.Fare.OfferedFare;
		});

	let onward = [];
	let returnD = [];
	let combineFlightReturn = [];
	let filterAirlineTBOR = [];
	let filterData5 = [];
	if (loading) {
		console.log('Loading.......');
	} else {
		let tbo =
			flightreturn &&
			flightreturn?.result &&
			flightreturn?.result?.Response &&
			flightreturn?.result?.Response?.Results[1];
		let Etrav =
			roundTripEtrav &&
			roundTripEtrav?.result &&
			roundTripEtrav?.result?.TripDetails &&
			roundTripEtrav?.result?.TripDetails[1] &&
			roundTripEtrav?.result?.TripDetails[1]?.Flights;
		let tjk =
			TJKRound &&
			TJKRound?.result &&
			TJKRound?.result?.searchResult &&
			TJKRound?.result?.searchResult?.tripInfos &&
			TJKRound?.result?.searchResult?.tripInfos?.RETURN;
		if (Etrav?.length > 0 && tbo?.length > 0 && tjk?.length > 0) {
			combineFlightReturn = [...tbo, ...Etrav, ...tjk];
		} else if (Etrav?.length > 0 && tbo?.length > 0) {
			combineFlightReturn = [...tbo, ...Etrav];
		} else if (Etrav?.length > 0 && tjk?.length > 0) {
			combineFlightReturn = [...Etrav, ...tjk];
		} else if (tbo?.length > 0 && tjk?.length > 0) {
			combineFlightReturn = [...tbo, ...tjk];
		} else if (tbo?.length > 0) {
			combineFlightReturn = [...tbo];
		} else if (tjk?.length > 0) {
			combineFlightReturn = [...tjk];
		} else if (Etrav?.length > 0) {
			combineFlightReturn = [...Etrav];
		}
		filterData5 =
			combineFlightReturn &&
			combineFlightReturn.length > 0 &&
			combineFlightReturn.slice().sort((a, b) => {
				let first = a.Fare
					? Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  )
					: a.Fares
					? Number(Math.round(a.Fares.Total_Amount))
					: a.totalPriceList
					? Number(Math.round(a.totalPriceList.fd.Total_Amount))
					: Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  );
				let second = b.Fare
					? Number(
							Number(Math.round(b.Fare.Total_Amount)) -
								Number(Math.round(b.Fare.MFB_Discount))
					  )
					: b.Fares
					? Number(Math.round(b.Fares.Total_Amount))
					: b.totalPriceList
					? Number(Math.round(b.totalPriceList.fd.Total_Amount))
					: Number(Math.round(b.Fare.Total_Amount)) -
					  Number(Math.round(b.Fare.MFB_Discount));
				return first - second;
			});
		if (
			flightreturn &&
			flightreturn?.result &&
			flightreturn?.result?.Response &&
			flightreturn?.result?.Response?.Results[0].length > 0
		) {
			filterAirlineTBOR = [
				...new Set(
					flightreturn &&
						flightreturn?.result &&
						flightreturn?.result?.Response &&
						flightreturn?.result?.Response?.Results[0].length > 0 &&
						flightreturn?.result?.Response?.Results[0].map(
							(item) => item.AirlineCode
						)
				),
			];
		}
	}
	console.log(combineFlightReturn);
	if (loading) {
		console.log('loading.............222');
	} else {
		if (filterData) {
			filterData &&
				filterData.length > 0 &&
				filterData.map((app, index) => {
					if (index === flightId) {
						onward.push({
							...app,
						});
					}
				});
		}
		if (ReturnfilterData) {
			ReturnfilterData &&
				ReturnfilterData.length > 0 &&
				ReturnfilterData.map((app, index) => {
					if (index === RflightId) {
						returnD.push({
							...app,
						});
					}
				});
		}
	}
	let resultSelection = [];
	let resultRSelection = [];
	if (loading) {
		console.log('loading............');
	} else {
		if (result) {
			result &&
				result.length > 0 &&
				result.map((app, index) => {
					if (index === flightId) {
						resultSelection.push({
							...app,
						});
					}
				});
		}
		if (result2) {
			result2 &&
				result2.length > 0 &&
				result2.map((app, index) => {
					if (index === RflightId) {
						resultRSelection.push({
							...app,
						});
						// dispatch(setFlightData(..app))
					}
				});
		}
	}
	console.log('onward', onward);
	console.log('returnD', returnD);

	console.log('flight', flight);
	console.log('Returnflight', Returnflight);
	const fetchMoreData = () => {
		if (ltbo === false && letrav === false) {
			if (scrollVal >= filterData4.length) {
				SethasMoreFilterData(false);
			}
		}

		// a fake async api call like which sends
		// 20 more records in .5 secs
		setTimeout(() => {
			SetScrollVal(scrollVal + 5);
			// this.setState({
			// 	items: this.state.items.concat(Array.from({ length: 20 })),
			// });
		}, 500);
	};
	const fetchMoreData2 = () => {
		if (ltbo === false && letrav === false) {
			if (scrollVal2 >= filterData5.length) {
				SethasMoreFilterData2(false);
			}
		}
		// a fake async api call like which sends
		// 20 more records in .5 secs
		setTimeout(() => {
			SetScrollVal2(scrollVal2 + 5);
			// this.setState({
			// 	items: this.state.items.concat(Array.from({ length: 20 })),
			// });
		}, 500);
	};
	const fetchMoreDataResult = () => {
		if (scrollValResult >= result.length) {
			SethasMoreResult(false);
		}
		// a fake async api call like which sends
		// 20 more records in .5 secs
		setTimeout(() => {
			SetScrollValResult(scrollValResult + 5);
			// this.setState({
			// 	items: this.state.items.concat(Array.from({ length: 20 })),
			// });
		}, 500);
	};
	const fetchMoreDataResult2 = () => {
		if (scrollValResult2 >= result2.length) {
			SethasMoreResult2(false);
		}
		// a fake async api call like which sends
		// 20 more records in .5 secs
		setTimeout(() => {
			SetScrollValResult2(scrollValResult2 + 5);
			// this.setState({
			// 	items: this.state.items.concat(Array.from({ length: 20 })),
			// });
		}, 500);
	};
	let combineFlight = [];
	let filterData4 = [];
	let filterAirlineTBO = [];
	if (loading) {
		console.log('Loading');
	} else {
		let tbo =
			flightreturn &&
			flightreturn?.result &&
			flightreturn?.result?.Response &&
			flightreturn?.result?.Response?.Results[0];
		let Etrav =
			roundTripEtrav &&
			roundTripEtrav?.result &&
			roundTripEtrav?.result?.TripDetails &&
			roundTripEtrav?.result?.TripDetails[0] &&
			roundTripEtrav?.result?.TripDetails[0]?.Flights;
		let tjk =
			TJKRound &&
			TJKRound?.result &&
			TJKRound?.result?.searchResult &&
			TJKRound?.result?.searchResult?.tripInfos &&
			TJKRound?.result?.searchResult?.tripInfos?.ONWARD;
		if (Etrav?.length > 0 && tbo?.length > 0 && tjk?.length > 0) {
			combineFlight = [...tbo, ...Etrav, ...tjk];
		} else if (Etrav?.length > 0 && tbo?.length > 0) {
			combineFlight = [...tbo, ...Etrav];
		} else if (Etrav?.length > 0 && tjk?.length > 0) {
			combineFlight = [...Etrav, ...tjk];
		} else if (tbo?.length > 0 && tjk?.length > 0) {
			combineFlight = [...tbo, ...tjk];
		} else if (tbo?.length > 0) {
			combineFlight = [...tbo];
		} else if (tjk?.length > 0) {
			combineFlight = [...tjk];
		} else if (Etrav?.length > 0) {
			combineFlight = [...Etrav];
		}
		filterData4 =
			combineFlight &&
			combineFlight.length > 0 &&
			combineFlight.slice().sort((a, b) => {
				let first = a.Fare
					? Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  )
					: a.Fares
					? Number(Math.round(a.Fares.Total_Amount))
					: a.totalPriceList
					? Number(Math.round(a.totalPriceList.fd.Total_Amount))
					: Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  );
				let second = b.Fare
					? Number(
							Number(Math.round(b.Fare.Total_Amount)) -
								Number(Math.round(b.Fare.MFB_Discount))
					  )
					: b.Fares
					? Number(Math.round(b.Fares.Total_Amount))
					: b.totalPriceList
					? Number(Math.round(b.totalPriceList.fd.Total_Amount))
					: Number(Math.round(b.Fare.Total_Amount)) -
					  Number(Math.round(b.Fare.MFB_Discount));
				return first - second;
			});
		if (
			flightreturn &&
			flightreturn?.result &&
			flightreturn?.result?.Response &&
			flightreturn?.result?.Response?.Results[0].length > 0
		) {
			filterAirlineTBO = [
				...new Set(
					flightreturn &&
						flightreturn?.result &&
						flightreturn?.result?.Response &&
						flightreturn?.result?.Response?.Results[0].length > 0 &&
						flightreturn?.result?.Response?.Results[0].map(
							(item) => item.AirlineCode
						)
				),
			];
		}
	}

	console.log('filterAirlineTBO', filterAirlineTBO);
	let selectedonward = [];
	let selectedReturn = [];
	if (loading) {
		console.log('loading.............222');
	} else {
		if (filterData4) {
			filterData4 &&
				filterData4.length > 0 &&
				filterData4.map((app, index) => {
					if (index === flightId) {
						selectedonward.push({
							...app,
						});
					}
				});
		}
		if (filterData5) {
			filterData5 &&
				filterData5.length > 0 &&
				filterData5.map((app, index) => {
					if (index === RflightId) {
						selectedReturn.push({
							...app,
						});
						// dispatch(setFlightData(..app))
					}
				});
		}
	}
	console.log('selectedonward', selectedonward);
	console.log('selectedReturn', selectedReturn);
	console.log('FILTERDATA5', filterData5);
	console.log('filterData4', filterData4);
	console.log('rESULT', result);
	return (
		<>
			<HeaderSection id={1} />
			{matches ? (
				<Box
					style={{
						display: 'flex',
						marginTop: '66px',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 10,
					}}>
					<Box onClick={() => (filter ? setFilter(false) : setFilter(true))}>
						<i class='fa-solid fa-filter' style={{ margin: 6, fontSize: 16 }}>
							{' '}
						</i>
						<span style={{ fontSize: 16 }}>Filter</span>
					</Box>

					<Box onClick={() => (msearch ? setMSearch(false) : setMSearch(true))}>
						<i class='fa fa-search' style={{ margin: 6, fontSize: 16 }}></i>
						<span style={{ fontSize: 16 }}>Modify</span>
					</Box>
				</Box>
			) : (
				''
			)}
			{filterData4?.length > 0 && filterData5?.length > 0 ? (
				matches ? (
					msearch ? (
						<Box
							styel={{
								transition: 'all 0.5s ease',
								height: matches && modify ? 590 : '',
							}}
							className={classes.searhform}>
							{/* <Container> */}
							<OneWayFlightForm id={2} />
							{/* </Container> */}
						</Box>
					) : (
						''
					)
				) : (
					<Box
						styel={{
							transition: 'all 0.5s ease',
							height: matches && modify ? 590 : '',
						}}
						className={classes.searhform}>
						{/* <Container> */}
						<OneWayFlightForm id={2} />
						{/* </Container> */}
					</Box>
				)
			) : (
				<Linear />
			)}

			<Box
				style={{
					paddingLeft: matches ? 10 : 50,
					background: '#e5eef4',
					paddingRight: matches ? 10 : 50,
					paddingTop: matches ? 0 : 30,
				}}>
				{filterData4?.length > 0 && filterData5?.length > 0 ? (
					<></>
				) : (
					<marquee direction='right'>
						<img
							src={flightrun}
							style={{ width: 100, height: 32, marginTop: 10 }}
						/>
					</marquee>
				)}
				{filter ? (
					<Hidden only={['lg', 'sm', 'md']}>
						<Grid container>
							<Grid item xs={12}>
								<Box
									style={{
										marginTop: 20,
										padding: 15,
										borderRadius: 5,
										background: 'white',
										boxShadow: '0 3px 30px 0 rgb(0 0 0 / 10%)',
									}}>
									<Box
										mt={'10px'}
										display='flex'
										justifyContent='space-between'
										alignItems='center'>
										<Box display='flex' alignItems='center'>
											<i
												class='fa fa-search'
												style={{ margin: 6, fontSize: 20 }}></i>
											<span style={{ fontSize: 15 }}>Filter</span>
										</Box>
										<p style={{ fontSize: 13 }}>
											<u>Clear all filters</u>
										</p>
									</Box>
									<Box
										justifyContent='space-between'
										display='flex'
										style={{ border: '1px solid grey' }}>
										<Box
											onClick={() => {
												fltT === 1 ? setT(2) : setT(1);
											}}
											style={{
												background: fltT === 1 ? 'blue' : 'white',
												color: fltT === 1 ? 'white' : 'black',
												padding: '11px',
												width: '50%',
												textAlign: 'center',
												cursor: 'pointer',
											}}>
											<span>Onward</span>
										</Box>
										<Box
											onClick={() => {
												fltT === 2 ? setT(1) : setT(2);
											}}
											style={{
												background: fltT === 2 ? 'blue' : 'white',
												color: fltT === 2 ? 'white' : 'black',
												padding: '11px',
												width: '50%',
												textAlign: 'center',
												cursor: 'pointer',
											}}>
											<span>Return</span>
										</Box>
									</Box>
									{fltT === 1 ? (
										<>
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
													marginTop: 20,
												}}
												onClick={() => (stp ? setStp(false) : setStp(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Stops:
												</b>
											</Box>
											{stp ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stopcheck1}
																	onChange={handleStop}
																	name='1'
																/>
															}
															label='Non-Stop'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stopcheck2}
																	onChange={handleStop2}
																	name='2'
																/>
															}
															label='One-Stop'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stopcheck3}
																	onChange={handleStop3}
																	name='3'
																/>
															}
															label='Two & more Stop'
														/>
													</Box>
												</>
											) : (
												''
											)}

											{/* <Divider style={{ marginTop: 20 }} /> */}

											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (air ? setAir(false) : setAir(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Airlines:
												</b>
											</Box>
											{air ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														{filterAirlineTBO &&
															filterAirlineTBO.length > 0 &&
															filterAirlineTBO.map((i) => {
																return (
																	<FormControlLabel
																		control={
																			<GreenCheckbox
																				checked={
																					airlineOnward === i ? true : false
																				}
																				onChange={handleAirline}
																				name={`${i}`}
																			/>
																		}
																		label={
																			<>
																				<span style={{ fontWeight: 'bold' }}>
																					{i}
																				</span>
																				<img
																					width='25px'
																					height='25px'
																					style={{ marginLeft: 5 }}
																					src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
																				/>
																			</>
																		}
																	/>
																);
															})}
													</Box>
												</>
											) : (
												''
											)}

											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (out ? setOut(false) : setOut(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Outbound Flight Times:
												</b>
											</Box>
											{out ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check}
																	onChange={handleCheck}
																	name='SG'
																/>
															}
															label='Morning(04:00-11:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check2}
																	onChange={handleCheckGO}
																	name='G8'
																/>
															}
															label='Afternoon(11:00-16:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check3}
																	onChange={handleCheckIndigo}
																	name='6E'
																/>
															}
															label='Evening(16:00-21:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check4}
																	onChange={handleCheckAir}
																	name='I5'
																/>
															}
															label='Night(21:00-04:00)'
														/>
													</Box>
												</>
											) : (
												''
											)}
											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (rf ? setRf(false) : setRf(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Fare Type
												</b>
											</Box>
											{rf ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={refund}
																	onChange={handleFareR}
																	name='Refundable'
																/>
															}
															label='Refundable'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={nrefund}
																	onChange={handleFareN}
																	name='Non'
																/>
															}
															label='Non-Refundable'
														/>
													</Box>
												</>
											) : (
												''
											)}
										</>
									) : (
										<>
											<Box
												mt={'10px'}
												display='flex'
												justifyContent='space-between'
												alignItems='center'>
												<Box display='flex' alignItems='center'>
													<i
														class='fa fa-search'
														style={{ margin: 6, fontSize: 20 }}></i>
													<span style={{ fontSize: 15 }}>Filter</span>
												</Box>
												<p style={{ fontSize: 13 }}>
													<u>Clear all filters</u>
												</p>
											</Box>

											{/* <Divider /> */}
											{/* <Box style={{background:'#E0DEDE',padding:5}}>
					<b style={{fontWeight:'bold'}}>  <span class="air_down_icon" id="icon_dept" style={{fontSize:14}}>▼</span> Outbound Flight Times:</b>
               

				</Box> */}
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
													marginTop: 20,
												}}
												onClick={() => (stp ? setStp(false) : setStp(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Stops:
												</b>
											</Box>
											{stp ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stop2check1}
																	onChange={handle2Stop}
																/>
															}
															label='Non-Stop'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stop2check2}
																	onChange={handle2Stop2}
																	name='2'
																/>
															}
															label='One-Stop'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stop2check3}
																	onChange={handle2Stop3}
																	name='6E'
																/>
															}
															label='Two & more Stop'
														/>
													</Box>
												</>
											) : (
												''
											)}

											{/* <Divider style={{ marginTop: 20 }} /> */}

											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (air ? setAir(false) : setAir(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Airlines:
												</b>
											</Box>
											{air ? (
												<>
													{filterAirlineTBO &&
														filterAirlineTBO?.length > 0 &&
														filterAirlineTBO?.map((i) => {
															return (
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={
																				airlineOnward === i ? true : false
																			}
																			onChange={handleAirline}
																			name={`${i}`}
																		/>
																	}
																	label={
																		<>
																			<span style={{ fontWeight: 'bold' }}>
																				{i}
																			</span>
																			<img
																				width='25px'
																				height='25px'
																				style={{ marginLeft: 5 }}
																				src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
																			/>
																		</>
																	}
																/>
															);
														})}
												</>
											) : (
												''
											)}

											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (out ? setOut(false) : setOut(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Outbound Flight Times:
												</b>
											</Box>
											{out ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check}
																	onChange={handleCheck}
																	name='SG'
																/>
															}
															label='Morning(04:00-11:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check2}
																	onChange={handleCheckGO}
																	name='G8'
																/>
															}
															label='Afternoon(11:00-16:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check3}
																	onChange={handleCheckIndigo}
																	name='6E'
																/>
															}
															label='Evening(16:00-21:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={check4}
																	onChange={handleCheckAir}
																	name='I5'
																/>
															}
															label='Night(21:00-04:00)'
														/>
													</Box>
												</>
											) : (
												''
											)}
											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (rf ? setRf(false) : setRf(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 14 }}>
														▼
													</span>
													Fare Type
												</b>
											</Box>
											{rf ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={refund2}
																	onChange={handle2FareR}
																	name='Refundable'
																/>
															}
															label='Refundable'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={nrefund2}
																	onChange={handle2FareN}
																	name='Non-Refundable'
																/>
															}
															label='Non-Refundable'
														/>
													</Box>
												</>
											) : (
												''
											)}
										</>
									)}
								</Box>
							</Grid>
						</Grid>
					</Hidden>
				) : (
					''
				)}
				<Grid container style={{ marginBottom: 140 }} spacing={1}>
					<Grid item xs={6} sm={6} lg={7}>
						<Grid container spacing={5}>
							<Hidden only={['xs', 'sm', 'md']}>
								<Grid item lg={5}>
									<Box
										style={{
											marginTop: 20,
											// border: "1px solid rgb(38 38 38 / 27%)",
											padding: 15,
											borderRadius: 5,
											background: 'white',
											boxShadow: '0 3px 30px 0 rgb(0 0 0 / 10%)',
										}}>
										<Box
											justifyContent='space-between'
											display='flex'
											style={{ border: '1px solid grey' }}>
											<Box
												onClick={() => {
													fltT === 1 ? setT(2) : setT(1);
												}}
												style={{
													background: fltT === 1 ? 'blue' : 'white',
													color: fltT === 1 ? 'white' : 'black',
													padding: '11px',
													width: '50%',
													textAlign: 'center',
													cursor: 'pointer',
												}}>
												<span>Onward</span>
											</Box>
											<Box
												onClick={() => {
													fltT === 2 ? setT(1) : setT(2);
												}}
												style={{
													background: fltT === 2 ? 'blue' : 'white',
													color: fltT === 2 ? 'white' : 'black',
													padding: '11px',
													width: '50%',
													textAlign: 'center',
													cursor: 'pointer',
												}}>
												<span>Return</span>
											</Box>
										</Box>
										{fltT === 1 ? (
											<>
												<Box
													mt={'10px'}
													display='flex'
													justifyContent='space-between'
													alignItems='center'>
													<Box display='flex' alignItems='center'>
														<i
															class='fa fa-search'
															style={{ margin: 6, fontSize: 20 }}></i>
														<span style={{ fontSize: 15 }}>Filter</span>
													</Box>
													<p style={{ fontSize: 13 }}>
														<u>Clear all filters</u>
													</p>
												</Box>

												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
														marginTop: 20,
													}}
													onClick={() => (stp ? setStp(false) : setStp(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Stops:
													</b>
												</Box>
												{stp ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stopcheck1}
																		onChange={handleStop}
																		name='1'
																	/>
																}
																label='Non-Stop'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stopcheck2}
																		onChange={handleStop2}
																		name='2'
																	/>
																}
																label='One-Stop'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stopcheck3}
																		onChange={handleStop3}
																		name='3'
																	/>
																}
																label='Two & more Stop'
															/>
														</Box>
													</>
												) : (
													''
												)}

												{/* <Divider style={{ marginTop: 20 }} /> */}

												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (air ? setAir(false) : setAir(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Airlines:
													</b>
												</Box>
												{air ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															{filterAirlineTBO &&
																filterAirlineTBO.length > 0 &&
																filterAirlineTBO.map((i) => {
																	return (
																		<FormControlLabel
																			control={
																				<GreenCheckbox
																					checked={
																						airlineOnward === i ? true : false
																					}
																					onChange={handleAirline}
																					name={`${i}`}
																				/>
																			}
																			label={
																				<>
																					<span style={{ fontWeight: 'bold' }}>
																						{i}
																					</span>
																					<img
																						width='25px'
																						height='25px'
																						style={{ marginLeft: 5 }}
																						src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
																					/>
																				</>
																			}
																		/>
																	);
																})}
														</Box>
													</>
												) : (
													''
												)}

												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (out ? setOut(false) : setOut(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Outbound Flight Times:
													</b>
												</Box>
												{out ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check}
																		onChange={handleCheck}
																		name='SG'
																	/>
																}
																label='Morning(04:00-11:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check2}
																		onChange={handleCheckGO}
																		name='G8'
																	/>
																}
																label='Afternoon(11:00-16:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check3}
																		onChange={handleCheckIndigo}
																		name='6E'
																	/>
																}
																label='Evening(16:00-21:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check4}
																		onChange={handleCheckAir}
																		name='I5'
																	/>
																}
																label='Night(21:00-04:00)'
															/>
														</Box>
													</>
												) : (
													''
												)}
												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (rf ? setRf(false) : setRf(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Fare Type
													</b>
												</Box>
												{rf ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={refund}
																		onChange={handleFareR}
																		name='Refundable'
																	/>
																}
																label='Refundable'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={nrefund}
																		onChange={handleFareN}
																		name='Non'
																	/>
																}
																label='Non-Refundable'
															/>
														</Box>
													</>
												) : (
													''
												)}
											</>
										) : (
											<>
												<Box
													mt={'10px'}
													display='flex'
													justifyContent='space-between'
													alignItems='center'>
													<Box display='flex' alignItems='center'>
														<i
															class='fa fa-search'
															style={{ margin: 6, fontSize: 20 }}></i>
														<span style={{ fontSize: 15 }}>Filter</span>
													</Box>
													<p style={{ fontSize: 13 }}>
														<u>Clear all filters</u>
													</p>
												</Box>

												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
														marginTop: 20,
													}}
													onClick={() => (stp ? setStp(false) : setStp(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Stops:
													</b>
												</Box>
												{stp ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stop2check1}
																		onChange={handle2Stop}
																	/>
																}
																label='Non-Stop'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stop2check2}
																		onChange={handle2Stop2}
																		name='2'
																	/>
																}
																label='One-Stop'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stop2check3}
																		onChange={handle2Stop3}
																		name='6E'
																	/>
																}
																label='Two & more Stop'
															/>
														</Box>
													</>
												) : (
													''
												)}

												{/* <Divider style={{ marginTop: 20 }} /> */}

												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (air ? setAir(false) : setAir(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Airlines:
													</b>
												</Box>
												{air ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															{filterAirlineTBOR &&
																filterAirlineTBOR.length > 0 &&
																filterAirlineTBOR.map((i) => {
																	return (
																		<FormControlLabel
																			control={
																				<GreenCheckbox
																					checked={
																						airlineReturn === i ? true : false
																					}
																					onChange={handle2Airline}
																					name={`${i}`}
																				/>
																			}
																			label={
																				<>
																					<span style={{ fontWeight: 'bold' }}>
																						{i}
																					</span>
																					<img
																						width='25px'
																						height='25px'
																						style={{ marginLeft: 5 }}
																						src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
																					/>
																				</>
																			}
																		/>
																	);
																})}
														</Box>
													</>
												) : (
													''
												)}

												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (out ? setOut(false) : setOut(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Outbound Flight Times:
													</b>
												</Box>
												{out ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check}
																		onChange={handleCheck}
																		name='SG'
																	/>
																}
																label='Morning(04:00-11:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check2}
																		onChange={handleCheckGO}
																		name='G8'
																	/>
																}
																label='Afternoon(11:00-16:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check3}
																		onChange={handleCheckIndigo}
																		name='6E'
																	/>
																}
																label='Evening(16:00-21:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={check4}
																		onChange={handleCheckAir}
																		name='I5'
																	/>
																}
																label='Night(21:00-04:00)'
															/>
														</Box>
													</>
												) : (
													''
												)}
												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (rf ? setRf(false) : setRf(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 14 }}>
															▼
														</span>
														Fare Type
													</b>
												</Box>
												{rf ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={refund2}
																		onChange={handle2FareR}
																		name='Refundable'
																	/>
																}
																label='Refundable'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={nrefund2}
																		onChange={handle2FareN}
																		name='Non-Refundable'
																	/>
																}
																label='Non-Refundable'
															/>
														</Box>
													</>
												) : (
													''
												)}
											</>
										)}
									</Box>
								</Grid>
							</Hidden>

							<Grid
								item
								xs={12}
								lg={7}
								style={{ marginTop: matches ? 20 : '' }}>
								<Box
									justifyContent='space-between'
									alignItems='center'
									display='flex'>
									{matches ? (
										<h3 style={{ fontSize: matches ? 10 : 0 }}>{`Flights from ${
											selectedOrigin.city_name
										} to ${selectedDepart.city_name}(${
											filterData4 ? filterData4.length : 0
										})`}</h3>
									) : (
										<h3 style={{ fontSize: 18 }}>{`Flights from ${
											selectedOrigin.city_name
										} to ${selectedDepart.city_name}(${
											filterData4 ? filterData4.length : 0
										})`}</h3>
									)}
								</Box>
								{/* <Box
										style={{
											padding: 10,
											boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
											background: 'rgba(57,201,187,.14)',
											border: '1px solid #39c9bb',
											color: '#666',
										}}>
										<Box display='flex' justifyContent='space-between'>
											<Box display='flex' alignItems='center'>
												<img src={spice} width='50px' height='25px'></img>
												<Box alignItems='center' ml={'5px'}>
													<TitleText two>SpiceJet</TitleText>
													<BodyText two>SG-2589</BodyText>
												</Box>
											</Box>
											<Box display='flex' alignItems='center'>
												<TitleText>Rs 8054</TitleText>
												<MoreVertIcon />
											</Box>
										</Box>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'>
											<Box>
												<TitleText two>DEL</TitleText>
												<TitleText two>16:40</TitleText>
											</Box>
											<Box alignItems='center' textAlign='center'>
												<TitleText two>1hr 40mins</TitleText>
												<TitleText one>Non Stop</TitleText>
											</Box>
											<Box>
												<TitleText two>AMD</TitleText>
												<TitleText two>19:40</TitleText>
											</Box>
										</Box>
									</Box> */}
								{/* <Box
										style={{
											padding: '6px 10px',
											background: '#f5f6f6',
											borderBottomLeftRadius: '4px',
											borderBottomRightRadius: '4px',
											borderTop: '1px solid #e2e2e2',
											color: '#666',
										}}>
										<Box
											justifyContent='end'
											alignItems='center'
											display='flex'>
											<Box
												onClick={() => dispatch(toggleFlightModal(true))}
												display='flex'>
												<TitleText two>Flight Details</TitleText>
												<ArrowDropDownIcon />
											</Box>
										</Box>
									</Box> */}
								{/* {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => {
										return <CardFlight />;
									})} */}
								{ltbo ? (
									<>
										{[0, 1, 2, 4, 5].map((i) => {
											return <SkeletonOneway />;
										})}
									</>
								) : ltbo ? (
									<>
										{[0, 1, 2, 4, 5].map((i) => {
											return <SkeletonOneway />;
										})}
									</>
								) : rule || (result && result.length > 0) ? (
									result && result.length > 0 ? (
										<InfiniteScroll
											dataLength={scrollValResult}
											next={fetchMoreDataResult}
											hasMore={hasMoreresult}
											loader={<h4>Loading...</h4>}
											endMessage={
												<p style={{ textAlign: 'center' }}>
													<b>No More Data</b>
												</p>
											}>
											{result.map((item, index) => {
												return index < scrollValResult ? (
													<CardFlight item={item} index={index} />
												) : (
													''
												);
											})}
										</InfiniteScroll>
									) : (
										<div
											style={{
												padding: '30px 15px',
												background: 'white',
												borderBottom: '1px solid #e6e6e6',
												textAlign: 'center',
											}}>
											<img src={flightnotfound}></img>
											<p style={{ fontSize: '24px', textAlign: 'center' }}>
												Sorry No Flight Found !!
											</p>
										</div>
									)
								) : filterData4 && filterData4.length > 0 ? (
									<InfiniteScroll
										dataLength={scrollVal}
										next={fetchMoreData}
										hasMore={hasMorefilterData}
										loader={<h4>Loading...</h4>}
										endMessage={
											<p style={{ textAlign: 'center' }}>
												<b>No More Data</b>
											</p>
										}>
										{filterData4.map((item, index) => {
											return index < scrollVal ? (
												<CardFlight
													item={item}
													index={index}
													selectedReturn={selectedonward}
													matches={matches}
												/>
											) : (
												''
											);
										})}
										{/* <></> */}
									</InfiniteScroll>
								) : ltbo === false ? (
									<div
										style={{
											padding: '30px 15px',
											background: 'white',
											borderBottom: '1px solid #e6e6e6',
											textAlign: 'center',
										}}>
										<img src={flightnotfound}></img>
										<p style={{ fontSize: '24px', textAlign: 'center' }}>
											Sorry No Flight Found !!
										</p>
									</div>
								) : (
									<>
										{[0, 1, 2, 4, 5].map((i) => {
											return <SkeletonOneway />;
										})}
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={6}
						sm={6}
						lg={4}
						style={{ marginTop: matches ? 20 : '' }}>
						<Box
							justifyContent='space-between'
							alignItems='center'
							display='flex'>
							{matches ? (
								<h3 style={{ fontSize: matches ? 10 : 0 }}>{`Flights from ${
									selectedDepart.city_name
								} to ${selectedOrigin.city_name}(${
									filterData5 ? filterData5.length : 0
								})`}</h3>
							) : (
								<h3 style={{ fontSize: 18 }}>
									{' '}
									{`Flights from ${selectedDepart.city_name} to ${
										selectedOrigin.city_name
									}(${filterData5 ? filterData5.length : 0})`}
								</h3>
							)}
						</Box>

						{ltbo ? (
							<>
								{[0, 1, 2, 4, 5].map((i) => {
									return <SkeletonOneway />;
								})}
							</>
						) : ltbo ? (
							<>
								{[0, 1, 2, 4, 5].map((i) => {
									return <SkeletonOneway />;
								})}
							</>
						) : rule2 || (result2 && result2.length > 0) ? (
							result2 && result2.length > 0 ? (
								<InfiniteScroll
									dataLength={scrollValResult2}
									next={fetchMoreDataResult2}
									hasMore={hasMoreresult2}
									loader={<h4>Loading...</h4>}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>No More Data</b>
										</p>
									}>
									{result2.map((item, index) => {
										return index < scrollValResult2 ? (
											<CardFlightReturn
												item={item}
												index={index}
												selectedReturn={selectedReturn}
												matches={matches}
											/>
										) : (
											''
										);
									})}
								</InfiniteScroll>
							) : (
								<div
									style={{
										padding: '30px 15px',
										background: 'white',
										borderBottom: '1px solid #e6e6e6',
										textAlign: 'center',
									}}>
									<img src={flightnotfound}></img>
									<p style={{ fontSize: '24px', textAlign: 'center' }}>
										Sorry No Flight Found !!
									</p>
								</div>
							)
						) : filterData5 && filterData5.length > 0 ? (
							<InfiniteScroll
								dataLength={scrollVal2}
								next={fetchMoreData2}
								hasMore={hasMorefilterData2}
								loader={<h4>Loading...</h4>}
								endMessage={
									<p style={{ textAlign: 'center' }}>
										<b>No More Data</b>
									</p>
								}>
								{filterData5.map((item, index) => {
									return index < scrollVal2 ? (
										<CardFlightReturn
											item={item}
											index={index}
											selectedReturn={selectedReturn}
											matches={matches}
										/>
									) : (
										''
									);
								})}
								{/* <></> */}
							</InfiniteScroll>
						) : ltbo === false ? (
							<div
								style={{
									padding: '30px 15px',
									background: 'white',
									borderBottom: '1px solid #e6e6e6',
									textAlign: 'center',
								}}>
								<img src={flightnotfound}></img>
								<p style={{ fontSize: '24px', textAlign: 'center' }}>
									Sorry No Flight Found !!
								</p>
							</div>
						) : (
							<>
								{[0, 1, 2, 4, 5].map((i) => {
									return <SkeletonOneway />;
								})}
							</>
						)}
					</Grid>
				</Grid>
				{/* </Container> */}
			</Box>
			{loading
				? ''
				: filterData4 &&
				  filterData5 && (
						<Box
							style={{
								position: 'fixed',
								bottom: 0,
								left: 0,
								right: 0,
								padding: '10px 0',
								background: 'black',
								color: '#bbb',
								zIndex: 9,
							}}>
							<Container>
								<Grid container>
									<Hidden only={['xs']}>
										<Grid item xs={1} alignItems='center'>
											<Box display='flex'>
												<span
													style={{
														color: 'white',
														fontFamily: 'Josefin Sans, sans-serif',
														fontWeight: 500,
													}}>
													Your Selection
												</span>
												<div
													style={{
														borderLeft: '3px solid grey',
														height: 80,
														marginLeft: 10,
													}}></div>
											</Box>
										</Grid>
									</Hidden>

									{matches ? (
										<>
											<Grid item xs={12}>
												<Box display='flex' justifyContent='space-between'>
													<Box>
														<span
															style={{
																color: 'white',
																fontFamily: 'Josefin Sans, sans-serif',
																fontSize: 10,
															}}>
															Onwards
														</span>
														<span
															style={{
																color: 'white',
																fontFamily: 'Josefin Sans, sans-serif',
																fontSize: 10,
																marginLeft: 10,
															}}>
															<span style={{ marginRight: 5 }}>{'USD'}</span>
															{selectedonward[0]?.Airline_Code
																? Number(
																		Math.round(
																			Number(
																				Math.round(
																					selectedonward[0].Fares.Total_Amount
																				)
																			)
																		)
																  )
																: selectedonward[0]?.AirlineCode
																? Number(
																		Math.round(
																			Number(currency?.conversion_rates?.USD) *
																				Number(
																					Math.round(
																						Number(
																							Number(
																								Math.round(
																									selectedonward[0].Fare
																										.Total_Amount
																								)
																							) -
																								Number(
																									Math.round(
																										selectedonward[0].Fare
																											.MFB_Discount
																									)
																								)
																						)
																					)
																				)
																		)
																  )
																: selectedonward[0]?.Supplier === 'TJK'
																? Number(
																		Math.round(
																			selectedonward[0].totalPriceList.fd
																				.Total_Amount
																		)
																  )
																: ''}
														</span>
														<br></br>
														{loading ? (
															<Skeleton />
														) : (
															<img
																src={`https://nitish.musafirbazar.com/static/media/${
																	selectedonward && selectedonward.length === 1
																		? selectedonward &&
																		  selectedonward[0]?.Airline_Code
																			? selectedonward[0]?.Airline_Code
																			: selectedonward[0]?.AirlineCode
																			? selectedonward[0]?.AirlineCode
																			: selectedonward[0]?.Supplier === 'TJK'
																			? selectedonward[0]?.sI[0].fD.aI.code
																			: ''
																		: ''
																}.gif`}
																width='30px'
																height='25px'></img>
														)}
														<span
															style={{
																color: 'white',
																marginLeft: 10,
																fontSize: 10,
															}}>
															{selectedonward && selectedonward.length === 1
																? selectedonward[0].Airline_Code
																	? selectedonward[0].Segments[0].Departure_DateTime.substr(
																			11,
																			5
																	  )
																	: selectedonward[0].AirlineCode
																	? selectedonward[0].Segments[0].map((i) => {
																			return i.Origin.DepTime.substr(11, 5);
																	  })
																	: selectedonward[0].Supplier === 'TJK'
																	? selectedonward[0].sI[0].dt.substr(11, 5)
																	: ''
																: ''}
														</span>
														<span
															style={{
																color: 'white',
																marginLeft: 5,
																fontSize: 10,
															}}>
															{selectedonward[0]?.Airline_Code
																? selectedonward[0]?.Segments.length === 1
																	? selectedonward[0]?.Segments.filter(
																			(i, index) => index === 0
																	  ).map((it) => {
																			return it.Arrival_DateTime.substr(11, 5);
																	  })
																	: selectedonward[0]?.Segments.length === 2
																	? selectedonward[0]?.Segments.filter(
																			(i, index) => index === 1
																	  ).map((it) => {
																			return it.Arrival_DateTime.substr(11, 5);
																	  })
																	: selectedonward[0]?.Segments.length === 3
																	? selectedonward[0]?.Segments.filter(
																			(i, index) => index === 2
																	  ).map((it) => {
																			return it.Arrival_DateTime.substr(11, 5);
																	  })
																	: ''
																: selectedonward[0]?.AirlineCode
																? selectedonward[0]?.Segments[0].length == 3
																	? selectedonward[0]?.Segments[0]
																			.filter((i, index) => index === 2)
																			.map((i) => {
																				return i.Destination.ArrTime.substr(
																					11,
																					5
																				);
																			})
																	: selectedonward[0]?.Segments[0].length == 2
																	? selectedonward[0]?.Segments[0]
																			.filter((i, index) => index === 1)
																			.map((i) => {
																				return i.Destination.ArrTime.substr(
																					11,
																					5
																				);
																			})
																	: selectedonward[0]?.Segments[0].length == 1
																	? selectedonward[0]?.Segments[0]
																			.filter((i, index) => index === 0)
																			.map((i) => {
																				return i.Destination.ArrTime.substr(
																					11,
																					5
																				);
																			})
																	: ''
																: selectedonward[0]?.Supplier === 'TJK'
																? selectedonward[0]?.sI.length === 1
																	? selectedonward[0].sI
																			.filter((i, index) => index === 0)
																			.map((it) => {
																				return it.at.substr(11, 5);
																			})
																	: selectedonward[0]?.sI.length === 2
																	? selectedonward[0]?.sI
																			.filter((i, index) => index === 1)
																			.map((it) => {
																				return it.at.substr(11, 5);
																			})
																	: selectedonward[0]?.sI.length === 3
																	? selectedonward[0]?.sI
																			.filter((i, index) => index === 2)
																			.map((it) => {
																				return it.at.substr(11, 5);
																			})
																	: ''
																: ''}
														</span>
														<br></br>
														<BodyText
															three
															textStyle={{ color: 'white', fontSize: 8 }}>
															{selectedonward && selectedonward.length === 1
																? selectedonward[0]?.Airline_Code
																	? selectedonward[0]?.Segments[0].Airline_Name
																	: selectedonward[0]?.AirlineCode
																	? selectedonward[0]?.Segments[0]
																			.slice(0, 1)
																			.map((i) => {
																				return i.Airline.AirlineName;
																			})
																	: selectedonward[0].Supplier === 'TJK'
																	? selectedonward[0].sI[0].fD.aI.name
																	: ''
																: ''}
														</BodyText>
														<BodyText
															three
															textStyle={{ color: 'white', fontSize: 8 }}>
															{' '}
															{selectedonward && selectedonward.length === 1
																? selectedonward[0].Airline_Code
																	? selectedonward[0].Airline_Code
																	: selectedonward[0].AirlineCode
																	? selectedonward[0].AirlineCode
																	: selectedonward[0].Supplier === 'TJK'
																	? selectedonward[0].sI[0].fD.aI.code
																	: ''
																: ''}
															-
															{selectedonward && selectedonward.length === 1
																? selectedonward[0].Airline_Code
																	? selectedonward[0].Flight_Numbers.substr(
																			0,
																			8
																	  )
																	: selectedonward[0].AirlineCode
																	? selectedonward[0].Segments[0]
																			.slice(0, 1)
																			.map((i) => {
																				return i.Airline.FlightNumber;
																			})
																	: selectedonward[0].Supplier === 'TJK'
																	? selectedonward[0].sI[0].fD.fN
																	: ''
																: ''}
														</BodyText>
													</Box>
													<Box>
														<span
															style={{
																color: 'white',
																fontFamily: 'Josefin Sans, sans-serif',
																fontSize: 10,
															}}>
															Return
														</span>
														<span
															style={{
																color: 'white',
																fontFamily: 'Josefin Sans, sans-serif',
																fontSize: 10,
																marginLeft: 10,
															}}>
															<span style={{ marginRight: 5 }}>{'USD'}</span>
															{selectedReturn[0]?.Airline_Code
																? Number(
																		Math.round(
																			Number(
																				Math.round(
																					selectedReturn[0].Fares.Total_Amount
																				)
																			)
																		)
																  )
																: selectedReturn[0]?.AirlineCode
																? Number(
																		Math.round(
																			Number(currency?.conversion_rates?.USD) *
																				Number(
																					Math.round(
																						Number(
																							Number(
																								Math.round(
																									selectedReturn[0].Fare
																										.Total_Amount
																								)
																							) -
																								Number(
																									Math.round(
																										selectedReturn[0].Fare
																											.MFB_Discount
																									)
																								)
																						)
																					)
																				)
																		)
																  )
																: selectedReturn[0]?.Supplier === 'TJK'
																? Number(
																		Math.round(
																			selectedReturn[0].totalPriceList.fd
																				.Total_Amount
																		)
																  )
																: ''}
														</span>
														<br></br>
														{loading ? (
															<Skeleton />
														) : (
															<img
																src={`https://nitish.musafirbazar.com/static/media/${
																	selectedReturn && selectedReturn.length === 1
																		? selectedReturn &&
																		  selectedReturn[0]?.Airline_Code
																			? selectedReturn[0]?.Airline_Code
																			: selectedReturn[0]?.AirlineCode
																			? selectedReturn[0]?.AirlineCode
																			: selectedReturn[0]?.Supplier === 'TJK'
																			? selectedReturn[0]?.sI[0].fD.aI.code
																			: ''
																		: ''
																}.gif`}
																width='30px'
																height='25px'></img>
														)}
														<span
															style={{
																color: 'white',
																marginLeft: 10,
																fontSize: 10,
															}}>
															{selectedReturn && selectedReturn.length === 1
																? selectedReturn[0].Airline_Code
																	? selectedReturn[0].Segments[0].Departure_DateTime.substr(
																			11,
																			5
																	  )
																	: selectedReturn[0].AirlineCode
																	? selectedReturn[0].Segments[0].map((i) => {
																			return i.Origin.DepTime.substr(11, 5);
																	  })
																	: selectedReturn[0].Supplier === 'TJK'
																	? selectedReturn[0].sI[0].dt.substr(11, 5)
																	: ''
																: ''}
														</span>
														<span
															style={{
																color: 'white',
																marginLeft: 5,
																fontSize: 10,
															}}>
															{selectedReturn[0]?.Airline_Code
																? selectedReturn[0]?.Segments.length === 1
																	? selectedReturn[0]?.Segments.filter(
																			(i, index) => index === 0
																	  ).map((it) => {
																			return it.Arrival_DateTime.substr(11, 5);
																	  })
																	: selectedReturn[0]?.Segments.length === 2
																	? selectedReturn[0]?.Segments.filter(
																			(i, index) => index === 1
																	  ).map((it) => {
																			return it.Arrival_DateTime.substr(11, 5);
																	  })
																	: selectedReturn[0]?.Segments.length === 3
																	? selectedReturn[0]?.Segments.filter(
																			(i, index) => index === 2
																	  ).map((it) => {
																			return it.Arrival_DateTime.substr(11, 5);
																	  })
																	: ''
																: selectedReturn[0]?.AirlineCode
																? selectedReturn[0]?.Segments[0].length == 3
																	? selectedReturn[0]?.Segments[0]
																			.filter((i, index) => index === 2)
																			.map((i) => {
																				return i.Destination.ArrTime.substr(
																					11,
																					5
																				);
																			})
																	: selectedReturn[0]?.Segments[0].length == 2
																	? selectedReturn[0]?.Segments[0]
																			.filter((i, index) => index === 1)
																			.map((i) => {
																				return i.Destination.ArrTime.substr(
																					11,
																					5
																				);
																			})
																	: selectedReturn[0]?.Segments[0].length == 1
																	? selectedReturn[0]?.Segments[0]
																			.filter((i, index) => index === 0)
																			.map((i) => {
																				return i.Destination.ArrTime.substr(
																					11,
																					5
																				);
																			})
																	: ''
																: selectedReturn[0]?.Supplier === 'TJK'
																? selectedReturn[0]?.sI.length === 1
																	? selectedReturn[0].sI
																			.filter((i, index) => index === 0)
																			.map((it) => {
																				return it.at.substr(11, 5);
																			})
																	: selectedReturn[0]?.sI.length === 2
																	? selectedReturn[0]?.sI
																			.filter((i, index) => index === 1)
																			.map((it) => {
																				return it.at.substr(11, 5);
																			})
																	: selectedReturn[0]?.sI.length === 3
																	? selectedReturn[0]?.sI
																			.filter((i, index) => index === 2)
																			.map((it) => {
																				return it.at.substr(11, 5);
																			})
																	: ''
																: ''}
														</span>
														<br></br>
														<BodyText
															three
															textStyle={{ color: 'white', fontSize: 8 }}>
															{selectedReturn && selectedReturn.length === 1
																? selectedReturn[0]?.Airline_Code
																	? selectedReturn[0]?.Segments[0].Airline_Name
																	: selectedReturn[0]?.AirlineCode
																	? selectedReturn[0]?.Segments[0]
																			.slice(0, 1)
																			.map((i) => {
																				return i.Airline.AirlineName;
																			})
																	: selectedReturn[0].Supplier === 'TJK'
																	? selectedReturn[0].sI[0].fD.aI.name
																	: ''
																: ''}
														</BodyText>
														<BodyText
															three
															textStyle={{ color: 'white', fontSize: 8 }}>
															{' '}
															{selectedReturn && selectedReturn.length === 1
																? selectedReturn[0].Airline_Code
																	? selectedReturn[0].Airline_Code
																	: selectedReturn[0].AirlineCode
																	? selectedReturn[0].AirlineCode
																	: selectedReturn[0].Supplier === 'TJK'
																	? selectedReturn[0].sI[0].fD.aI.code
																	: ''
																: ''}
															-
															{selectedReturn && selectedReturn.length === 1
																? selectedReturn[0].Airline_Code
																	? selectedReturn[0].Flight_Numbers.substr(
																			0,
																			8
																	  )
																	: selectedReturn[0].AirlineCode
																	? selectedReturn[0].Segments[0]
																			.slice(0, 1)
																			.map((i) => {
																				return i.Airline.FlightNumber;
																			})
																	: selectedReturn[0].Supplier === 'TJK'
																	? selectedReturn[0].sI[0].fD.fN
																	: ''
																: ''}
														</BodyText>
													</Box>
													<Box
														onClick={() => {
															history.push({
																pathname: '/summaryflight',
																state: {
																	orderID: orderID?.result,
																	flightData:
																		resultSelection.length == 1
																			? resultSelection
																			: onward.length == 1
																			? onward
																			: '',
																	selectedonward:
																		selectedonward.length == 1
																			? selectedonward
																			: '',
																	selectedReturn:
																		selectedReturn.length == 1
																			? selectedReturn
																			: '',

																	Returnflight:
																		resultRSelection.length == 1
																			? resultRSelection
																			: returnD.length == 1
																			? returnD
																			: '',
																	returnBody: location.state.returnBody,
																	ID:
																		flightreturn &&
																		flightreturn.result &&
																		flightreturn.result.Response &&
																		flightreturn.result.Response.TraceId,
																	IDTBO:
																		flightreturn &&
																		flightreturn.result &&
																		flightreturn.result.Response &&
																		flightreturn.result.Response.TraceId,
																	IDEtrav:
																		roundTripEtrav &&
																		roundTripEtrav.result &&
																		roundTripEtrav.result.Search_Key,
																},
															});
														}}
														textAlign='center'
														style={{
															color: 'white',
															background: 'rgb(239, 51, 57)',
															padding: '16px 18px',
															borderRadius: 8,
															cursor: 'pointer',
															fontFamily: 'Josefin Sans, sans-serif',
															fontWeight: 500,
															boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
														}}>
														<TitleText>Book Now</TitleText>
														{`${'USD'} ${Number(
															Math.round(
																Number(currency?.conversion_rates?.USD) *
																	Number(
																		Math.round(
																			Number(
																				Number(
																					selectedonward[0]?.Airline_Code
																						? Number(
																								Math.round(
																									selectedonward[0]?.Fares
																										.Total_Amount
																								)
																						  )
																						: selectedonward[0]?.AirlineCode
																						? Number(
																								Math.round(
																									selectedonward[0]?.Fare
																										.Total_Amount
																								)
																						  )
																						: //   -
																						//   Number(
																						// 		Math.round(
																						// 			selectedonward[0]?.Fare.MFB_Discount
																						// 		)
																						//   )
																						selectedonward[0]?.Supplier ===
																						  'TJK'
																						? Number(
																								Math.round(
																									selectedonward[0]
																										?.totalPriceList.fd
																										.Total_Amount
																								)
																						  )
																						: ''
																				) +
																					Number(
																						selectedReturn[0]?.Airline_Code
																							? Number(
																									Math.round(
																										selectedReturn[0].Fares
																											.Total_Amount
																									)
																							  )
																							: selectedReturn[0]?.AirlineCode
																							? Number(
																									Math.round(
																										selectedReturn[0].Fare
																											.Total_Amount
																									)
																							  )
																							: //   -
																							//   Number(
																							// 		Math.round(
																							// 			selectedReturn[0].Fare
																							// 				.MFB_Discount
																							// 		)
																							//   )
																							selectedReturn[0]?.Supplier ===
																							  'TJK'
																							? Number(
																									Math.round(
																										selectedReturn[0]
																											.totalPriceList.fd
																											.Total_Amount
																									)
																							  )
																							: ''
																					)
																			)
																		)
																	)
															)
														)}`}
													</Box>
												</Box>
											</Grid>
										</>
									) : (
										<>
											<Grid item xs={4} style={{ padding: 5 }}>
												<Box
													display='flex'
													justifyContent='space-between'
													style={{ paddingLeft: 5 }}>
													<Box>
														{' '}
														<Box>
															<span
																style={{
																	color: 'white',
																	fontFamily: 'Josefin Sans, sans-serif',
																}}>
																Onwards
															</span>
														</Box>
														<Box mt={'5px'}>
															{loading ? (
																<Skeleton />
															) : (
																<img
																	src={`https://nitish.musafirbazar.com/static/media/${
																		selectedonward &&
																		selectedonward.length === 1
																			? selectedonward &&
																			  selectedonward[0]?.Airline_Code
																				? selectedonward[0]?.Airline_Code
																				: selectedonward[0]?.AirlineCode
																				? selectedonward[0]?.AirlineCode
																				: selectedonward[0]?.Supplier === 'TJK'
																				? selectedonward[0]?.sI[0].fD.aI.code
																				: ''
																			: ''
																	}.gif`}
																	width='50px'
																	height='25px'></img>
															)}
														</Box>
													</Box>
													<Box
														display='flex'
														justifyContent='space-between'
														ml={'5px'}>
														<Box>
															<Box display={'flex'}>
																<BodyText
																	three
																	textStyle={{ color: 'white', fontSize: 12 }}>
																	{selectedonward && selectedonward.length === 1
																		? selectedonward[0]?.Airline_Code
																			? selectedonward[0]?.Segments[0]
																					.Airline_Name
																			: selectedonward[0]?.AirlineCode
																			? selectedonward[0]?.Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.AirlineName;
																					})
																			: selectedonward[0].Supplier === 'TJK'
																			? selectedonward[0].sI[0].fD.aI.name
																			: ''
																		: ''}
																</BodyText>
																<div
																	style={{
																		borderLeft: '2px solid grey',
																		height: 20,
																		marginLeft: 10,
																		marginRight: 10,
																	}}></div>
																<BodyText
																	three
																	textStyle={{ color: 'white', fontSize: 12 }}>
																	{' '}
																	{selectedonward && selectedonward.length === 1
																		? selectedonward[0].Airline_Code
																			? selectedonward[0].Airline_Code
																			: selectedonward[0].AirlineCode
																			? selectedonward[0].AirlineCode
																			: selectedonward[0].Supplier === 'TJK'
																			? selectedonward[0].sI[0].fD.aI.code
																			: ''
																		: ''}
																	-
																	{selectedonward && selectedonward.length === 1
																		? selectedonward[0].Airline_Code
																			? selectedonward[0].Flight_Numbers.substr(
																					0,
																					8
																			  )
																			: selectedonward[0].AirlineCode
																			? selectedonward[0].Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.FlightNumber;
																					})
																			: selectedonward[0].Supplier === 'TJK'
																			? selectedonward[0].sI[0].fD.fN
																			: ''
																		: ''}
																</BodyText>
															</Box>
															<Box
																display='flex'
																justifyContent='space-around'
																mt={'10px'}>
																<span style={{ color: 'white' }}>
																	{selectedonward && selectedonward.length === 1
																		? selectedonward[0].Airline_Code
																			? selectedonward[0].Segments[0].Departure_DateTime.substr(
																					11,
																					5
																			  )
																			: selectedonward[0].AirlineCode
																			? selectedonward[0].Segments[0].map(
																					(i) => {
																						return i.Origin.DepTime.substr(
																							11,
																							5
																						);
																					}
																			  )
																			: selectedonward[0].Supplier === 'TJK'
																			? selectedonward[0].sI[0].dt.substr(11, 5)
																			: ''
																		: ''}
																</span>
																<span style={{ color: 'white' }}>
																	{selectedonward[0]?.Airline_Code
																		? selectedonward[0]?.Segments.length === 1
																			? selectedonward[0]?.Segments.filter(
																					(i, index) => index === 0
																			  ).map((it) => {
																					return it.Arrival_DateTime.substr(
																						11,
																						5
																					);
																			  })
																			: selectedonward[0]?.Segments.length === 2
																			? selectedonward[0]?.Segments.filter(
																					(i, index) => index === 1
																			  ).map((it) => {
																					return it.Arrival_DateTime.substr(
																						11,
																						5
																					);
																			  })
																			: selectedonward[0]?.Segments.length === 3
																			? selectedonward[0]?.Segments.filter(
																					(i, index) => index === 2
																			  ).map((it) => {
																					return it.Arrival_DateTime.substr(
																						11,
																						5
																					);
																			  })
																			: ''
																		: selectedonward[0]?.AirlineCode
																		? selectedonward[0]?.Segments[0].length == 3
																			? selectedonward[0]?.Segments[0]
																					.filter((i, index) => index === 2)
																					.map((i) => {
																						return i.Destination.ArrTime.substr(
																							11,
																							5
																						);
																					})
																			: selectedonward[0]?.Segments[0].length ==
																			  2
																			? selectedonward[0]?.Segments[0]
																					.filter((i, index) => index === 1)
																					.map((i) => {
																						return i.Destination.ArrTime.substr(
																							11,
																							5
																						);
																					})
																			: selectedonward[0]?.Segments[0].length ==
																			  1
																			? selectedonward[0]?.Segments[0]
																					.filter((i, index) => index === 0)
																					.map((i) => {
																						return i.Destination.ArrTime.substr(
																							11,
																							5
																						);
																					})
																			: ''
																		: selectedonward[0]?.Supplier === 'TJK'
																		? selectedonward[0]?.sI.length === 1
																			? selectedonward[0].sI
																					.filter((i, index) => index === 0)
																					.map((it) => {
																						return it.at.substr(11, 5);
																					})
																			: selectedonward[0]?.sI.length === 2
																			? selectedonward[0]?.sI
																					.filter((i, index) => index === 1)
																					.map((it) => {
																						return it.at.substr(11, 5);
																					})
																			: selectedonward[0]?.sI.length === 3
																			? selectedonward[0]?.sI
																					.filter((i, index) => index === 2)
																					.map((it) => {
																						return it.at.substr(11, 5);
																					})
																			: ''
																		: ''}
																</span>
															</Box>
														</Box>
													</Box>
													<Box>
														<BodyText three textStyle={{ fontSize: 14 }}>
															{/* {selectedonward && selectedonward.length === 1
                            ? selectedonward[0].Airline_Code
                              ? selectedonward[0].TravelDate
                              : ""
                            : ""} */}
														</BodyText>
														<TitleText
															four
															textStyle={{
																color: 'white',
																marginLeft: 5,
																marginTop: 5,
																fontSize: 16,
															}}>
															<span style={{ marginRight: 5 }}>{'USD'}</span>
															{selectedonward[0]?.Airline_Code
																? Number(
																		Math.round(
																			Number(
																				Math.round(
																					selectedonward[0].Fares.Total_Amount
																				)
																			)
																		)
																  )
																: selectedonward[0]?.AirlineCode
																? Number(
																		Math.round(
																			Number(currency?.conversion_rates?.USD) *
																				Number(
																					Math.round(
																						Number(
																							Number(
																								Math.round(
																									selectedonward[0].Fare
																										.Total_Amount
																								)
																							) -
																								Number(
																									Math.round(
																										selectedonward[0].Fare
																											.MFB_Discount
																									)
																								)
																						)
																					)
																				)
																		)
																  )
																: selectedonward[0]?.Supplier === 'TJK'
																? Number(
																		Math.round(
																			selectedonward[0].totalPriceList.fd
																				.Total_Amount
																		)
																  )
																: ''}
														</TitleText>
													</Box>
													<div
														style={{
															borderLeft: '3px solid grey',
															height: 80,
														}}></div>
												</Box>
											</Grid>
											<Grid item xs={4} style={{ padding: 5 }}>
												<Box
													display='flex'
													justifyContent='space-between'
													style={{ paddingLeft: 5 }}>
													<Box>
														{' '}
														<Box>
															<span
																style={{
																	color: 'white',
																	fontFamily: 'Josefin Sans, sans-serif',
																}}>
																Return
															</span>
														</Box>
														<Box mt={'5px'}>
															{loading ? (
																<Skeleton />
															) : (
																<img
																	src={`https://nitish.musafirbazar.com/static/media/${
																		selectedReturn &&
																		selectedReturn.length === 1
																			? selectedReturn &&
																			  selectedReturn[0].Airline_Code
																				? selectedReturn[0].Airline_Code
																				: selectedReturn[0].AirlineCode
																				? selectedReturn[0].AirlineCode
																				: selectedReturn[0].Supplier === 'TJK'
																				? selectedReturn[0].sI[0].fD.aI.code
																				: ''
																			: ''
																	}.gif`}
																	width='50px'
																	height='25px'></img>
															)}
														</Box>
													</Box>
													<Box
														display='flex'
														justifyContent='space-between'
														ml={'5px'}>
														<Box>
															<Box display={'flex'}>
																<BodyText
																	three
																	textStyle={{ color: 'white', fontSize: 12 }}>
																	{selectedReturn && selectedReturn.length === 1
																		? selectedReturn[0].Airline_Code
																			? selectedReturn[0].Segments[0]
																					.Airline_Name
																			: selectedReturn[0].AirlineCode
																			? selectedReturn[0].Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.AirlineName;
																					})
																			: selectedReturn[0].Supplier === 'TJK'
																			? selectedReturn[0].sI[0].fD.aI.name
																			: ''
																		: ''}
																</BodyText>
																<div
																	style={{
																		borderLeft: '2px solid grey',
																		height: 20,
																		marginLeft: 10,
																		marginRight: 10,
																	}}></div>
																<BodyText
																	three
																	textStyle={{ color: 'white', fontSize: 12 }}>
																	{' '}
																	{selectedReturn && selectedReturn.length === 1
																		? selectedReturn[0].Airline_Code
																			? selectedReturn[0].Airline_Code
																			: selectedReturn[0].AirlineCode
																			? selectedReturn[0].AirlineCode
																			: selectedReturn[0].Supplier === 'TJK'
																			? selectedReturn[0].sI[0].fD.aI.code
																			: ''
																		: ''}
																	-
																	{selectedReturn && selectedReturn.length === 1
																		? selectedReturn[0].Airline_Code
																			? selectedReturn[0].Flight_Numbers.substr(
																					0,
																					8
																			  )
																			: selectedReturn[0].AirlineCode
																			? selectedReturn[0].Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.FlightNumber;
																					})
																			: selectedReturn[0].Supplier === 'TJK'
																			? selectedReturn[0].sI[0].dt.substr(11, 5)
																			: ''
																		: ''}
																</BodyText>
															</Box>
															<Box
																display='flex'
																justifyContent='space-around'
																mt={'10px'}>
																<span style={{ color: 'white' }}>
																	{selectedReturn && selectedReturn.length === 1
																		? selectedReturn[0].Airline_Code
																			? selectedReturn[0].Segments[0].Departure_DateTime.substr(
																					11,
																					5
																			  )
																			: selectedReturn[0].AirlineCode
																			? selectedReturn[0].Segments[0].map(
																					(i) => {
																						return i.Origin.DepTime.substr(
																							11,
																							5
																						);
																					}
																			  )
																			: selectedReturn[0].Supplier === 'TJK'
																			? selectedReturn[0].sI[0].dt.substr(11, 5)
																			: ''
																		: ''}
																</span>
																<span style={{ color: 'white' }}>
																	{selectedReturn[0]?.Airline_Code
																		? selectedReturn[0]?.Segments.length === 1
																			? selectedReturn[0]?.Segments.filter(
																					(i, index) => index === 0
																			  ).map((it) => {
																					return it.Arrival_DateTime.substr(
																						11,
																						5
																					);
																			  })
																			: selectedReturn[0]?.Segments.length === 2
																			? selectedReturn[0]?.Segments.filter(
																					(i, index) => index === 1
																			  ).map((it) => {
																					return it.Arrival_DateTime.substr(
																						11,
																						5
																					);
																			  })
																			: selectedReturn[0]?.Segments.length === 3
																			? selectedReturn[0]?.Segments.filter(
																					(i, index) => index === 2
																			  ).map((it) => {
																					return it.Arrival_DateTime.substr(
																						11,
																						5
																					);
																			  })
																			: ''
																		: selectedReturn[0]?.AirlineCode
																		? selectedReturn[0]?.Segments[0].length == 3
																			? selectedReturn[0]?.Segments[0]
																					.filter((i, index) => index === 2)
																					.map((i) => {
																						return i.Destination.ArrTime.substr(
																							11,
																							5
																						);
																					})
																			: selectedReturn[0]?.Segments[0].length ==
																			  2
																			? selectedReturn[0]?.Segments[0]
																					.filter((i, index) => index === 1)
																					.map((i) => {
																						return i.Destination.ArrTime.substr(
																							11,
																							5
																						);
																					})
																			: selectedReturn[0]?.Segments[0].length ==
																			  1
																			? selectedReturn[0]?.Segments[0]
																					.filter((i, index) => index === 0)
																					.map((i) => {
																						return i.Destination.ArrTime.substr(
																							11,
																							5
																						);
																					})
																			: ''
																		: selectedReturn[0]?.Supplier === 'TJK'
																		? selectedReturn[0]?.sI.length === 1
																			? selectedReturn[0].sI
																					.filter((i, index) => index === 0)
																					.map((it) => {
																						return it.at.substr(11, 5);
																					})
																			: selectedReturn[0].sI.length === 2
																			? selectedReturn[0].sI
																					.filter((i, index) => index === 1)
																					.map((it) => {
																						return it.at.substr(11, 5);
																					})
																			: selectedReturn[0].sI.length === 3
																			? selectedReturn[0].sI
																					.filter((i, index) => index === 2)
																					.map((it) => {
																						return it.at.substr(11, 5);
																					})
																			: ''
																		: ''}
																</span>
															</Box>
														</Box>
													</Box>
													<Box>
														<BodyText three textStyle={{ fontSize: 14 }}>
															{/* {selectedReturn && selectedReturn.length === 1
                            ? selectedReturn[0].Airline_Code
                              ? selectedReturn[0].TravelDate
                              : ""
                            : ""} */}
														</BodyText>
														<TitleText
															four
															textStyle={{
																color: 'white',
																marginLeft: 5,
																marginTop: 5,
																fontSize: 16,
															}}>
															<span style={{ marginRight: 5 }}>{'USD'}</span>
															{selectedReturn[0]?.Airline_Code
																? Number(
																		Math.round(
																			Number(
																				Math.round(
																					selectedReturn[0].Fares.Total_Amount
																				)
																			)
																		)
																  )
																: selectedReturn[0]?.AirlineCode
																? Number(
																		Math.round(
																			Number(currency?.conversion_rates?.USD) *
																				Number(
																					Math.round(
																						Number(
																							Number(
																								Math.round(
																									selectedReturn[0].Fare
																										.Total_Amount
																								)
																							) -
																								Number(
																									Math.round(
																										selectedReturn[0].Fare
																											.MFB_Discount
																									)
																								)
																						)
																					)
																				)
																		)
																  )
																: selectedReturn[0]?.Supplier === 'TJK'
																? Number(
																		Math.round(
																			selectedReturn[0].totalPriceList.fd
																				.Total_Amount
																		)
																  )
																: ''}
														</TitleText>
													</Box>
													<div
														style={{
															borderLeft: '3px solid grey',
															height: 80,
														}}></div>
												</Box>
											</Grid>
											<Grid item xs={3} style={{ padding: 5 }}>
												<Box display='flex' justifyContent='space-between'>
													<Box>
														<span
															style={{
																color: 'white',
																marginLeft: 5,
																fontFamily: 'Josefin Sans, sans-serif',
																fontWeight: 500,
															}}>
															Total Amount
														</span>

														<Box>
															<span
																style={{
																	color: '#f56d6d',
																	marginLeft: 5,
																	fontSize: 20,
																}}>
																{`${'USD'} ${Number(
																	Math.round(
																		Number(currency?.conversion_rates?.USD) *
																			Number(
																				Math.round(
																					Number(
																						Number(
																							selectedonward[0]?.Airline_Code
																								? Number(
																										Math.round(
																											selectedonward[0]?.Fares
																												.Total_Amount
																										)
																								  )
																								: selectedonward[0]?.AirlineCode
																								? Number(
																										Math.round(
																											selectedonward[0]?.Fare
																												.Total_Amount
																										)
																								  ) -
																								  Number(
																										Math.round(
																											selectedonward[0]?.Fare
																												.MFB_Discount
																										)
																								  )
																								: selectedonward[0]
																										?.Supplier === 'TJK'
																								? Number(
																										Math.round(
																											selectedonward[0]
																												?.totalPriceList.fd
																												.Total_Amount
																										)
																								  )
																								: ''
																						) +
																							Number(
																								selectedReturn[0]?.Airline_Code
																									? Number(
																											Math.round(
																												selectedReturn[0].Fares
																													.Total_Amount
																											)
																									  )
																									: selectedReturn[0]
																											?.AirlineCode
																									? Number(
																											Math.round(
																												selectedReturn[0].Fare
																													.Total_Amount
																											)
																									  ) -
																									  Number(
																											Math.round(
																												selectedReturn[0].Fare
																													.MFB_Discount
																											)
																									  )
																									: selectedReturn[0]
																											?.Supplier === 'TJK'
																									? Number(
																											Math.round(
																												selectedReturn[0]
																													.totalPriceList.fd
																													.Total_Amount
																											)
																									  )
																									: ''
																							)
																					)
																				)
																			)
																	)
																)}`}
															</span>
														</Box>
													</Box>

													{loading ? (
														<Skeleton width='100px' height='50px' />
													) : (
														<Box
															onClick={() => {
																history.push({
																	pathname: '/summaryflight',
																	state: {
																		orderID: orderID?.result,
																		flightData:
																			resultSelection.length == 1
																				? resultSelection
																				: onward.length == 1
																				? onward
																				: '',
																		selectedonward:
																			selectedonward.length == 1
																				? selectedonward
																				: '',
																		selectedReturn:
																			selectedReturn.length == 1
																				? selectedReturn
																				: '',

																		Returnflight:
																			resultRSelection.length == 1
																				? resultRSelection
																				: returnD.length == 1
																				? returnD
																				: '',
																		returnBody: location.state.returnBody,
																		ID:
																			flightreturn &&
																			flightreturn.result &&
																			flightreturn.result.Response &&
																			flightreturn.result.Response.TraceId,
																		IDTBO:
																			flightreturn &&
																			flightreturn.result &&
																			flightreturn.result.Response &&
																			flightreturn.result.Response.TraceId,
																		IDEtrav:
																			roundTripEtrav &&
																			roundTripEtrav.result &&
																			roundTripEtrav.result.Search_Key,
																	},
																});
															}}
															textAlign='center'
															style={{
																color: 'white',
																background: 'rgb(239, 51, 57)',
																padding: '16px 18px',
																borderRadius: 8,
																cursor: 'pointer',
																fontFamily: 'Josefin Sans, sans-serif',
																fontWeight: 500,
																boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
															}}>
															<TitleText>Book Now</TitleText>
														</Box>
													)}
												</Box>
											</Grid>
										</>
									)}
								</Grid>
							</Container>
						</Box>
				  )}
			<FlightDetailModal />
			<Drawers />
			<Login />
		</>
	);
};

export default RoundTrip;
