import React, { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	makeStyles,
	withStyles,
	FormControlLabel,
	Checkbox,
	Hidden,
	useMediaQuery,
	useTheme,
	Divider,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import { toggleFlightModal, setFlightId } from '../../reducers/UiReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
	SearchReturnFormIntDispatch,
	SearchReturnEtravFormDispatch,
	AddSearchFlightDispatch,
	FlightFareRuleDispatch,
} from '../../reducers/HomeReducer';
import Slider from '@mui/material/Slider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory, useLocation } from 'react-router-dom';
import SkeletonOneway from '../../components/reusableComponents/SkeletonOneway';
import OneWayFlightForm from '../../form/OneWayFlightForm';
import FlightDetailModal from '../../components/reusableComponents/FlightDetailModal';
import Linear from '../../components/reusableComponents/Linear';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-between',
		},
	},
	searhform: {
		paddingTop: 40,
		paddingBottom: 10,
		paddingLeft: 50,
		paddingRight: 50,
		backgroundColor: 'midnightblue',
		[theme.breakpoints.down('md')]: {
			height: 508,
		},
		[theme.breakpoints.down('xs')]: {
			height: 590,
		},
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
}));
const GreenCheckbox = withStyles({
	root: {
		color: 'rgb(239, 51, 57)',
		'&$checked': {
			color: 'rgb(239, 51, 57)',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const International = () => {
	const {
		flightreturnInt,
		loading,
		orderID,
		currency,
		lint,
		ltbo,
		loading2,
		etravInt,
	} = useSelector((state) => state.home);
	const { selectedOrigin, selectedDepart, curr } = useSelector(
		(state) => state.ui
	);
	const [value, setValue] = React.useState([1000, 100000]);
	const [scrollVal, SetScrollVal] = useState(10);
	const [data, setData] = useState(false);
	const history = useHistory();
	const theme = useTheme();
	const [rule, SetRule] = useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [out, setOut] = useState(true);
	const [rf, setRf] = useState(true);
	const [stopcheck1, setStopCheck1] = useState(false);
	const [stopcheck2, setStopCheck2] = useState(false);
	const [stopcheck3, setStopCheck3] = useState(false);
	const [info, setInfo] = useState('fare');
	const classes = useStyles();
	const [result, setResult] = useState([]);
	const [airlineS, setairlineS] = useState('');
	const [airlineG, setairlineG] = useState(false);
	const [airlineI, setairlineI] = useState(false);
	const [airlineA, setairlineA] = useState(false);
	const [airlineV, setairlineV] = useState(false);
	const [refund, setRefund] = useState(false);
	const [nrefund, setNRefund] = useState(false);
	const dispatch = useDispatch();
	const [stp, setStp] = useState(true);
	const [air, setAir] = useState(true);
	const [scrollValResult, SetScrollValResult] = useState(10);
	const [hasMoreresult, SethasMoreResult] = useState(true);
	const [hasMorefilterData, SethasMoreFilterData] = useState(true);

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handleInfo = (value) => {
		setInfo(value);
	};
	const fetchMoreData = () => {
		if (scrollVal >= filterData.length) {
			SethasMoreFilterData(false);
		}

		setTimeout(() => {
			SetScrollVal(scrollVal + 5);
		}, 500);
	};
	const fetchMoreDataResult = () => {
		if (scrollValResult >= 200) {
			SethasMoreResult(false);
		}
		setTimeout(() => {
			SetScrollValResult(scrollValResult + 5);
		}, 500);
	};
	const handleStop = (event) => {
		setStopCheck1(event.target.checked);

		if (stopcheck1) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturnInt) {
				flightreturnInt &&
					flightreturnInt.result &&
					flightreturnInt.result.Response &&
					flightreturnInt.result.Response.Results[0].length > 0 &&
					flightreturnInt.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === 1) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleStop2 = (event) => {
		setStopCheck2(event.target.checked);

		if (stopcheck2) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturnInt) {
				flightreturnInt &&
					flightreturnInt.result &&
					flightreturnInt.result.Response &&
					flightreturnInt.result.Response.Results[0].length > 0 &&
					flightreturnInt.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === 2) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleStop3 = (event) => {
		setStopCheck3(event.target.checked);

		if (stopcheck3) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturnInt) {
				flightreturnInt &&
					flightreturnInt.result &&
					flightreturnInt.result.Response &&
					flightreturnInt.result.Response.Results[0].length > 0 &&
					flightreturnInt.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length > 2) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleAirline = (event) => {
		setairlineS(event.target.name);
		if (airlineS === event.target.name) {
			setResult([]);
			SetRule(event.target.checked);
			setairlineS('');
		} else {
			SetRule(event.target.checked);
			console.log(event.target.name);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturnInt) {
				flightreturnInt &&
					flightreturnInt.result &&
					flightreturnInt.result.Response &&
					flightreturnInt.result.Response.Results[0].length > 0 &&
					flightreturnInt.result.Response.Results[0].map((item) => {
						if (item.AirlineCode === event.target.name) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('filterAilrine', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleFareR = (event) => {
		setRefund(event.target.checked);

		if (refund) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturnInt) {
				flightreturnInt &&
					flightreturnInt.result &&
					flightreturnInt.result.Response &&
					flightreturnInt.result.Response.Results[0].length > 0 &&
					flightreturnInt.result.Response.Results[0].map((item) => {
						if (item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleFareN = (event) => {
		setNRefund(event.target.checked);

		if (nrefund) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];

			if (flightreturnInt) {
				flightreturnInt &&
					flightreturnInt.result &&
					flightreturnInt.result.Response &&
					flightreturnInt.result.Response.Results[0].length > 0 &&
					flightreturnInt.result.Response.Results[0].map((item) => {
						if (!item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}

			filterCombineStop = [...tbo];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const timedifference = (t1, t2) => {
		const date1 = new Date(t1);
		const date2 = new Date(t2);
		const diff = date2.getTime() - date1.getTime();
		return Math.abs(Math.floor(diff / 1000 / 60));
	};
	const location = useLocation();
	console.log('lo', location);
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	let filterData = [];
	let filterDataNEW = [];
	let filterAirline = [];
	let combineflightData = [];
	let flt = [];
	if (lint) {
		console.log('lint');
	} else {
		etravInt &&
			etravInt?.result &&
			etravInt?.result?.TripDetails &&
			etravInt?.result?.TripDetails[0]?.Flights.length > 0 &&
			etravInt?.result?.TripDetails[0]?.Flights.map((i) => {
				filterDataNEW.push({
					...i,
					ONWARD: i.Segments.filter((f) => f.Return_Flight === false),
					RETURN: i.Segments.filter((f) => f.Return_Flight === true),
				});
			});
		let tbo =
			// [];
			flightreturnInt &&
			flightreturnInt?.result &&
			flightreturnInt?.result?.Response &&
			flightreturnInt?.result?.Response?.Results[0].length > 0 &&
			flightreturnInt?.result?.Response?.Results[0];
		let Etrav = filterDataNEW.length > 0 ? filterDataNEW : [];

		if (Etrav?.length > 0 && tbo?.length > 0) {
			combineflightData = [...tbo, ...Etrav];
		} else if (Etrav?.length > 0 && tbo?.length > 0) {
			combineflightData = [...tbo, ...Etrav];
		} else if (tbo?.length > 0) {
			combineflightData = [...tbo];
		} else if (Etrav?.length > 0) {
			combineflightData = [...Etrav];
		}
		filterData =
			combineflightData &&
			combineflightData.length > 0 &&
			combineflightData.slice().sort((a, b) => {
				let first = a.Fare
					? Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  )
					: a.Fares
					? Number(Math.round(a.Fares.Total_Amount))
					: a.totalPriceList
					? Number(Math.round(a.totalPriceList.fd.Total_Amount))
					: Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  );
				let second = b.Fare
					? Number(
							Number(Math.round(b.Fare.Total_Amount)) -
								Number(Math.round(b.Fare.MFB_Discount))
					  )
					: b.Fares
					? Number(Math.round(b.Fares.Total_Amount))
					: b.totalPriceList
					? Number(Math.round(b.totalPriceList.fd.Total_Amount))
					: Number(Math.round(b.Fare.Total_Amount)) -
					  Number(Math.round(b.Fare.MFB_Discount));
				return first - second;
			});
		filterAirline = [
			...new Set(
				flightreturnInt &&
					flightreturnInt?.result &&
					flightreturnInt?.result?.Response &&
					flightreturnInt?.result?.Response?.Results[0].length > 0 &&
					flightreturnInt?.result?.Response?.Results[0].map(
						(item) => item.AirlineCode
					)
			),
		];
		// if (tbo?.length > 0) {
		// 	filterData = [...tbo];

		// }
		console.log('tbo', tbo);
		console.log('etrav', Etrav);
		console.log('combineflightData', combineflightData);
	}
	console.log('filterDataNEW', filterDataNEW);
	// console.log("filterData",filterData)
	useEffect(() => {
		if (location.state) {
			// dispatch(SearchReturnFormIntDispatch(location.state.returnBody));
			// dispatch(SearchReturnEtravFormDispatch(location.state.returnBody));
			dispatch(AddSearchFlightDispatch(location.state.returnBody));
			window.scrollTo(0, 0);
		}
	}, [dispatch]);
	console.log('filterData', filterData);
	console.log('filterAirline', filterAirline);
	return (
		<>
			<HeaderSection id={1} />
			{!lint ? (
				<Box
					styel={{ transition: 'all 0.5s ease' }}
					className={classes.searhform}>
					{/* <Container> */}
					<OneWayFlightForm id={3} />

					{/* </Container> */}
				</Box>
			) : (
				<Linear />
			)}
			<Box style={{ padding: 10 }}>
				<Grid
					container
					style={{
						marginTop: 10,
						paddingLeft: matches ? 0 : 100,
						paddingRight: matches ? 0 : 100,
					}}
					spacing={2}>
					<Grid item xs={12} lg={3}>
						<Box
							style={{
								padding: 15,
								boxShadow: '0 3px 30px 0 rgb(0 0 0 / 10%)',
								background: 'white',
								borderRadius: 5,
							}}>
							<Box
								display='flex'
								justifyContent='space-between'
								alignItems='center'>
								<Box display='flex' alignItems='center'>
									<i
										class='fa fa-search'
										style={{ margin: 6, fontSize: 20 }}></i>
									<span style={{ fontSize: 15 }}>Filter</span>
								</Box>
								<p style={{ fontSize: 13 }}>
									<u>Clear all filters</u>
								</p>
							</Box>
							<Box
								style={{
									background: '#E0DEDE',
									padding: 5,
									cursor: 'pointer',
									marginTop: 20,
								}}
								onClick={() => (stp ? setStp(false) : setStp(true))}>
								<b style={{ fontWeight: 'bold' }}>
									{' '}
									<span
										class='air_down_icon'
										id='icon_dept'
										style={{ fontSize: 14 }}>
										▼
									</span>
									Stops:
								</b>
							</Box>
							{stp ? (
								<>
									{' '}
									<Box
										mt={matches ? '20px' : ''}
										className={classes.radio}
										style={{
											border: '1px solid lightgrey',
											padding: '10px',
											transition: 'all 0.3s ease-in-out',
										}}>
										<FormControlLabel
											control={
												<GreenCheckbox
													checked={stopcheck1}
													onChange={handleStop}
													name='1'
												/>
											}
											label='Non-Stop'
										/>
										<br />
										<FormControlLabel
											control={
												<GreenCheckbox
													checked={stopcheck2}
													onChange={handleStop2}
													name='2'
												/>
											}
											label='One-Stop'
										/>
										<br />
										<FormControlLabel
											control={
												<GreenCheckbox
													checked={stopcheck3}
													onChange={handleStop3}
													name='3'
												/>
											}
											label='Two & more Stop'
										/>
									</Box>
								</>
							) : (
								''
							)}
							<Divider style={{ marginTop: 20 }} />
							<Box
								style={{
									background: '#E0DEDE',
									padding: 5,
									cursor: 'pointer',
								}}
								onClick={() => (air ? setAir(false) : setAir(true))}>
								<b style={{ fontWeight: 'bold' }}>
									{' '}
									<span
										class='air_down_icon'
										id='icon_dept'
										style={{ fontSize: 14 }}>
										▼
									</span>
									Airlines:
								</b>
							</Box>
							{air ? (
								<>
									{' '}
									<Box
										mt={matches ? '20px' : ''}
										className={classes.radio}
										style={{
											border: '1px solid lightgrey',
											padding: '10px',
											transition: 'all 0.3s ease-in-out',
										}}>
										{filterAirline &&
											filterAirline.length > 0 &&
											filterAirline.map((i) => {
												return (
													<FormControlLabel
														control={
															<GreenCheckbox
																checked={airlineS === i ? true : false}
																onChange={handleAirline}
																name={`${i}`}
															/>
														}
														label={
															<img
																width='25px'
																height='25px'
																src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
															/>
														}
													/>
												);
											})}
									</Box>
								</>
							) : (
								''
							)}
							<Divider style={{ marginTop: 20 }} />
							<Box
								style={{
									background: '#E0DEDE',
									padding: 5,
									cursor: 'pointer',
								}}
								onClick={() => (out ? setOut(false) : setOut(true))}>
								<b style={{ fontWeight: 'bold' }}>
									{' '}
									<span
										class='air_down_icon'
										id='icon_dept'
										style={{ fontSize: 14 }}>
										▼
									</span>
									Outbound Flight Times:
								</b>
							</Box>
							{out ? (
								<>
									{' '}
									<Box
										mt={matches ? '20px' : ''}
										className={classes.radio}
										style={{
											border: '1px solid lightgrey',
											padding: '10px',
											transition: 'all 0.3s ease-in-out',
										}}>
										<FormControlLabel
											control={
												<GreenCheckbox
													// checked={morning}
													// onChange={handleMorning}
													name='Morning'
												/>
											}
											label='Morning(04:00-11:00)'
										/>
										<br />
										<FormControlLabel
											control={
												<GreenCheckbox
													// checked={check2}
													// onChange={handleCheckGO}
													name='G8'
												/>
											}
											label='Afternoon(11:00-16:00)'
										/>
										<br />
										<FormControlLabel
											control={
												<GreenCheckbox
													// checked={check3}
													// onChange={handleCheckIndigo}
													name='6E'
												/>
											}
											label='Evening(16:00-21:00)'
										/>
										<br />
										<FormControlLabel
											control={
												<GreenCheckbox
													// checked={check4}
													// onChange={handleCheckAir}
													name='I5'
												/>
											}
											label='Night(21:00-04:00)'
										/>

										<br />
									</Box>
								</>
							) : (
								''
							)}
							<Divider style={{ marginTop: 20 }} />
							<Box
								style={{
									background: '#E0DEDE',
									padding: 5,
									cursor: 'pointer',
								}}
								onClick={() => (rf ? setRf(false) : setRf(true))}>
								<b style={{ fontWeight: 'bold' }}>
									{' '}
									<span
										class='air_down_icon'
										id='icon_dept'
										style={{ fontSize: 14 }}>
										▼
									</span>
									Fare Type
								</b>
							</Box>
							{rf ? (
								<>
									{' '}
									<Box
										mt={matches ? '20px' : ''}
										className={classes.radio}
										style={{
											border: '1px solid lightgrey',
											padding: '10px',
											transition: 'all 0.3s ease-in-out',
										}}>
										<FormControlLabel
											control={
												<GreenCheckbox
													checked={refund}
													onChange={handleFareR}
													name='Refundable'
												/>
											}
											label='Refundable'
										/>
										<br />
										<FormControlLabel
											control={
												<GreenCheckbox
													checked={nrefund}
													onChange={handleFareN}
													name='Non'
												/>
											}
											label='Non-Refundable'
										/>
									</Box>
								</>
							) : (
								''
							)}
						</Box>
					</Grid>
					<Grid item xs={12} lg={9}>
						<h3>{`Flights from ${selectedOrigin.city_name} to ${
							selectedDepart.city_name
						}(${filterData ? filterData.length : 0})`}</h3>
						{loading2 ? (
							[0, 1, 2, 3, 4].map((i) => {
								return <SkeletonOneway />;
							})
						) : lint ? (
							[0, 1, 2, 3, 4].map((i) => {
								return <SkeletonOneway />;
							})
						) : rule || (result && result.length > 0) ? (
							result && result.length > 0 ? (
								<InfiniteScroll
									dataLength={scrollValResult}
									next={fetchMoreDataResult}
									hasMore={hasMoreresult}
									loader={<h4>Loading...</h4>}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>No More Flights</b>
										</p>
									}>
									{result.map((item, index) => {
										return index < scrollValResult ? (
											<>
												<Accordion
													style={{ marginTop: 10 }}
													expanded={expanded === `panel${index + 1}`}
													onChange={handleChange(`panel${index + 1}`)}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls='panel1bh-content'
														id='panel1bh-header'>
														<Box
															display='flex'
															justifyContent='space-between'
															style={{ width: '100%' }}>
															<Box display='flex' alignItems='center'>
																<img
																	width='30px'
																	height='30px'
																	src={`https://nitish.musafirbazar.com/static/media/${
																		item.Supplier === 'TBO'
																			? item.AirlineCode
																			: item.Supplier === 'ETR'
																			? item.Airline_Code
																			: ''
																	}.gif`}
																/>
																<Box style={{ marginLeft: 5 }}>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'TBO'
																			? item.Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.AirlineName;
																					})
																			: item.Supplier === 'ETR'
																			? item.Segments[0].Airline_Name
																			: ''}
																	</span>
																	<p
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'TBO'
																			? item.Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.AirlineCode;
																					})
																			: item.Supplier === 'ETR'
																			? item.Segments[0].Airline_Code
																			: ''}
																		-
																		{item.Supplier === 'TBO'
																			? item.Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.FlightNumber;
																					})
																			: item.Supplier === 'ETR'
																			? item.Segments[0].Flight_Number
																			: ''}
																	</p>
																</Box>
																<Box style={{ marginLeft: 10 }} display='flex'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																			fontWeight: 'bold',
																		}}>
																		{item.Supplier === 'TBO'
																			? item.Segments[0]
																					.filter((i, index) => index === 0)
																					.map((it) => {
																						return it.Origin.Airport
																							.AirportCode;
																					})
																			: item.Supplier === 'ETR'
																			? item.Origin
																			: ''}
																	</span>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		-
																	</span>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																			fontWeight: 'bold',
																		}}>
																		{item.Airline_Code
																			? item.Destination
																			: item.Segments[0].slice(-1)[0]
																					.Destination.Airport.AirportCode}
																		{/* {
				console.log("first",item.Supplier==='TBO'?item.Segments[0].slice(-1)[0]:'')
			  } */}
																	</span>
																</Box>
																<Box
																	style={{ marginLeft: 10, marginRight: 5 }}
																	display='flex'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'ETR'
																			? item.ONWARD[0].Departure_DateTime.split(
																					' '
																			  )[1]
																			: item.Supplier === 'TBO'
																			? item.Segments[0]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Origin.DepTime.split(
																							'T'
																						)[1];
																					})
																			: ''}
																	</span>
																</Box>
																||
																<Box style={{ marginLeft: 10 }} display='flex'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'ETR'
																			? item.ONWARD.slice(
																					-1
																			  )[0].Arrival_DateTime.split(' ')[1]
																			: item.Supplier === 'TBO'
																			? item.Segments[0]
																					.slice(-1)[0]
																					.Destination.ArrTime.split('T')[1]
																			: ''}
																	</span>
																</Box>
															</Box>
															<Box display='flex' alignItems='center'>
																<img
																	width='30px'
																	height='30px'
																	src={`https://nitish.musafirbazar.com/static/media/${
																		item.Supplier === 'TBO'
																			? item.AirlineCode
																			: item.Supplier === 'ETR'
																			? item.RETURN[0].Airline_Code
																			: ''
																	}.gif`}
																/>
																<Box style={{ marginLeft: 5 }}>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'TBO'
																			? item.Segments[1]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.AirlineName;
																					})
																			: item.Supplier === 'ETR'
																			? item.Segments[1].Airline_Name
																			: ''}
																	</span>
																	<p
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'TBO'
																			? item.Segments[1]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.AirlineCode;
																					})
																			: item.Supplier === 'ETR'
																			? item.Segments[1].Airline_Code
																			: ''}
																		-
																		{item.Supplier === 'TBO'
																			? item.Segments[1]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Airline.FlightNumber;
																					})
																			: item.Supplier === 'ETR'
																			? item.Segments[1].Flight_Number
																			: ''}
																	</p>
																</Box>
																<Box style={{ marginLeft: 10 }} display='flex'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																			fontWeight: 'bold',
																		}}>
																		{item.Supplier === 'TBO'
																			? item.Segments[1]
																					.filter((i, index) => index === 0)
																					.map((it) => {
																						return it.Origin.Airport
																							.AirportCode;
																					})
																			: item.Supplier === 'ETR'
																			? item.Destination
																			: ''}
																	</span>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		-
																	</span>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																			fontWeight: 'bold',
																		}}>
																		{item.Airline_Code
																			? item.Origin
																			: item.Segments[1].slice(-1)[0]
																					.Destination.Airport.AirportCode}
																	</span>
																</Box>
																<Box
																	style={{ marginLeft: 10, marginRight: 5 }}
																	display='flex'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'ETR'
																			? item.RETURN[0].Departure_DateTime.split(
																					' '
																			  )[1]
																			: item.Supplier === 'TBO'
																			? item.Segments[1]
																					.slice(0, 1)
																					.map((i) => {
																						return i.Origin.DepTime.split(
																							'T'
																						)[1];
																					})
																			: ''}
																	</span>
																</Box>
																||
																<Box style={{ marginLeft: 10 }} display='flex'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{item.Supplier === 'ETR'
																			? item.RETURN.slice(
																					-1
																			  )[0].Arrival_DateTime.split(' ')[1]
																			: item.Supplier === 'TBO'
																			? item.Segments[1]
																					.slice(-1)[0]
																					.Destination.ArrTime.split('T')[1]
																			: ''}
																	</span>
																</Box>
															</Box>
															<Box display='flex'>
																<Box
																	onClick={() => {
																		history.push({
																			pathname: '/summaryflight',
																			state: {
																				orderID: orderID?.result,
																				returnBody: location.state.returnBody,
																				intFlight: item,
																				IDTBO:
																					flightreturnInt &&
																					flightreturnInt.result &&
																					flightreturnInt.result.Response &&
																					flightreturnInt.result.Response
																						.TraceId,
																			},
																		});
																	}}
																	style={{
																		background: 'midnightblue',
																		padding: '6px 14px',
																		borderRadius: 2,
																		color: 'white',
																		fontWeight: 'bold',
																	}}>
																	{/* <img
																width='30px'
																height='30px'
																src={`https://nitish.musafirbazar.com/static/media/${'G8'}.gif`}
															/> */}
																	<span style={{ fontFamily: 'paynimo-icons' }}>
																		USD
																		{item.Supplier === 'TBO'
																			? Number(
																					Math.round(
																						Number(
																							currency.conversion_rates.USD
																						) *
																							Number(
																								Math.round(
																									Number(
																										item.Fare.Total_Amount
																									) -
																										Number(
																											item.Fare.MFB_Discount
																										)
																								)
																							)
																					)
																			  )
																			: item.Supplier === 'ETR'
																			? Number(
																					Math.round(item.Fares.Total_Amount)
																			  )
																			: ''}
																	</span>
																</Box>
																<span>
																	{item.Supplier === 'ETR' ? '*' : ''}
																</span>
															</Box>
														</Box>
													</AccordionSummary>
													<AccordionDetails>
														<Grid container spacing={2}>
															<Grid item xs={12} lg={6}>
																{' '}
																<Box
																	style={{ marginTop: 25 }}
																	display='flex'
																	justifyContent='space-between'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		Depart
																	</span>
																	<Box>
																		<span
																			style={{
																				fontSize: 12,
																				fontFamily: 'Poppins',
																			}}>
																			Duration:
																		</span>
																		<span
																			style={{
																				marginLeft: 10,
																				fontSize: 12,
																				fontFamily: 'Poppins',
																			}}>
																			{timeConvert(
																				item.AirlineCode
																					? item.Segments[0].length == 2
																						? Number(
																								item.Segments[0]
																									.filter(
																										(i, index) => index === 0
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  ) +
																						  Number(
																								item.Segments[0]
																									.filter(
																										(i, index) => index === 1
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  )
																						: item.Segments[0].length == 1
																						? item.Segments[0].map(
																								(i) => i.Duration
																						  )
																						: item.Segments[0].length == 3
																						? Number(
																								item.Segments[0]
																									.filter(
																										(i, index) => index === 0
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  ) +
																						  Number(
																								item.Segments[0]
																									.filter(
																										(i, index) => index === 1
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  ) +
																						  Number(
																								item.Segments[0]
																									.filter(
																										(i, index) => index === 2
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  )
																						: ''
																					: item.Supplier === 'ETR'
																					? timedifference(
																							item.ONWARD[0].Departure_DateTime,
																							item.ONWARD.slice(-1)[0]
																								.Arrival_DateTime
																					  )
																					: ''
																			)}
																		</span>
																	</Box>
																</Box>
																<Box
																	style={{
																		padding: 10,
																		border: '1px solid #ebe5e5',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[0].map((i) => {
																				return (
																					<>
																						<Box
																							display='flex'
																							style={{ marginTop: 10 }}
																							justifyContent='space-between'>
																							{' '}
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Origin.Airport.AirportCode}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									({i.Origin.Airport.CityName})
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Origin.DepTime.substr(
																										11,
																										5
																									)}
																								</span>
																							</Box>
																							<Box
																								style={{ textAlign: 'center' }}>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{timeConvert(i.Duration)}
																								</span>
																								<div className='ln'></div>
																								<br></br>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									Non Stop
																								</span>
																							</Box>
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{
																										i.Destination.Airport
																											.AirportCode
																									}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									(
																									{
																										i.Destination.Airport
																											.CityName
																									}
																									)
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Destination.ArrTime.substr(
																										11,
																										5
																									)}
																								</span>
																							</Box>
																						</Box>
																					</>
																				);
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments.map((i) => {
																				return !i.Return_Flight ? (
																					<>
																						<Box
																							display='flex'
																							style={{ marginTop: 10 }}
																							justifyContent='space-between'>
																							{' '}
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Origin}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									({i.Origin_City})
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{
																										i.Departure_DateTime.split(
																											' '
																										)[1]
																									}
																								</span>
																							</Box>
																							<Box
																								style={{ textAlign: 'center' }}>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{timeConvert(
																										timedifference(
																											i.Departure_DateTime,
																											i.Arrival_DateTime
																										)
																									)}
																								</span>
																								<div className='ln'></div>
																								<br></br>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									Non Stop
																								</span>
																							</Box>
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Destination}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									({i.Destination_City})
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{
																										i.Arrival_DateTime.split(
																											' '
																										)[1]
																									}
																								</span>
																							</Box>
																						</Box>
																					</>
																				) : (
																					''
																				);
																		  })
																		: ''}
																</Box>
															</Grid>
															<Grid item xs={12} lg={6}>
																{' '}
																<Box
																	style={{ marginTop: 25 }}
																	display='flex'
																	justifyContent='space-between'>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		Return
																	</span>
																	<Box>
																		<span>Duration:</span>
																		<span
																			style={{
																				marginLeft: 10,
																				fontSize: 12,
																				fontFamily: 'Poppins',
																			}}>
																			{timeConvert(
																				item.AirlineCode
																					? item.Segments[1].length == 2
																						? Number(
																								item.Segments[1]
																									.filter(
																										(i, index) => index === 0
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  ) +
																						  Number(
																								item.Segments[1]
																									.filter(
																										(i, index) => index === 1
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  )
																						: item.Segments[1].length == 1
																						? item.Segments[1].map(
																								(i) => i.Duration
																						  )
																						: item.Segments[1].length == 3
																						? Number(
																								item.Segments[1]
																									.filter(
																										(i, index) => index === 0
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  ) +
																						  Number(
																								item.Segments[1]
																									.filter(
																										(i, index) => index === 1
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  ) +
																						  Number(
																								item.Segments[1]
																									.filter(
																										(i, index) => index === 2
																									)
																									.map((it) => {
																										return it.Duration;
																									})
																						  )
																						: ''
																					: item.Supplier === 'ETR'
																					? timedifference(
																							item.RETURN[0].Departure_DateTime,
																							item.RETURN.slice(-1)[0]
																								.Arrival_DateTime
																					  )
																					: ''
																			)}
																		</span>
																	</Box>
																</Box>
																<Box
																	style={{
																		padding: 10,
																		border: '1px solid #ebe5e5',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[1].map((i) => {
																				return (
																					<>
																						<Box
																							display='flex'
																							style={{ marginTop: 10 }}
																							justifyContent='space-between'>
																							{' '}
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Origin.Airport.AirportCode}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									({i.Origin.Airport.CityName})
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Origin.DepTime.substr(
																										11,
																										5
																									)}
																								</span>
																							</Box>
																							<Box
																								style={{ textAlign: 'center' }}>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{timeConvert(i.Duration)}
																								</span>
																								<div className='ln'></div>
																								<br></br>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									Non Stop
																								</span>
																							</Box>
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{
																										i.Destination.Airport
																											.AirportCode
																									}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									(
																									{
																										i.Destination.Airport
																											.CityName
																									}
																									)
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Destination.ArrTime.substr(
																										11,
																										5
																									)}
																								</span>
																							</Box>
																						</Box>
																					</>
																				);
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments.map((i) => {
																				return i.Return_Flight ? (
																					<>
																						<Box
																							display='flex'
																							style={{ marginTop: 10 }}
																							justifyContent='space-between'>
																							{' '}
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Origin}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									({i.Origin_City})
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{
																										i.Departure_DateTime.split(
																											' '
																										)[1]
																									}
																								</span>
																							</Box>
																							<Box
																								style={{ textAlign: 'center' }}>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{timeConvert(
																										timedifference(
																											i.Departure_DateTime,
																											i.Arrival_DateTime
																										)
																									)}
																								</span>
																								<div className='ln'></div>
																								<br></br>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									Non Stop
																								</span>
																							</Box>
																							<Box>
																								<span
																									style={{
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{i.Destination}
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									({i.Destination_City})
																								</span>
																								<span
																									style={{
																										marginLeft: 5,
																										fontWeight: 'bold',
																										fontSize: 12,
																										fontFamily: 'Poppins',
																									}}>
																									{
																										i.Arrival_DateTime.split(
																											' '
																										)[1]
																									}
																								</span>
																							</Box>
																						</Box>
																					</>
																				) : (
																					''
																				);
																		  })
																		: ''}
																</Box>
															</Grid>
														</Grid>
														<Box
															onClick={() => {
																dispatch(toggleFlightModal(true));
																dispatch(setFlightId(item.ResultIndex));
																dispatch(
																	FlightFareRuleDispatch(
																		item.ResultIndex,
																		flightreturnInt &&
																			flightreturnInt.result &&
																			flightreturnInt.result.Response.TraceId
																	)
																);
															}}>
															<span
																style={{ color: 'blue', cursor: 'pointer' }}>
																Flight Details
															</span>
														</Box>
													</AccordionDetails>
												</Accordion>
											</>
										) : (
											''
										);
									})}
								</InfiniteScroll>
							) : (
								<div
									style={{
										padding: '30px 15px',
										background: 'white',
										borderBottom: '1px solid #e6e6e6',
										textAlign: 'center',
									}}>
									{/* <img src={flightnotfound}></img> */}
									<p style={{ fontSize: '24px', textAlign: 'center' }}>
										Sorry No Flight Found !!
									</p>
								</div>
							)
						) : lint ? (
							[0, 1, 2, 3, 4].map((i) => {
								return <SkeletonOneway />;
							})
						) : filterData && filterData.length > 0 ? (
							<InfiniteScroll
								dataLength={scrollVal}
								next={fetchMoreData}
								hasMore={hasMorefilterData}
								loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
								endMessage={
									<p style={{ textAlign: 'center' }}>
										<b>No More Flights</b>
									</p>
								}>
								{filterData.map((item, index) => {
									return index < scrollVal ? (
										<>
											<Accordion
												style={{ marginTop: 10 }}
												expanded={expanded === `panel${index + 1}`}
												onChange={handleChange(`panel${index + 1}`)}>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls='panel1bh-content'
													id='panel1bh-header'>
													<Box
														display='flex'
														justifyContent='space-between'
														style={{ width: '100%' }}>
														<Box display='flex' alignItems='center'>
															<img
																width='30px'
																height='30px'
																src={`https://nitish.musafirbazar.com/static/media/${
																	item.Supplier === 'TBO'
																		? item.AirlineCode
																		: item.Supplier === 'ETR'
																		? item.Airline_Code
																		: ''
																}.gif`}
															/>
															<Box style={{ marginLeft: 5 }}>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[0].slice(0, 1).map((i) => {
																				return i.Airline.AirlineName;
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments[0].Airline_Name
																		: ''}
																</span>
																<p
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[0].slice(0, 1).map((i) => {
																				return i.Airline.AirlineCode;
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments[0].Airline_Code
																		: ''}
																	-
																	{item.Supplier === 'TBO'
																		? item.Segments[0].slice(0, 1).map((i) => {
																				return i.Airline.FlightNumber;
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments[0].Flight_Number
																		: ''}
																</p>
															</Box>
															<Box style={{ marginLeft: 10 }} display='flex'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																		fontWeight: 'bold',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[0]
																				.filter((i, index) => index === 0)
																				.map((it) => {
																					return it.Origin.Airport.AirportCode;
																				})
																		: item.Supplier === 'ETR'
																		? item.Origin
																		: ''}
																</span>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	-
																</span>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																		fontWeight: 'bold',
																	}}>
																	{item.Airline_Code
																		? item.Destination
																		: item.Segments[0].slice(-1)[0].Destination
																				.Airport.AirportCode}
																	{/* {
				console.log("first",item.Supplier==='TBO'?item.Segments[0].slice(-1)[0]:'')
			  } */}
																</span>
															</Box>
															<Box
																style={{ marginLeft: 10, marginRight: 5 }}
																display='flex'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'ETR'
																		? item.ONWARD[0].Departure_DateTime.split(
																				' '
																		  )[1]
																		: item.Supplier === 'TBO'
																		? item.Segments[0].slice(0, 1).map((i) => {
																				return i.Origin.DepTime.split('T')[1];
																		  })
																		: ''}
																</span>
															</Box>
															||
															<Box style={{ marginLeft: 10 }} display='flex'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'ETR'
																		? item.ONWARD.slice(
																				-1
																		  )[0].Arrival_DateTime.split(' ')[1]
																		: item.Supplier === 'TBO'
																		? item.Segments[0]
																				.slice(-1)[0]
																				.Destination.ArrTime.split('T')[1]
																		: ''}
																</span>
															</Box>
														</Box>
														<Box display='flex' alignItems='center'>
															<img
																width='30px'
																height='30px'
																src={`https://nitish.musafirbazar.com/static/media/${
																	item.Supplier === 'TBO'
																		? item.AirlineCode
																		: item.Supplier === 'ETR'
																		? item.RETURN[0].Airline_Code
																		: ''
																}.gif`}
															/>
															<Box style={{ marginLeft: 5 }}>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[1].slice(0, 1).map((i) => {
																				return i.Airline.AirlineName;
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments[1].Airline_Name
																		: ''}
																</span>
																<p
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[1].slice(0, 1).map((i) => {
																				return i.Airline.AirlineCode;
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments[1].Airline_Code
																		: ''}
																	-
																	{item.Supplier === 'TBO'
																		? item.Segments[1].slice(0, 1).map((i) => {
																				return i.Airline.FlightNumber;
																		  })
																		: item.Supplier === 'ETR'
																		? item.Segments[1].Flight_Number
																		: ''}
																</p>
															</Box>
															<Box style={{ marginLeft: 10 }} display='flex'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																		fontWeight: 'bold',
																	}}>
																	{item.Supplier === 'TBO'
																		? item.Segments[1]
																				.filter((i, index) => index === 0)
																				.map((it) => {
																					return it.Origin.Airport.AirportCode;
																				})
																		: item.Supplier === 'ETR'
																		? item.Destination
																		: ''}
																</span>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	-
																</span>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																		fontWeight: 'bold',
																	}}>
																	{item.Airline_Code
																		? item.Origin
																		: item.Segments[1].slice(-1)[0].Destination
																				.Airport.AirportCode}
																</span>
															</Box>
															<Box
																style={{ marginLeft: 10, marginRight: 5 }}
																display='flex'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'ETR'
																		? item.RETURN[0].Departure_DateTime.split(
																				' '
																		  )[1]
																		: item.Supplier === 'TBO'
																		? item.Segments[1].slice(0, 1).map((i) => {
																				return i.Origin.DepTime.split('T')[1];
																		  })
																		: ''}
																</span>
															</Box>
															||
															<Box style={{ marginLeft: 10 }} display='flex'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	{item.Supplier === 'ETR'
																		? item.RETURN.slice(
																				-1
																		  )[0].Arrival_DateTime.split(' ')[1]
																		: item.Supplier === 'TBO'
																		? item.Segments[1]
																				.slice(-1)[0]
																				.Destination.ArrTime.split('T')[1]
																		: ''}
																</span>
															</Box>
														</Box>
														<Box display='flex'>
															<Box
																onClick={() => {
																	history.push({
																		pathname: '/summaryflight',
																		state: {
																			orderID: orderID?.result,
																			returnBody: location.state.returnBody,
																			intFlight: item,
																			IDTBO:
																				flightreturnInt &&
																				flightreturnInt.result &&
																				flightreturnInt.result.Response &&
																				flightreturnInt.result.Response.TraceId,
																		},
																	});
																}}
																style={{
																	background: 'midnightblue',
																	padding: '6px 14px',
																	borderRadius: 2,
																	color: 'white',
																	fontWeight: 'bold',
																}}>
																{/* <img
																width='30px'
																height='30px'
																src={`https://nitish.musafirbazar.com/static/media/${'G8'}.gif`}
															/> */}
																<span style={{ fontFamily: 'paynimo-icons' }}>
																	USD
																	{item.Supplier === 'TBO'
																		? Number(
																				Math.round(
																					Number(
																						currency?.conversion_rates?.USD
																					) *
																						Number(
																							Math.round(
																								Number(item.Fare.Total_Amount) -
																									Number(item.Fare.MFB_Discount)
																							)
																						)
																				)
																		  )
																		: item.Supplier === 'ETR'
																		? Number(
																				Math.round(item.Fares.Total_Amount)
																		  )
																		: ''}
																</span>
															</Box>
															<span>{item.Supplier === 'ETR' ? '*' : ''}</span>
														</Box>
													</Box>
												</AccordionSummary>
												<AccordionDetails>
													<Grid container spacing={2}>
														<Grid item xs={12} lg={6}>
															{' '}
															<Box
																style={{ marginTop: 25 }}
																display='flex'
																justifyContent='space-between'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	Depart
																</span>
																<Box>
																	<span
																		style={{
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		Duration:
																	</span>
																	<span
																		style={{
																			marginLeft: 10,
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{timeConvert(
																			item.AirlineCode
																				? item.Segments[0].length == 2
																					? Number(
																							item.Segments[0]
																								.filter(
																									(i, index) => index === 0
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  ) +
																					  Number(
																							item.Segments[0]
																								.filter(
																									(i, index) => index === 1
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  )
																					: item.Segments[0].length == 1
																					? item.Segments[0].map(
																							(i) => i.Duration
																					  )
																					: item.Segments[0].length == 3
																					? Number(
																							item.Segments[0]
																								.filter(
																									(i, index) => index === 0
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  ) +
																					  Number(
																							item.Segments[0]
																								.filter(
																									(i, index) => index === 1
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  ) +
																					  Number(
																							item.Segments[0]
																								.filter(
																									(i, index) => index === 2
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  )
																					: ''
																				: item.Supplier === 'ETR'
																				? timedifference(
																						item.ONWARD[0].Departure_DateTime,
																						item.ONWARD.slice(-1)[0]
																							.Arrival_DateTime
																				  )
																				: ''
																		)}
																	</span>
																</Box>
															</Box>
															<Box
																style={{
																	padding: 10,
																	border: '1px solid #ebe5e5',
																}}>
																{item.Supplier === 'TBO'
																	? item.Segments[0].map((i) => {
																			return (
																				<>
																					<Box
																						display='flex'
																						style={{ marginTop: 10 }}
																						justifyContent='space-between'>
																						{' '}
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Origin.Airport.AirportCode}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								({i.Origin.Airport.CityName})
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Origin.DepTime.substr(11, 5)}
																							</span>
																						</Box>
																						<Box
																							style={{ textAlign: 'center' }}>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{timeConvert(i.Duration)}
																							</span>
																							<div className='ln'></div>
																							<br></br>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								Non Stop
																							</span>
																						</Box>
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{
																									i.Destination.Airport
																										.AirportCode
																								}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								(
																								{i.Destination.Airport.CityName}
																								)
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Destination.ArrTime.substr(
																									11,
																									5
																								)}
																							</span>
																						</Box>
																					</Box>
																				</>
																			);
																	  })
																	: item.Supplier === 'ETR'
																	? item.Segments.map((i) => {
																			return !i.Return_Flight ? (
																				<>
																					<Box
																						display='flex'
																						style={{ marginTop: 10 }}
																						justifyContent='space-between'>
																						{' '}
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Origin}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								({i.Origin_City})
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{
																									i.Departure_DateTime.split(
																										' '
																									)[1]
																								}
																							</span>
																						</Box>
																						<Box
																							style={{ textAlign: 'center' }}>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{timeConvert(
																									timedifference(
																										i.Departure_DateTime,
																										i.Arrival_DateTime
																									)
																								)}
																							</span>
																							<div className='ln'></div>
																							<br></br>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								Non Stop
																							</span>
																						</Box>
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Destination}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								({i.Destination_City})
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{
																									i.Arrival_DateTime.split(
																										' '
																									)[1]
																								}
																							</span>
																						</Box>
																					</Box>
																				</>
																			) : (
																				''
																			);
																	  })
																	: ''}
															</Box>
														</Grid>
														<Grid item xs={12} lg={6}>
															{' '}
															<Box
																style={{ marginTop: 25 }}
																display='flex'
																justifyContent='space-between'>
																<span
																	style={{
																		fontSize: 12,
																		fontFamily: 'Poppins',
																	}}>
																	Return
																</span>
																<Box>
																	<span>Duration:</span>
																	<span
																		style={{
																			marginLeft: 10,
																			fontSize: 12,
																			fontFamily: 'Poppins',
																		}}>
																		{timeConvert(
																			item.AirlineCode
																				? item.Segments[1].length == 2
																					? Number(
																							item.Segments[1]
																								.filter(
																									(i, index) => index === 0
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  ) +
																					  Number(
																							item.Segments[1]
																								.filter(
																									(i, index) => index === 1
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  )
																					: item.Segments[1].length == 1
																					? item.Segments[1].map(
																							(i) => i.Duration
																					  )
																					: item.Segments[1].length == 3
																					? Number(
																							item.Segments[1]
																								.filter(
																									(i, index) => index === 0
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  ) +
																					  Number(
																							item.Segments[1]
																								.filter(
																									(i, index) => index === 1
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  ) +
																					  Number(
																							item.Segments[1]
																								.filter(
																									(i, index) => index === 2
																								)
																								.map((it) => {
																									return it.Duration;
																								})
																					  )
																					: ''
																				: item.Supplier === 'ETR'
																				? timedifference(
																						item.RETURN[0].Departure_DateTime,
																						item.RETURN.slice(-1)[0]
																							.Arrival_DateTime
																				  )
																				: ''
																		)}
																	</span>
																</Box>
															</Box>
															<Box
																style={{
																	padding: 10,
																	border: '1px solid #ebe5e5',
																}}>
																{item.Supplier === 'TBO'
																	? item.Segments[1].map((i) => {
																			return (
																				<>
																					<Box
																						display='flex'
																						style={{ marginTop: 10 }}
																						justifyContent='space-between'>
																						{' '}
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Origin.Airport.AirportCode}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								({i.Origin.Airport.CityName})
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Origin.DepTime.substr(11, 5)}
																							</span>
																						</Box>
																						<Box
																							style={{ textAlign: 'center' }}>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{timeConvert(i.Duration)}
																							</span>
																							<div className='ln'></div>
																							<br></br>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								Non Stop
																							</span>
																						</Box>
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{
																									i.Destination.Airport
																										.AirportCode
																								}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								(
																								{i.Destination.Airport.CityName}
																								)
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Destination.ArrTime.substr(
																									11,
																									5
																								)}
																							</span>
																						</Box>
																					</Box>
																				</>
																			);
																	  })
																	: item.Supplier === 'ETR'
																	? item.Segments.map((i) => {
																			return i.Return_Flight ? (
																				<>
																					<Box
																						display='flex'
																						style={{ marginTop: 10 }}
																						justifyContent='space-between'>
																						{' '}
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Origin}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								({i.Origin_City})
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{
																									i.Departure_DateTime.split(
																										' '
																									)[1]
																								}
																							</span>
																						</Box>
																						<Box
																							style={{ textAlign: 'center' }}>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{timeConvert(
																									timedifference(
																										i.Departure_DateTime,
																										i.Arrival_DateTime
																									)
																								)}
																							</span>
																							<div className='ln'></div>
																							<br></br>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								Non Stop
																							</span>
																						</Box>
																						<Box>
																							<span
																								style={{
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{i.Destination}
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								({i.Destination_City})
																							</span>
																							<span
																								style={{
																									marginLeft: 5,
																									fontWeight: 'bold',
																									fontSize: 12,
																									fontFamily: 'Poppins',
																								}}>
																								{
																									i.Arrival_DateTime.split(
																										' '
																									)[1]
																								}
																							</span>
																						</Box>
																					</Box>
																				</>
																			) : (
																				''
																			);
																	  })
																	: ''}
															</Box>
														</Grid>
													</Grid>
													<Box
														onClick={() => {
															dispatch(toggleFlightModal(true));
															dispatch(setFlightId(item.ResultIndex));
															dispatch(
																FlightFareRuleDispatch(
																	item.ResultIndex,
																	flightreturnInt &&
																		flightreturnInt.result &&
																		flightreturnInt.result.Response.TraceId
																)
															);
														}}>
														<span style={{ color: 'blue', cursor: 'pointer' }}>
															Flight Details
														</span>
													</Box>
												</AccordionDetails>
											</Accordion>
										</>
									) : (
										''
									);
								})}
							</InfiniteScroll>
						) : (
							<div
								style={{
									padding: '30px 15px',
									background: 'white',
									borderBottom: '1px solid #e6e6e6',
									textAlign: 'center',
								}}>
								{/* <img src={flightnotfound}></img> */}
								<p style={{ fontSize: '24px', textAlign: 'center' }}>
									Sorry No Flight Found !!
								</p>
							</div>
						)}
					</Grid>
				</Grid>
			</Box>
			<FlightDetailModal />
			{/* </Container> */}
		</>
	);
};

export default International;
