import React, { useEffect } from 'react';
import {
	Box,
	Container,
	Grid,
	Divider,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import { useDispatch } from 'react-redux';
import AddGSTModal from '../../components/reusableComponents/AddGSTModal';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import FlightSummaryForm from '../../form/FlightSummaryForm';
import Drawers from '../../components/Drawers';
import {
	agentlogogetDispatch,
	walletBalanceAgentDispatch,
} from '../../reducers/UserReducer';
import Login from '../../components/reusableComponents/Login';
import SelectedOnwardEtravRow from '../../components/reusableComponents/SelectedOnwardEtravRow';
import SelectedReturnEtravRow from '../../components/reusableComponents/SelectedReturnEtravRow';
import SelectedOnwardTboRow from '../../components/reusableComponents/SelectedOnwardTboRow';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import SessionExpireModal from '../../components/reusableComponents/SessionExpireModal';
import Footer from './Footer';
import SelectedOnwardTJKRow from '../../components/reusableComponents/SelectedOnwardTJKRow';

const SummaryFlight = () => {
	const [value, setValue] = React.useState('');
	const { agentlogin } = useSelector((state) => state.user);
	const { currency } = useSelector((state) => state.home);
	const { baggageInfo, mealInfo } = useSelector((state) => state.ui);
	const theme = useTheme();
	const location = useLocation();
	console.log('first', location);
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const dispatch = useDispatch();
	const history = useHistory();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	function convertH2M(timeInHour) {
		console.log(timeInHour);
		var timeParts = timeInHour.split(':');
		return Number(timeParts[0]) * 60 + Number(timeParts[1]);
	}
	useEffect(() => {
		if (agentlogin && agentlogin.status === 200) {
			let logodata = {
				agent_id: agentlogin && agentlogin.result.result.id,
			};
			dispatch(agentlogogetDispatch(logodata));
			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
		}
	}, []);

	return (
		<>
			<HeaderSection id={1} />
			<Box
				style={{
					backgroundColor: '#515b62',
					marginTop: 60,
					color: 'white',
					padding: 10,
				}}>
				<Container></Container>
			</Box>
			<Box style={{ background: '#e5eef4', paddingLeft: 30, paddingRight: 30 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={8}>
						<Box display='flex' justifyContent='space-between' mt={'30px'}>
							<Box>
								<TitleText
									four
									textStyle={{
										fontFamily: 'Josefin Sans, sans-serif',
										fontWeight: 500,
										fontSize: 22,
									}}>
									Review Your Booking
								</TitleText>
							</Box>
						</Box>
						<Box
							style={{
								padding: 15,
								background: 'white',
								color: 'gray',
								border: '1px solid #e6e6e6',
								margin: '0 0 20px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							{location.state.OneflightData
								? location.state.OneflightData.Airline_Code
									? location.state.OneflightData.Segments.map((i, index) => {
											return <SelectedOnwardEtravRow i={i} index={index} />;
									  })
									: location.state.OneflightData.AirlineCode
									? location.state.OneflightData.Segments[0].map((i, index) => {
											return <SelectedOnwardTboRow i={i} index={index} />;
									  })
									: location.state.OneflightData.Supplier === 'TJK'
									? location.state.OneflightData.sI.map((i, index) => {
											return <SelectedOnwardTJKRow i={i} index={index} />;
									  })
									: ''
								: ''}

							{location.state.selectedonward
								? location.state.selectedonward[0].Airline_Code
									? location.state.selectedonward[0].Segments.map(
											(i, index) => {
												return <SelectedOnwardEtravRow i={i} index={index} />;
											}
									  )
									: location.state.selectedonward[0].AirlineCode
									? location.state.selectedonward[0].Segments[0].map(
											(i, index) => {
												return (
													<SelectedOnwardTboRow
														i={i}
														index={index}
														selectedonward={location.state.selectedonward}
													/>
												);
											}
									  )
									: location.state.selectedonward[0].Supplier === 'TJK'
									? location.state.selectedonward[0].sI.map((i, index) => {
											return (
												<SelectedOnwardTJKRow
													i={i}
													index={index}
													selectedonward={location.state.selectedonward}
												/>
											);
									  })
									: ''
								: ''}
							{location?.state?.intFlight?.Supplier === 'TBO' &&
							location.state.intFlight
								? location.state.intFlight.Segments[0].map((i, index) => {
										return (
											<>
												<Box
													display='flex'
													justifyContent='space-between'
													mt={index > 0 ? '20px' : ''}>
													<Box display='flex' alignItems='center'>
														<Box alignItems='center' display='flex'>
															<TitleText
																textStyle={{
																	fontFamily: 'unset',
																	fontWeight: 500,
																	fontSize: 18,
																}}>
																{i.Origin.Airport.CityCode}
															</TitleText>
															-
															<TitleText
																textStyle={{
																	fontFamily: 'unset',
																	fontWeight: 500,
																	fontSize: 18,
																}}>
																{i.Destination.Airport.CityCode}
															</TitleText>
														</Box>
														{index === 0 ? (
															<Box
																ml={'30px'}
																style={{
																	background: 'rgb(239, 51, 57)',
																	padding: '3px 15px',
																	borderRadius: '50px',
																	color: 'white',
																	fontFamily: 'unset',
																	fontWeight: 500,
																}}>
																<TitleText two>ONWARD</TitleText>
															</Box>
														) : (
															''
														)}
													</Box>
													<Box>{/* <BodyText>Fare Rule</BodyText> */}</Box>
												</Box>
												<Box mt={'20px'}>
													<BodyText three textStyle={{ fontSize: 16 }}>
														{`${new Date(i.Origin.DepTime).toLocaleString(
															'en-us',
															{
																weekday: 'short',
															}
														)} ,  ${new Date(
															i.Origin.DepTime
														).getDate()} ${new Date(
															i.Origin.DepTime
														).toLocaleString('en-us', {
															month: 'short',
														})}`}
													</BodyText>
												</Box>
												<Box
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														borderRadius: 5,
													}}>
													<Grid container style={{ alignItems: 'center' }}>
														<Grid item xs={12} sm={3} lg={3}>
															<Box
																display={matches ? 'flex' : ''}
																justifyContent={matches ? 'space-between' : ''}
																alignItems={matches ? 'center' : ''}>
																<TitleText
																	textStyle={{
																		fontFamily: 'unset',
																		fontWeight: 500,
																		fontSize: 18,
																	}}>
																	{i.Origin.Airport.CityName}
																	{/* {i.Origin.Airport.AirportCode}) */}
																</TitleText>
																<TitleText
																	three
																	textStyle={{
																		fontFamily: 'unset',
																		fontWeight: 600,
																		fontSize: matches ? 16 : '',
																	}}>
																	{i.Origin.DepTime.substr(11, 5)}
																</TitleText>
															</Box>
														</Grid>
														<Grid
															item
															xs={12}
															sm={3}
															lg={3}
															style={{ marginTop: matches ? 10 : '' }}>
															<Box
																style={{
																	padding: '2px 20px',
																	background: 'rgb(239, 51, 57)',
																	color: 'white',
																	borderRadius: 25,
																}}>
																<BodyText
																	three
																	textStyle={{
																		fontSize: 16,
																		textAlign: 'center',
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																	}}>
																	{timeConvert(i.Duration)}
																</BodyText>
															</Box>
														</Grid>
														<Grid
															item
															xs={12}
															sm={3}
															lg={3}
															style={{
																textAlign: 'center',
																marginTop: matches ? 10 : '',
															}}>
															<Box
																display={matches ? 'flex' : ''}
																justifyContent={matches ? 'space-between' : ''}
																alignItems={matches ? 'center' : ''}>
																<TitleText
																	textStyle={{
																		fontFamily: 'unset',
																		fontWeight: 500,
																		fontSize: 18,
																	}}>
																	{i.Destination.Airport.CityName}
																	{/* {i.Destination_City} */}
																	{/* {i.Origin.Airport.AirportCode}) */}
																</TitleText>
																<TitleText
																	three
																	textStyle={{
																		fontFamily: 'unset',
																		fontWeight: 500,
																		fontSize: matches ? 16 : '',
																	}}>
																	{i.Destination.ArrTime.substr(11, 5)}
																</TitleText>
															</Box>
														</Grid>
														<Grid
															item
															xs={12}
															sm={3}
															lg={3}
															style={{ textAlign: 'center' }}>
															<img
																src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																width='50px'
																height='25px'></img>
															<p>{`${i.Airline.AirlineName} (${i.Airline.AirlineCode}-${i.Airline.FlightNumber})`}</p>
														</Grid>
													</Grid>
												</Box>
											</>
										);
								  })
								: ''}
							{location?.state?.intFlight?.Supplier === 'ETR' &&
								location?.state?.intFlight?.ONWARD.map((i, index) => {
									return <SelectedOnwardEtravRow i={i} index={index} />;
								})}
						</Box>

						{location.state.selectedReturn ? (
							<Box
								style={{
									padding: 15,
									background: 'white',
									color: 'gray',
									border: '1px solid #e6e6e6',
									margin: '0 0 20px',
									boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
									borderRadius: '5px',
								}}>
								{location.state.selectedReturn[0].Airline_Code
									? location.state.selectedReturn[0].Segments.map(
											(i, index) => {
												return <SelectedReturnEtravRow i={i} index={index} />;
											}
									  )
									: location.state.selectedReturn[0].AirlineCode
									? location.state.selectedReturn[0].Segments[0].map(
											(i, index) => {
												return (
													<>
														<Box
															display='flex'
															justifyContent='space-between'
															mt={index > 0 ? '20px' : ''}>
															<Box display='flex' alignItems='center'>
																<Box alignItems='center' display='flex'>
																	<TitleText
																		textStyle={{
																			fontFamily: 'unset',
																			fontWeight: 500,
																			fontSize: 18,
																		}}>
																		{i.Origin.Airport.CityCode}
																	</TitleText>
																	-
																	<TitleText
																		textStyle={{
																			fontFamily: 'unset',
																			fontWeight: 500,
																			fontSize: 18,
																		}}>
																		{i.Destination.Airport.CityCode}
																	</TitleText>
																</Box>
																<Box
																	ml={'30px'}
																	style={{
																		background: 'rgb(239, 51, 57)',
																		padding: '3px 15px',
																		borderRadius: '50px',
																		color: 'white',
																		fontFamily: 'unset',
																		fontWeight: 500,
																	}}>
																	<TitleText two>ONWARD</TitleText>
																</Box>
															</Box>
															<Box>{/* <BodyText>Fare Rule</BodyText> */}</Box>
														</Box>
														<Box mt={'20px'}>
															<BodyText three textStyle={{ fontSize: 16 }}>
																{' '}
																{`${new Date(
																	location.state.returnBody.Segments[0].PreferredDepartureTime
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	location.state.returnBody.Segments[0].PreferredDepartureTime
																).getDate()} ${new Date(
																	location.state.returnBody.Segments[0].PreferredDepartureTime
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</BodyText>
														</Box>
														<Box
															style={{
																background: 'white',
																padding: '10px 15px',
																border: '1px solid #e6e6e6',
																marginTop: '10px',
																borderRadius: 5,
															}}>
															<Grid container style={{ alignItems: 'center' }}>
																<Grid item xs={12} sm={3} lg={3}>
																	<Box
																		display={matches ? 'flex' : ''}
																		justifyContent={
																			matches ? 'space-between' : ''
																		}
																		alignItems={matches ? 'center' : ''}>
																		<TitleText
																			textStyle={{
																				fontFamily: 'unset',
																				fontWeight: 500,
																				fontSize: 18,
																			}}>
																			{i.Origin.Airport.CityName}
																			{/* {i.Origin.Airport.AirportCode}) */}
																		</TitleText>
																		<TitleText
																			three
																			textStyle={{
																				fontFamily: 'unset',
																				fontWeight: 600,
																				fontSize: matches ? 16 : '',
																			}}>
																			{i.Origin.DepTime.substr(11, 5)}
																		</TitleText>
																	</Box>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={3}
																	lg={3}
																	style={{ marginTop: matches ? 10 : '' }}>
																	<Box
																		style={{
																			padding: '2px 20px',
																			background: 'rgb(239, 51, 57)',
																			color: 'white',
																			borderRadius: 25,
																		}}>
																		<BodyText
																			three
																			textStyle={{
																				fontSize: 16,
																				textAlign: 'center',
																				whiteSpace: 'nowrap',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																			}}>
																			{timeConvert(i.Duration)}
																		</BodyText>
																	</Box>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={3}
																	lg={3}
																	style={{
																		textAlign: 'center',
																		marginTop: matches ? 10 : '',
																	}}>
																	<Box
																		display={matches ? 'flex' : ''}
																		justifyContent={
																			matches ? 'space-between' : ''
																		}
																		alignItems={matches ? 'center' : ''}>
																		<TitleText
																			textStyle={{
																				fontFamily: 'unset',
																				fontWeight: 500,
																				fontSize: 18,
																			}}>
																			{i.Destination.Airport.CityName}
																			{/* {i.Destination_City} */}
																			{/* {i.Origin.Airport.AirportCode}) */}
																		</TitleText>
																		<TitleText
																			three
																			textStyle={{
																				fontFamily: 'unset',
																				fontWeight: 500,
																				fontSize: matches ? 16 : '',
																			}}>
																			{i.Destination.ArrTime.substr(11, 5)}
																		</TitleText>
																	</Box>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={3}
																	lg={3}
																	style={{ textAlign: 'center' }}>
																	<img
																		src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																		width='50px'
																		height='25px'></img>
																	<p>{`${i.Airline.AirlineName} (${i.Airline.AirlineCode}-${i.Airline.FlightNumber})`}</p>
																</Grid>
															</Grid>
															{/* <Grid container>
                              <Grid item xs={12} sm={9} lg={9}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box>
                                    <TitleText
                                      textStyle={{
                                        fontFamily: "unset",
                                        fontWeight: 500,
                                        fontSize: 18,
                                      }}
                                    >
                                      {i.Origin.Airport.CityName}(
                                      {i.Origin.Airport.AirportCode})
                                    </TitleText>
                                    <BodyText
                                      four
                                      textStyle={{
                                        fontFamily: "unset",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {i.Origin.Airport.AirportName}
                                    </BodyText>
                                  </Box>
                                  <Box>
                                    <TitleText
                                      four
                                      textStyle={{
                                        fontFamily: "unset",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {i.Origin.DepTime.substr(11, 5)}
                                    </TitleText>
                                  </Box>
                                </Box>
                                <Grid container alignItems="center">
                                  <Grid item xs={6} lg={9}>
                                    <Divider />
                                  </Grid>
                                  <Grid item xs={4} lg={3}>
                                    <Box
                                      style={{
                                        padding: "2px 20px",
                                        background: "rgb(239, 51, 57)",
                                        color: "white",
                                        borderRadius: 5,
                                      }}
                                    >
                                      <BodyText three>
                                        {timeConvert(i.Duration)}
                                      </BodyText>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box>
                                    <TitleText
                                      textStyle={{
                                        fontFamily: "unset",
                                        fontWeight: 500,
                                        fontSize: 18,
                                      }}
                                    >
                                      {i.Destination.Airport.CityName}(
                                      {i.Destination.Airport.AirportCode})
                                    </TitleText>
                                    <BodyText
                                      four
                                      textStyle={{
                                        fontFamily: "unset",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {i.Destination.Airport.AirportName}
                                    </BodyText>
                                  </Box>
                                  <Box>
                                    <TitleText
                                      four
                                      textStyle={{
                                        fontFamily: "unset",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {i.Destination.ArrTime.substr(11, 5)}
                                    </TitleText>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={3} lg={3}>
                                <Box textAlign="center">
                                  <img
                                    src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
                                    width="50px"
                                    height="25px"
                                  ></img>
                                  <BodyText three>
                                    {i.Airline.AirlineName}
                                  </BodyText>
                                  <BodyText three>
                                    {i.Airline.AirlineCode}-
                                    {i.Airline.FlightNumber}| Economy
                                  </BodyText>
                                  <BodyText three>
                                    Check-In : 1Pc - 15Kgs
                                  </BodyText>
                                  <BodyText three>Cabin : 7Kgs</BodyText>
                                </Box>
                              </Grid>
                            </Grid> */}
														</Box>
													</>
												);
											}
									  )
									: location.state.selectedReturn[0].Supplier === 'TJK'
									? location.state.selectedReturn[0].sI.map((i, index) => {
											return (
												<SelectedOnwardTJKRow
													i={i}
													index={index}
													selectedReturn={location.state.selectedReturn}
												/>
											);
									  })
									: ''}
							</Box>
						) : (
							''
						)}
						{location.state.intFlight ? (
							<Box
								style={{
									padding: 15,
									background: 'white',
									color: 'gray',
									border: '1px solid #e6e6e6',
									margin: '0 0 20px',
									boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
									borderRadius: '5px',
								}}>
								{location?.state?.intFlight?.Supplier === 'TBO' &&
								location.state.intFlight
									? location.state.intFlight.Segments[1].map((i, index) => {
											return (
												<>
													<Box
														display='flex'
														justifyContent='space-between'
														mt={index > 0 ? '20px' : ''}>
														<Box display='flex' alignItems='center'>
															<Box alignItems='center' display='flex'>
																<TitleText
																	textStyle={{
																		fontFamily: 'unset',
																		fontWeight: 500,
																		fontSize: 18,
																	}}>
																	{i.Origin.Airport.CityCode}
																</TitleText>
																-
																<TitleText
																	textStyle={{
																		fontFamily: 'unset',
																		fontWeight: 500,
																		fontSize: 18,
																	}}>
																	{i.Destination.Airport.CityCode}
																</TitleText>
															</Box>
															{index === 0 ? (
																<Box
																	ml={'30px'}
																	style={{
																		background: 'rgb(239, 51, 57)',
																		padding: '3px 15px',
																		borderRadius: '50px',
																		color: 'white',
																		fontFamily: 'unset',
																		fontWeight: 500,
																	}}>
																	<TitleText two>RETRUN</TitleText>
																</Box>
															) : (
																''
															)}
														</Box>
														<Box>{/* <BodyText>Fare Rule</BodyText> */}</Box>
													</Box>
													<Box mt={'20px'}>
														<BodyText three textStyle={{ fontSize: 16 }}>
															{`${new Date(i.Origin.DepTime).toLocaleString(
																'en-us',
																{
																	weekday: 'short',
																}
															)} ,  ${new Date(
																i.Origin.DepTime
															).getDate()} ${new Date(
																i.Origin.DepTime
															).toLocaleString('en-us', {
																month: 'short',
															})}`}
														</BodyText>
													</Box>
													<Box
														style={{
															background: 'white',
															padding: '10px 15px',
															border: '1px solid #e6e6e6',
															marginTop: '10px',
															borderRadius: 5,
														}}>
														<Grid container style={{ alignItems: 'center' }}>
															<Grid item xs={12} sm={3} lg={3}>
																<Box
																	display={matches ? 'flex' : ''}
																	justifyContent={
																		matches ? 'space-between' : ''
																	}
																	alignItems={matches ? 'center' : ''}>
																	<TitleText
																		textStyle={{
																			fontFamily: 'unset',
																			fontWeight: 500,
																			fontSize: 18,
																		}}>
																		{i.Origin.Airport.CityName}
																		{/* {i.Origin.Airport.AirportCode}) */}
																	</TitleText>
																	<TitleText
																		three
																		textStyle={{
																			fontFamily: 'unset',
																			fontWeight: 600,
																			fontSize: matches ? 16 : '',
																		}}>
																		{i.Origin.DepTime.substr(11, 5)}
																	</TitleText>
																</Box>
															</Grid>
															<Grid
																item
																xs={12}
																sm={3}
																lg={3}
																style={{ marginTop: matches ? 10 : '' }}>
																<Box
																	style={{
																		padding: '2px 20px',
																		background: 'rgb(239, 51, 57)',
																		color: 'white',
																		borderRadius: 25,
																	}}>
																	<BodyText
																		three
																		textStyle={{
																			fontSize: 16,
																			textAlign: 'center',
																			whiteSpace: 'nowrap',
																			overflow: 'hidden',
																			textOverflow: 'ellipsis',
																		}}>
																		{timeConvert(i.Duration)}
																	</BodyText>
																</Box>
															</Grid>
															<Grid
																item
																xs={12}
																sm={3}
																lg={3}
																style={{
																	textAlign: 'center',
																	marginTop: matches ? 10 : '',
																}}>
																<Box
																	display={matches ? 'flex' : ''}
																	justifyContent={
																		matches ? 'space-between' : ''
																	}
																	alignItems={matches ? 'center' : ''}>
																	<TitleText
																		textStyle={{
																			fontFamily: 'unset',
																			fontWeight: 500,
																			fontSize: 18,
																		}}>
																		{i.Destination.Airport.CityName}
																		{/* {i.Destination_City} */}
																		{/* {i.Origin.Airport.AirportCode}) */}
																	</TitleText>
																	<TitleText
																		three
																		textStyle={{
																			fontFamily: 'unset',
																			fontWeight: 500,
																			fontSize: matches ? 16 : '',
																		}}>
																		{i.Destination.ArrTime.substr(11, 5)}
																	</TitleText>
																</Box>
															</Grid>
															<Grid
																item
																xs={12}
																sm={3}
																lg={3}
																style={{ textAlign: 'center' }}>
																<img
																	src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																	width='50px'
																	height='25px'></img>
																<p>{`${i.Airline.AirlineName} (${i.Airline.AirlineCode}-${i.Airline.FlightNumber})`}</p>
															</Grid>
														</Grid>
													</Box>
												</>
											);
									  })
									: ''}
								{location?.state?.intFlight?.Supplier === 'ETR' &&
									location?.state?.intFlight?.RETURN.map((i, index) => {
										return <SelectedReturnEtravRow i={i} index={index} />;
									})}
							</Box>
						) : (
							''
						)}

						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<TitleText
								textStyle={{
									fontFamily: 'unset',
									fontWeight: 500,
									fontSize: 20,
								}}>
								Contact Information
							</TitleText>
							<span
								style={{ cursor: 'pointer', color: 'blue' }}
								onClick={() =>
									history.push({
										pathname: `/oneway/${location.state.body.Segments[0].Origin}/${location.state.body.Segments[0].Destination}/${location.state.body.Segments[0].PreferredDepartureTime}`,
										state: {
											body: location.state.body,
										},
									})
								}>
								<u>Choose Another Fare</u>
							</span>
						</Box>
						<FlightSummaryForm />
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box style={{ position: 'sticky', top: 84 }}>
							<Box
								style={{
									background: 'white',
									padding: '10px 15px',
									border: '1px solid #e6e6e6',
									marginTop: '62px',
									boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
									borderRadius: '5px',
									// position: 'sticky',
									// top: 84,
									zIndex: 1,
								}}>
								<Grid container alignItems='center'>
									<Grid item xs={4}>
										Fare Details
									</Grid>
									<Grid item xs={8}>
										<Divider style={{ background: 'black' }} />
									</Grid>
								</Grid>
								<Box
									style={{
										background: 'rgb(166 185 255 / 17%)',
										padding: '5px 10px',
										margin: '10px 0 0',
										borderRadius: '15px',
									}}>
									<BodyText textStyle={{ fontSize: 13 }}>
										AMOUNT TO BE PAID
									</BodyText>
									<TitleText textStyle={{ fontSize: 18 }}>
										{location.state.returnBody
											? location.state.intFlight
												? location.state.intFlight.Supplier === 'TBO'
													? `${'USD'} ${Number(
															Math.round(
																Number(currency?.conversion_rates?.USD) *
																	Number(
																		Number(
																			Math.round(
																				Number(
																					Number(
																						Math.round(
																							location.state.intFlight.Fare
																								.Total_Amount
																						)
																					)
																				)
																			)
																		) -
																			Number(
																				Math.round(
																					Number(
																						Number(
																							Math.round(
																								location.state.intFlight.Fare
																									.MFB_Discount
																							)
																						)
																					)
																				)
																			)
																	)
															)
													  )}`
													: location.state.intFlight.Supplier === 'ETR'
													? `${'₹'} ${Number(
															Math.round(
																Number(
																	Number(
																		Math.round(
																			location.state.intFlight.Fares
																				.Total_Amount
																		)
																	)
																)
															)
													  )}`
													: ''
												: `${'USD'} ${Number(
														Math.round(
															Number(currency?.conversion_rates?.USD) *
																Number(
																	Math.round(
																		Number(
																			Number(
																				Number(
																					Math.round(
																						location.state.selectedonward[0]
																							.Airline_Code
																							? location.state.selectedonward[0]
																									.Fares.Total_Amount
																							: location.state.selectedonward[0]
																									.AirlineCode
																							? location.state.selectedonward[0]
																									.Fare.Total_Amount
																							: 0
																					)
																				) +
																					Number(
																						Math.round(
																							location.state.selectedReturn[0]
																								.Airline_Code
																								? location.state
																										.selectedReturn[0].Fares
																										.Total_Amount
																								: location.state
																										.selectedReturn[0]
																										.AirlineCode
																								? location.state
																										.selectedReturn[0].Fare
																										.Total_Amount
																								: 0
																						)
																					) -
																					Number(
																						Math.round(
																							location.state.selectedReturn[0]
																								.Airline_Code
																								? 0
																								: location.state
																										.selectedonward[0]
																										.AirlineCode
																								? location.state
																										.selectedReturn[0].Fare
																										.MFB_Discount
																								: 0
																						)
																					) -
																					Number(
																						Math.round(
																							location.state.selectedonward[0]
																								.Airline_Code
																								? 0
																								: location.state
																										.selectedonward[0]
																										.AirlineCode
																								? location.state
																										.selectedonward[0].Fare
																										.MFB_Discount
																								: 0
																						)
																					)
																			)
																		)
																	)
																)
														)
												  )}`
											: location.state.OneflightData
											? `${'USD'} ${Number(
													Math.round(
														Number(currency?.conversion_rates?.USD) *
															Number(
																Number(
																	Math.round(
																		Number(
																			Number(
																				location.state.OneflightData
																					.Supplier === 'ETR'
																					? Math.round(
																							location.state.OneflightData.Fares
																								.Total_Amount
																					  )
																					: location.state.OneflightData
																							.Supplier === 'TBO'
																					? Math.round(
																							location.state.OneflightData.Fare
																								.Total_Amount
																					  )
																					: location.state.OneflightData
																							.Supplier === 'TJK'
																					? Math.round(
																							location.state.OneflightData
																								.totalPriceList.fd.Total_Amount
																					  )
																					: ''
																			) -
																				Number(
																					Math.round(
																						location.state.OneflightData
																							.Supplier === 'TBO'
																							? location.state.OneflightData
																									.Fare.MFB_Discount
																							: 0
																					)
																				)
																		) +
																			Number(
																				location.state.OneflightData
																					.Supplier === 'TBO'
																					? baggageInfo &&
																							baggageInfo?.length > 0 &&
																							baggageInfo
																								.map((item) =>
																									parseInt(item.split('|')[2])
																								)
																								.reduce(
																									(prev, next) => prev + next
																								)
																					: 0
																			)
																	)
																)
															)
													)
											  )}`
											: ''}
									</TitleText>
									<span>(inclusive of all taxes.)</span>
								</Box>
								<Box display='flex' justifyContent='space-between' mt={'10px'}>
									<BodyText textStyle={{ fontSize: 13 }}>
										{`Travellers (${
											Number(
												location.state.body
													? location.state.body.AdultCount
													: location.state.returnBody
													? location.state.returnBody.AdultCount
													: ''
											) +
											Number(
												location.state.body
													? location.state.body.ChildCount
													: location.state.returnBody
													? location.state.returnBody.ChildCount
													: ''
											) +
											Number(
												location.state.body
													? location.state.body.InfantCount
													: location.state.returnBody
													? location.state.returnBody.InfantCount
													: ''
											)
										}) `}
									</BodyText>
									<TitleText textStyle={{ fontSize: 13 }}>
										{location.state.returnBody
											? location.state.intFlight
												? location.state.intFlight.Supplier === 'TBO'
													? `${'USD'} ${Number(
															Math.round(
																Number(currency?.conversion_rates?.USD) *
																	Number(
																		Math.round(
																			Number(
																				Math.round(
																					location.state.intFlight.Fare
																						.Basic_Amount
																				)
																			)
																		)
																	)
															)
													  )}`
													: location.state.intFlight.Supplier === 'ETR'
													? `${'₹'} ${Number(
															Math.round(
																Number(
																	Math.round(
																		location.state.intFlight.Fares.Basic_Amount
																	)
																)
															)
													  )}`
													: ''
												: `${'USD'} ${Number(
														Math.round(
															Number(currency?.conversion_rates?.USD) *
																Number(
																	Math.round(
																		Number(
																			Number(
																				Math.round(
																					location.state.selectedonward[0]
																						.Airline_Code
																						? location.state.selectedonward[0]
																								.Fares.Basic_Amount
																						: location.state.selectedonward[0]
																								.AirlineCode
																						? location.state.selectedonward[0]
																								.Fare.Basic_Amount
																						: 0
																				)
																			) +
																				Number(
																					Math.round(
																						location.state.selectedReturn[0]
																							.Airline_Code
																							? location.state.selectedReturn[0]
																									.Fares.Basic_Amount
																							: location.state.selectedReturn[0]
																									.AirlineCode
																							? location.state.selectedReturn[0]
																									.Fare.Basic_Amount
																							: 0
																					)
																				)
																		)
																	)
																)
														)
												  )}`
											: location.state.OneflightData
											? `${'USD'} ${Number(
													Math.round(
														Number(currency?.conversion_rates?.USD) *
															Number(
																Math.round(
																	Number(
																		location.state.OneflightData.Supplier ===
																			'ETR'
																			? Math.round(
																					location.state.OneflightData.Fares
																						.Basic_Amount
																			  )
																			: location.state.OneflightData
																					.Supplier === 'TBO'
																			? Math.round(
																					location.state.OneflightData.Fare
																						.Basic_Amount
																			  )
																			: location.state.OneflightData
																					.Supplier === 'TJK'
																			? Math.round(
																					location.state.OneflightData
																						.totalPriceList.fd.Basic_Amount
																			  )
																			: ''
																	)
																)
															)
													)
											  )}`
											: ''}
									</TitleText>
								</Box>
								{baggageInfo?.length > 0 && (
									<Box display='flex' justifyContent='space-between'>
										<BodyText textStyle={{ fontSize: 13 }}>
											{`Excess Baggage ${
												baggageInfo &&
												baggageInfo?.length > 0 &&
												baggageInfo
													.map((item) => parseInt(item.split('|')[1]))
													.reduce((prev, next) => prev + next)
											} Kg`}
										</BodyText>
										<TitleText textStyle={{ fontSize: 13 }}>
											{`₹ ${
												baggageInfo &&
												baggageInfo?.length > 0 &&
												baggageInfo
													.map((item) => parseInt(item.split('|')[2]))
													.reduce((prev, next) => prev + next)
											}`}
										</TitleText>
									</Box>
								)}
								{mealInfo?.length > 0 && (
									<Box display='flex' justifyContent='space-between'>
										<BodyText textStyle={{ fontSize: 13 }}>
											{`Meal (1Platter )`}
										</BodyText>
										<TitleText textStyle={{ fontSize: 13 }}>
											{`₹ ${
												mealInfo &&
												mealInfo?.length > 0 &&
												mealInfo
													.map((item) => parseInt(item.split('|')[2]))
													.reduce((prev, next) => prev + next)
											}`}
										</TitleText>
									</Box>
								)}
								<Box display='flex' justifyContent='space-between'>
									<BodyText textStyle={{ fontSize: 13 }}>Total Taxes</BodyText>
									<TitleText textStyle={{ fontSize: 13 }}>
										{location.state.returnBody
											? location.state.intFlight
												? location.state.intFlight.Supplier === 'TBO'
													? `${'USD'} ${Number(
															Math.round(
																Number(currency?.conversion_rates?.USD) *
																	Number(
																		Math.round(
																			Number(
																				Math.round(
																					location.state.intFlight.Fare
																						.AirportTax_Amount
																				)
																			)
																		)
																	)
															)
													  )}`
													: location.state.intFlight.Supplier === 'ETR'
													? `${'₹'} ${Number(
															Math.round(
																Number(
																	Math.round(
																		location.state.intFlight.Fares
																			.AirportTax_Amount
																	)
																)
															)
													  )}`
													: ''
												: `${'USD'} ${Number(
														Math.round(
															Number(currency?.conversion_rates?.USD) *
																Number(
																	Math.round(
																		Number(
																			Number(
																				Math.round(
																					location.state.selectedonward[0]
																						.Airline_Code
																						? location.state.selectedonward[0]
																								.Fares.AirportTax_Amount
																						: location.state.selectedonward[0]
																								.AirlineCode
																						? location.state.selectedonward[0]
																								.Fare.AirportTax_Amount
																						: 0
																				)
																			) +
																				Number(
																					Math.round(
																						location.state.selectedReturn[0]
																							.Airline_Code
																							? location.state.selectedReturn[0]
																									.Fares.AirportTax_Amount
																							: location.state.selectedReturn[0]
																									.AirlineCode
																							? location.state.selectedReturn[0]
																									.Fare.AirportTax_Amount
																							: 0
																					)
																				)
																		)
																	)
																)
														)
												  )}`
											: location.state.OneflightData
											? `${'USD'} ${Number(
													Math.round(
														Number(currency?.conversion_rates?.USD) *
															Number(
																Math.round(
																	Number(
																		location.state.OneflightData.Supplier ===
																			'ETR'
																			? Math.round(
																					location.state.OneflightData.Fares
																						.AirportTax_Amount
																			  )
																			: location.state.OneflightData
																					.Supplier === 'TBO'
																			? Math.round(
																					location.state.OneflightData.Fare
																						.AirportTax_Amount
																			  )
																			: location.state.OneflightData
																					.Supplier === 'TJK'
																			? Math.round(
																					location.state.OneflightData
																						.totalPriceList.fd.AirportTax_Amount
																			  )
																			: ''
																	)
																)
															)
													)
											  )}`
											: ''}
									</TitleText>
								</Box>
								<Divider />
								{value ? (
									<Box
										display='flex'
										justifyContent='space-between'
										mt={'5px'}
										mb={'5px'}>
										<BodyText>Convenience Fees</BodyText>
										<TitleText two>Rs 200</TitleText>
									</Box>
								) : (
									''
								)}

								<Divider />
							</Box>
						</Box>
					</Grid>
				</Grid>
				{/* </Container> */}
			</Box>
			<Footer />
			<AddGSTModal />
			<Drawers />
			{/* <SessionExpireModal /> */}
			<Login />
		</>
	);
};

export default SummaryFlight;
