import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	makeStyles,
	Box,
	useTheme,
	useMediaQuery,
	Divider,
	Grid,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import BodyText from './BodyText';
import TitleText from './TitleText';
import SuccessModal from './SuccessModal';
import {
	toggleChangeLogoModal,
	toggleEasebuzzModal,
	togglePGModal,
	toggleRegisterModal,
} from '../../reducers/UiReducer';
import {
	agentloginDispatch,
	agentlogoDispatch,
} from '../../reducers/UserReducer';
import ErrorModal from './ErrorModal';
import LoaderModal from './LoaderModal';
import PGForm from '../../form/PGForm';
import EassBuzzBusForm from '../../form/EassBuzzBusForm';
import EaseBuzzHotelForm from '../../form/EaseBuzzHotelForm';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
	nextButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		fontWeight: 600,
		width: '100%',
		padding: 10,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
}));

const EaseBuzzHotelModal = ({ pay }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const loaction = useLocation();
	const { worldline, loadinguser, easebuzz } = useSelector((state) => state.ui);
	const [upload, setUpload] = useState(null);
	const [user, setUser] = useState({
		filename: '',
	});
	const { filename } = user;
	const onInputChange = (e) => {
		// setUser({ ...user, [e.target.name]: e.target.value });
		const fileToUpload = e.target.files;
		// setUser({ ...user, [e.target.name]: fileToUpload[0] });
		// console.log(e.target.files);
		setUpload(fileToUpload[0]);
	};
	const handleClose = () => {
		dispatch(toggleEasebuzzModal(false));
	};
	console.log('loaction', loaction);
	const onSubmit = async (e) => {
		e.preventDefault();
		// var dat = user;
		// var formData = JSON.stringify(dat);
		// console.log(formData);
		// let formData2 = {
		// 	agent_id: 1,
		// 	agentLogo: dat,
		// };
		// console.log(e);
		let formUpdate = new FormData();
		formUpdate.append('agent_id', 1);
		formUpdate.append('agentLogo', upload);
		dispatch(agentlogoDispatch(formUpdate));
	};
	const theme = useTheme();
	const fileInputRef = React.createRef();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<Dialog
			PaperProps={{
				style: {
					borderRadius: 20,
					maxWidth: 700,
				},
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={easebuzz}
			fullWidth={true}
			maxWidth={'xs'}>
			<DialogContent
				style={{
					paddingBottom: 20,
					paddingTop: 20,
				}}>
				<h2
					three={matches ? false : true}
					style={{ fontWeight: 600, textAlign: 'center' }}>
					Confirm Your Payment Detail
				</h2>
				{/* <EaseBuzzForm /> */}
				<EaseBuzzHotelForm />
				{/* <EassBuzzBusForm pay={pay} /> */}
				{/* <AssestmentForm /> */}
				<Grid container style={{ justifyContent: 'center' }}>
					<Box
						textAlign='center'
						style={{ cursor: 'pointer' }}
						mt={2}
						onClick={() => dispatch(toggleRegisterModal(false))}>
						<BodyText textStyle={{ color: '#E61855', fontWeight: 600 }}>
							Cancel
							<Divider style={{ backgroundColor: '#E61855' }} />
						</BodyText>
					</Box>
				</Grid>
				<SuccessModal />
				<ErrorModal />
				{loadinguser ? <LoaderModal /> : ''}
			</DialogContent>
		</Dialog>
	);
};

export default EaseBuzzHotelModal;
