import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Box,
	Grid,
	MenuItem,
	FormControlLabel,
	Radio,
	TextField as TextField2,
} from '@material-ui/core';
import { Field, Form, Formik, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { walletBalanceAgentDispatch } from '../reducers/UserReducer';
import * as Yup from 'yup';
import {
	FlightFareQuoteEtravDispatch,
	FlightFareQuoteDispatch,
	FlightFareQuoteReturnDispatch,
} from '../reducers/HomeReducer';
import {
	setHotelBookingData,
	setHotelFormData,
	setPayment,
	toggleEasebuzzModal,
} from '../reducers/UiReducer';
import { useDispatch, useSelector } from 'react-redux';
import { FlightFareQuoteTripJackDispatch } from '../reducers/TripjackReducer';
import TitleText from '../components/reusableComponents/TitleText';
import {
	hoteladdBookingDispatch,
	hotelRoomBlockDispatch,
} from '../reducers/HotelReducer';
const title = ['Mr', 'Mrs', 'Ms', 'Mstr', 'Miss'];
const titleC = ['Mstr', 'Miss'];
const titleI = ['Mr', 'Ms', 'Mstr', 'Miss'];
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		// backgroundColor: 'white',
		marginBottom: 30,
		width: '100%',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
		},
		'& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
			borderRadius: 5,
		},
	},
	InputPropsStyle: {
		// backgroundColor: 'white',
		borderRadius: 15,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: 'rgb(239, 51, 57)',
		color: 'white',
		borderRadius: 12,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: 'rgb(239, 51, 57)',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	date: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
			width: '100%',
		},
	},
	dob: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	passport: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	root: {
		'& .MuiFormHelperText-root': {
			fontSize: 12,
		},
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
		// '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
		// 	fontSize: 14,
		// },
	},
	root2: {
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
	},
}));
const age = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const HotelSummaryForm = ({ time }) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [value, setValue] = React.useState(null);
	const [offline, setOffline] = useState(false);
	const [easebuzz, setEasebuzz] = useState(false);
	// const { currency } = useSelector((state) => state.home);

	const date = new Date();
	const [child1, setChild1] = React.useState(new Date());
	const [child2, setChild2] = React.useState(new Date());
	const [child3, setChild3] = React.useState(new Date());
	const [child4, setChild4] = React.useState(new Date());
	const [child5, setChild5] = React.useState(new Date());
	const [child6, setChild6] = React.useState(new Date());
	const [infant1, setInfant1] = React.useState(new Date());
	const [infant2, setInfant2] = React.useState(new Date());
	const [infant3, setInfant3] = React.useState(new Date());
	const [infant4, setInfant4] = React.useState(new Date());
	const [infant5, setInfant5] = React.useState(new Date());
	const [infant6, setInfant6] = React.useState(new Date());
	const { etravflightFare, selectedOrigin, selectedDepart, payment } =
		useSelector((state) => state.ui);
	const { farequotetripjack } = useSelector((state) => state.tripjack);
	const { agentlogin, walletagent } = useSelector((state) => state.user);
	const { ssrTBO, currency } = useSelector((state) => state.home);
	function calculate_age(dob) {
		var diff_ms = Date.now() - dob.getTime();
		var age_dt = new Date(diff_ms);

		// return Math.abs(age_dt.getUTCFullYear() - 1970);
		let age = Math.abs(age_dt.getUTCFullYear() - 1970);
		if (age < 6) {
			alert('Please Select Valid Age');
		} else if (age > 12) {
			alert('Please Select Valid Age');
		}
		console.log('Hen', Math.abs(age_dt.getUTCFullYear() - 1970));
	}
	// const Convert = Number(curr==='INR'?currency.conversion_rates.INR:curr==='USD'?currency.conversion_rates.USD:curr==='AFN'?currency.conversion_rates.AFN:1)
	const dispatch = useDispatch();

	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	const getActualFormat = (given: number) => {
		if (given < 10) {
			return 0 + '' + given.toString();
		} else {
			return given;
		}
	};
	const invoice = [
		date.getFullYear(),
		getActualFormat(date.getMonth() + 1),
		getActualFormat(date.getDate()),
		getActualFormat(date.getHours()),
		getActualFormat(date.getMinutes()),
		getActualFormat(date.getSeconds()),
	].join('');
	console.log('location', location);
	const handleHold = (e) => {
		if (e.target.value === 'easebuzz') {
			dispatch(setPayment(e.target.value));
			if (easebuzz) {
				setOffline(false);
				setEasebuzz(false);
			} else {
				setEasebuzz(true);
				setOffline(false);
			}
		} else if (e.target.value === 'offline') {
			dispatch(setPayment(e.target.value));
			if (offline) {
				setEasebuzz(false);
				setOffline(false);
			} else {
				setEasebuzz(false);
				setOffline(true);
			}
		}

		// console.log(e.target.value);
	};
	const handleFormSubmit = (data) => {
		let formData = {
			hotelName: location.state.selectedHotel.HotelName,
			guestNationality: 'IN',
			noOfRooms: location.state.room.length,
			resultIndex: location.state.selectedHotel.ResultIndex,
			hotelcode: location.state.selectedHotel.HotelCode,
			traceId: location.state.traceId,
			selectedRoom: [
				{
					ChildCount: location.state.item.ChildCount,
					RequireAllPaxDetails: location.state.item.RequireAllPaxDetails,
					RoomId: location.state.item.RoomId,
					RoomStatus: location.state.item.RoomStatus,
					RoomIndex: location.state.item.RoomIndex,
					RoomTypeCode: location.state.item.RoomTypeCode,
					RoomTypeName: location.state.item.RoomTypeName,
					RatePlanCode: location.state.item.RatePlanCode,
					RatePlan: location.state.item.RatePlan,
					InfoSource: location.state.item.InfoSource,
					SequenceNo: location.state.item.SequenceNo,
					DayRates: [
						{
							Amount: Number(location.state.item.DayRates[0].Amount).toString(),
							Date: location.state.item.DayRates[0].Date,
						},
					],

					SupplierPrice: location.state.item.SupplierPrice,
					Price: {
						CurrencyCode: 'INR',
						RoomPrice: Number(location.state.item.Price.RoomPrice).toString(),
						Tax: Number(location.state.item.Price.Tax).toString(),
						ExtraGuestCharge: Number(
							location.state.item.Price.ExtraGuestCharge
						).toString(),
						ChildCharge: Number(
							location.state.item.Price.ChildCharge
						).toString(),
						OtherCharges: Number(
							location.state.item.Price.OtherCharges
						).toString(),
						Discount: Number(location.state.item.Price.Discount).toString(),
						PublishedPrice: Number(
							location.state.item.Price.PublishedPrice
						).toString(),
						PublishedPriceRoundedOff: Number(
							location.state.item.Price.PublishedPriceRoundedOff
						).toString(),
						OfferedPrice: Number(
							location.state.item.Price.OfferedPrice
						).toString(),
						OfferedPriceRoundedOff: Number(
							location.state.item.Price.OfferedPriceRoundedOff
						).toString(),
						AgentCommission: Number(
							location.state.item.Price.AgentCommission
						).toString(),
						AgentMarkUp: Number(
							location.state.item.Price.AgentMarkUp
						).toString(),
						ServiceTax: Number(location.state.item.Price.ServiceTax).toString(),
						TCS: Number(location.state.item.Price.TCS).toString(),
						TDS: Number(location.state.item.Price.TDS).toString(),
						ServiceCharge: Number(
							location.state.item.Price.ServiceCharge
						).toString(),
						TotalGSTAmount: Number(
							location.state.item.Price.TotalGSTAmount
						).toString(),
						GST: {
							CGSTAmount: Number(
								location.state.item.Price.GST.CGSTAmount
							).toString(),
							CGSTRate: Number(
								location.state.item.Price.GST.CGSTRate
							).toString(),
							CessAmount: Number(
								location.state.item.Price.GST.CessAmount
							).toString(),
							CessRate: Number(
								location.state.item.Price.GST.CessRate
							).toString(),
							IGSTAmount: Number(
								location.state.item.Price.GST.IGSTAmount
							).toString(),
							IGSTRate: Number(
								location.state.item.Price.GST.IGSTRate
							).toString(),
							SGSTAmount: Number(
								location.state.item.Price.GST.SGSTAmount
							).toString(),
							SGSTRate: Number(
								location.state.item.Price.GST.SGSTRate
							).toString(),
							TaxableAmount: Number(
								location.state.item.Price.GST.TaxableAmount
							).toString(),
						},
					},
				},
			],
		};

		console.log('formData', formData);
		// console.log('formDataBooking', formDataBooking);

		// dispatch(setHotelFormData(data));
		let room1 = [];
		let room2 = [];
		let room3 = [];
		let room4 = [];
		let finalRoom = [];
		if (data) {
			if (data?.adult) {
				data?.adult[0]?.map((i) => {
					room1.push({
						...i,
					});
				});
				data?.adult[1]?.map((i) => {
					room2.push({
						...i,
					});
				});
				data?.adult[2]?.map((i) => {
					room3.push({
						...i,
					});
				});
				data?.adult[3]?.map((i) => {
					room4.push({
						...i,
					});
				});
			}
			if (data?.child) {
				data?.child[0]?.map((i) => {
					room1.push({
						...i,
					});
				});

				data?.child[1]?.map((i) => {
					room2.push({
						...i,
					});
				});

				data?.child[2]?.map((i) => {
					room3.push({
						...i,
					});
				});

				data?.child[3]?.map((i) => {
					room4.push({
						...i,
					});
				});
			}

			console.log('room1', room1);
			console.log('room2', room2);
			console.log('room3', room3);
			console.log('room4', room4);
			// if (room1.length > 0 && room2.length && room3.length)
			finalRoom = [[...room1], [...room2], [...room3], [...room4]];
			console.log('finalRoom', finalRoom);
			let addBooking = {
				orderId: location.state.orderID,
				supplier: 'TBO',
				paymentStatus: '0',
				userEmail: data.email,
				userPhone: data.phone,
				user_id: 'T-1',
				user_type: 'Guest',
				hotelCountry: 'IN',
				hotelCity: 'Kolkata',
				hotelCode: location.state.selectedHotel.HotelCode,
				hotelIndex: location.state.selectedHotel.ResultIndex,
				hotelName: location.state.selectedHotel.HotelName,
				hotelStar: location.state.selectedHotel.StarRating,
				hotelAddress: location.state.selectedHotel.HotelAddress,
				hotelContactNumber: '+91 98765432',
				checkIn: '2020-06-30',
				checkOut: '2020-06-30',
				noOfRooms: location.state.room.length,
				roomName: location.state.item.RoomTypeName,
				roomCode: location.state.item.RoomTypeCode,
				roomIndex: location.state.item.RoomIndex,
				actualFare:
					Number(Math.round(location.state.item.Total_Amount)) -
					Number(Math.round(location.state.item.markup)) -
					Number(Math.round(location.state.item.partnerMarkup)) -
					Number(Math.round(location.state.item.agentMarkup)),
				markup: location.state.item.markup,
				baseFare:
					Number(Math.round(location.state.item.Total_Amount)) -
					Number(Math.round(location.state.item.partnerMarkup)) -
					Number(Math.round(location.state.item.agentMarkup)),
				partnerMarkup: location.state.item.partnerMarkup,
				totalFare:
					Number(Math.round(location.state.item.Total_Amount)) -
					Number(Math.round(location.state.item.agentMarkup)),
				agent_id: 0,
				agentMarkup: location.state.item.agentMarkup,
				PGCharge:
					payment === 'offline'
						? 0
						: Number(
								Number(
									Math.round(
										Number(
											Math.round(
												Number(Math.round(location.state.item.Total_Amount)) -
													Number(Math.round(location.state.item.agentMarkup))
											) * 2.5
										) / 100
									)
								)
						  ),
				payableAmount:
					Number(Math.round(location.state.item.Total_Amount)) -
					Number(Math.round(location.state.item.agentMarkup)) +
					Number(
						Number(
							Math.round(
								Number(
									Math.round(
										Number(Math.round(location.state.item.Total_Amount)) -
											Number(Math.round(location.state.item.agentMarkup))
									) * 2.5
								) / 100
							)
						)
					),
				ticketCost:
					Number(Math.round(location.state.item.Total_Amount)) -
					Number(Math.round(location.state.item.agentMarkup)) +
					Number(
						Number(
							Math.round(
								Number(
									Math.round(
										Number(Math.round(location.state.item.Total_Amount)) -
											Number(Math.round(location.state.item.agentMarkup))
									) * 2.5
								) / 100
							)
						)
					),
				totalEarn: '0',
				cashBack: '0.00',
				couponDiscount: 0.0,
				couponDetails: '',
				paymentMode: payment === 'offline' ? 'OFFLINE' : 'ONLINE',
				pricingDetails: {},
				selectedHotel: location.state.selectedHotel,
				selectedRoom: location.state.item,
				selectedGuests: finalRoom,
				ticketSent: 0,
			};
			let debit = {
				orderId: location.state.orderID,
				paymentId: invoice,
			};
			// console.log('addBooking', addBooking);
			let formDataBooking = {
				orderId: location.state.orderID,
				paymentId: invoice,
				hotelName: location.state.selectedHotel.HotelName,
				guestNationality: 'IN',
				noOfRooms: location.state.room.length,
				resultIndex: location.state.selectedHotel.ResultIndex,
				hotelcode: location.state.selectedHotel.HotelCode,
				traceId: location.state.traceId,
				selectedRoom: finalRoom
					.filter((i) => i.length > 0)
					.map((item) => {
						return {
							ChildCount: location.state.item.ChildCount,
							RequireAllPaxDetails: location.state.item.RequireAllPaxDetails,
							RoomId: location.state.item.RoomId,
							RoomStatus: location.state.item.RoomStatus,
							RoomIndex: location.state.item.RoomIndex,
							RoomTypeCode: location.state.item.RoomTypeCode,
							RoomTypeName: location.state.item.RoomTypeName,
							RatePlanCode: location.state.item.RatePlanCode,
							RatePlan: location.state.item.RatePlan,
							InfoSource: location.state.item.InfoSource,
							SequenceNo: location.state.item.SequenceNo,
							DayRates: [
								{
									Amount: Number(
										location.state.item.DayRates[0].Amount
									).toString(),
									Date: location.state.item.DayRates[0].Date,
								},
							],

							SupplierPrice: location.state.item.SupplierPrice,
							Price: {
								CurrencyCode: 'INR',
								RoomPrice: Number(
									location.state.item.Price.RoomPrice
								).toString(),
								Tax: Number(location.state.item.Price.Tax).toString(),
								ExtraGuestCharge: Number(
									location.state.item.Price.ExtraGuestCharge
								).toString(),
								ChildCharge: Number(
									location.state.item.Price.ChildCharge
								).toString(),
								OtherCharges: Number(
									location.state.item.Price.OtherCharges
								).toString(),
								Discount: Number(location.state.item.Price.Discount).toString(),
								PublishedPrice: Number(
									location.state.item.Price.PublishedPrice
								).toString(),
								PublishedPriceRoundedOff: Number(
									location.state.item.Price.PublishedPriceRoundedOff
								).toString(),
								OfferedPrice: Number(
									location.state.item.Price.OfferedPrice
								).toString(),
								OfferedPriceRoundedOff: Number(
									location.state.item.Price.OfferedPriceRoundedOff
								).toString(),
								AgentCommission: Number(
									location.state.item.Price.AgentCommission
								).toString(),
								AgentMarkUp: Number(
									location.state.item.Price.AgentMarkUp
								).toString(),
								ServiceTax: Number(
									location.state.item.Price.ServiceTax
								).toString(),
								TCS: Number(location.state.item.Price.TCS).toString(),
								TDS: Number(location.state.item.Price.TDS).toString(),
								ServiceCharge: Number(
									location.state.item.Price.ServiceCharge
								).toString(),
								TotalGSTAmount: Number(
									location.state.item.Price.TotalGSTAmount
								).toString(),
								GST: {
									CGSTAmount: Number(
										location.state.item.Price.GST.CGSTAmount
									).toString(),
									CGSTRate: Number(
										location.state.item.Price.GST.CGSTRate
									).toString(),
									CessAmount: Number(
										location.state.item.Price.GST.CessAmount
									).toString(),
									CessRate: Number(
										location.state.item.Price.GST.CessRate
									).toString(),
									IGSTAmount: Number(
										location.state.item.Price.GST.IGSTAmount
									).toString(),
									IGSTRate: Number(
										location.state.item.Price.GST.IGSTRate
									).toString(),
									SGSTAmount: Number(
										location.state.item.Price.GST.SGSTAmount
									).toString(),
									SGSTRate: Number(
										location.state.item.Price.GST.SGSTRate
									).toString(),
									TaxableAmount: Number(
										location.state.item.Price.GST.TaxableAmount
									).toString(),
								},
							},
							HotelPassenger: item.map((i, index) => {
								return {
									Title: i.title,
									FirstName: i.firstname,
									LastName: i.lastname,
									Age: i.age ? i.age : '22',
									PaxType: i.age ? 2 : 1,
									Email: null,
									LeadPassenger: index === 0 ? true : false,
									PAN: 'AALCR5274P',
								};
							}),
						};
					}),
			};
			dispatch(setHotelFormData(data));
			dispatch(setHotelBookingData(formDataBooking));
			dispatch(
				hoteladdBookingDispatch(
					addBooking,
					formData,
					payment,
					formDataBooking,
					history,
					debit
				)
			);
			dispatch(
				hotelRoomBlockDispatch(formData, payment, formDataBooking, history)
			);
			dispatch(toggleEasebuzzModal(true));
			// console.log('formDataBooking', formDataBooking);
		}
		console.log('data', data);

		// dispatch(hoteladdBookingDispatch(fo));
		// dispatch(
		// 	hotelRoomBlockDispatch(formData, payment, formDataBooking, history)
		// );
		// if (payment === 'easebuzz') {
		// 	dispatch(toggleEasebuzzModal(true));
		// }
	};
	//   const handleChange = (value) => {
	//     console.log(value);
	//   };
	const adult = 1;
	const child = 0;
	const infant = 0;
	const ar = [];
	const ar2 = [];
	const ar3 = [];
	for (let i = 1; i <= location.state.room.length; i++) {
		ar.push({ title: '', firstname: '', lastname: '' });
	}
	for (let i = 1; i <= location.state.room.length; i++) {
		ar2.push({});
	}
	for (let i = 1; i <= infant; i++) {
		ar3.push({});
	}
	// console.log('first', ar2);

	return (
		<>
			<Formik
				initialValues={{
					phone: '',
					email: '',
					adults: ar,
					childs: ar2,
					infants: ar3,
				}}
				validationSchema={Yup.object({
					phone: Yup.string()
						.required('Phone required')
						// .matches(phoneRegExp, 'Phone number is not valid')
						.min(10, 'too short')
						.max(10, 'too long'),
					email: Yup.string()
						.required('email required')
						.email('Enter valid email'),
					// adults: Yup.array().of(
					// 	Yup.object().shape({
					// 		title: Yup.string().required('title required'),
					// 		firstname: Yup.string().required('firstname required'),
					// 		lastname: Yup.string().required('lastname required'),
					// 	})
					// ),
					// childs: Yup.array().of(
					// 	Yup.object().shape({
					// 		title: Yup.string().required('title required'),
					// 		firstname: Yup.string()
					// 			.required('firstname required')
					// 			.matches(alpha, 'firstname is not valid'),
					// 		lastname: Yup.string()
					// 			.required('lastname required')
					// 			.matches(alpha, 'lastname is not valid')
					// 			.test('validator-custom-name', function (value) {
					// 				const validation = LastNameValidation(value);
					// 				console.log('validation', validation);
					// 				if (validation.isValid) {
					// 					return this.createError({
					// 						path: this.path,
					// 						message: validation.errorMessage,
					// 					});
					// 				} else {
					// 					return true;
					// 				}
					// 			}),
					// 		dob: Yup.string()
					// 			.required('dob required')
					// 			.test(
					// 				'DOB',
					// 				'Please choose a valid date of birth',
					// 				(date) => moment().diff(moment(date), 'years') <= 12
					// 			),
					// 	})
					// ),
					// infants: Yup.array().of(
					// 	Yup.object().shape({
					// 		title: Yup.string().required('title required'),
					// 		firstname: Yup.string()
					// 			.required('firstname required')
					// 			.matches(alpha, 'firstname is not valid'),
					// 		lastname: Yup.string()
					// 			.required('lastname required')
					// 			.matches(alpha, 'lastname is not valid')
					// 			.test('validator-custom-name', function (value) {
					// 				const validation = LastNameValidation(value);
					// 				console.log('validation', validation);
					// 				if (validation.isValid) {
					// 					return this.createError({
					// 						path: this.path,
					// 						message: validation.errorMessage,
					// 					});
					// 				} else {
					// 					return true;
					// 				}
					// 			}),
					// 		dob: Yup.string()
					// 			.required('dob required')
					// 			.test(
					// 				'DOB',
					// 				'Please choose a valid date of birth',
					// 				(date) => moment().diff(moment(date), 'years') <= 2
					// 			),
					// 	})
					// ),
				})}
				onSubmit={handleFormSubmit}>
				{(formik) => (
					<Form>
						<Box
							className={classes.root}
							style={{
								background: 'white',
								padding: '10px 15px',
								border: '1px solid #e6e6e6',
								marginTop: '10px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20, alignItems: 'baseline' }}>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='email'
										type='email'
										variant='outlined'
										// error={errors.email}
										label='Email ID'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='phone'
										type='number'
										variant='outlined'
										// error={errors.phone}
										label='Mobile No.'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
							</Grid>
						</Box>
						<FieldArray
							name='adult'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.adults.map((adult, index) =>
											Array.from(
												Array(
													index === 0
														? location.state.adultH[0]
														: index === 1
														? location.state.adultH[1]
														: index === 2
														? location.state.adultH[2]
														: location.state.adultH[3]
												)
											).map((i, ind) => {
												return (
													<>
														<Box
															className={classes.root}
															style={{
																background: 'white',
																padding: '10px 15px',
																border: '1px solid #e6e6e6',
																marginTop: '10px',
																boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
																borderRadius: '5px',
															}}>
															<div>{`Enter the Detail of Room.${
																index + 1
															}, Adult ${ind + 1}`}</div>

															<Grid
																container
																spacing={2}
																alignItems='center'
																style={{
																	marginTop: 20,
																	alignItems: 'baseline',
																}}>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={4}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`adults.${index}.${ind}.title`}
																		type='text'
																		variant='outlined'
																		label='Select Title'
																		// error={errors.titleA1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{title.map((item, index) => (
																			<MenuItem key={index} value={item}>
																				{item}
																			</MenuItem>
																		))}
																	</Field>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adults.${index}.${ind}.firstname`}
																		type='text'
																		// error={errors.first_nameA1}
																		variant='outlined'
																		label='Enter First Name'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adults.${index}.${ind}.lastname`}
																		type='text'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Enter Last Name'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
															</Grid>
														</Box>
													</>
												);
											})
										)}
									</div>
								);
							}}
						/>
						<FieldArray
							name='child'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values?.childs?.map((child, index) =>
											Array.from(
												Array(
													index === 0
														? location?.state?.childH[0]
														: index === 1
														? location?.state?.childH[1]
														: index === 2
														? location?.state?.childH[2]
														: location?.state?.childH[3]
												)
											).map((i, ind) => {
												return (
													<>
														<Box
															className={classes.root}
															style={{
																background: 'white',
																padding: '10px 15px',
																border: '1px solid #e6e6e6',
																marginTop: '10px',
																boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
																borderRadius: '5px',
															}}>
															<div>{`Enter the Detail of Room.${
																index + 1
															}, Child ${ind + 1}`}</div>

															{/* <Field
															disabled
															type='text'
															name={`chil.${index}.firsName`}
															value={chil.firsName}
														/>
														<Field type='date' name={`chil.${index}.date`} /> */}
															<Grid
																container
																spacing={2}
																alignItems='center'
																style={{
																	marginTop: 20,
																	alignItems: 'baseline',
																}}>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={3}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`child.${index}.${ind}.title`}
																		type='text'
																		variant='outlined'
																		label='Select Title'
																		// error={errors.titleC1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{titleC.map((item, index) => (
																			<MenuItem key={index} value={item}>
																				{item}
																			</MenuItem>
																		))}
																	</Field>
																</Grid>
																<Grid item xs={12} sm={6} lg={3}>
																	<Field
																		component={TextField}
																		name={`child.${index}.${ind}.firstname`}
																		type='text'
																		variant='outlined'
																		// error={errors.first_nameC1}
																		label='Enter First Name'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={3}>
																	<Field
																		component={TextField}
																		name={`child.${index}.${ind}.lastname`}
																		type='text'
																		variant='outlined'
																		// error={errors.last_nameC1}
																		label='Enter Last Name'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={3}>
																	{/* <Field
																		component={TextField}
																		name={`child.${index}.${ind}.dob`}
																		type='date'
																		variant='outlined'
																		// error={errors.last_nameC1}
																		label='Select DOB'
																		className={classes.selectField}
																		// style={{color:'lightgrey'}}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/> */}
																	<Field
																		component={TextField}
																		select
																		name={`child.${index}.${ind}.age`}
																		type='text'
																		variant='outlined'
																		label='Select Age'
																		// error={errors.titleC1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{age.map((item, index) => (
																			<MenuItem key={index} value={item}>
																				{item}
																			</MenuItem>
																		))}
																	</Field>
																</Grid>
																{selectedOrigin.country_code === 'IN' &&
																selectedDepart.country_code === 'IN' ? (
																	''
																) : (
																	<>
																		<Grid item xs={12} sm={6} lg={4}>
																			<Field
																				component={TextField}
																				name={`child.${index}.${ind}.passportnumber`}
																				type='text'
																				// error={errors.last_nameA1}
																				variant='outlined'
																				label='Enter Passport Number'
																				className={classes.selectField}
																				InputProps={{
																					className: classes.InputPropsStyle,
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6} lg={4}>
																			<Field
																				component={TextField}
																				name={`child.${index}.${ind}.expiry`}
																				type='date'
																				// error={errors.last_nameA1}
																				variant='outlined'
																				label='Select Expiry Date'
																				className={classes.selectField}
																				InputProps={{
																					className: classes.InputPropsStyle,
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																		</Grid>
																	</>
																)}
															</Grid>
														</Box>
													</>
												);
											})
										)}
									</div>
								);
							}}
						/>
						<FieldArray
							name='infant'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.infants.map((infant, index) => (
											<>
												<Box
													className={classes.root}
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
														borderRadius: '5px',
													}}>
													<div>{`Enter the Detail of Infant.${index + 1}`}</div>
													{/* <Field
														disabled
														type='text'
														name={`chil.${index}.firsName`}
														value={chil.firsName}
													/>
													<Field type='date' name={`chil.${index}.date`} /> */}
													<Grid
														container
														spacing={2}
														alignItems='center'
														style={{ marginTop: 20, alignItems: 'baseline' }}>
														<Grid
															item
															xs={12}
															sm={6}
															lg={3}
															className={classes.root2}
															style={{ alignItems: 'center' }}>
															<Field
																component={TextField}
																select
																name={`infant.${index}.title`}
																type='text'
																variant='outlined'
																label='Select Title'
																// error={errors.titleC1}
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	className: classes.inputPropsStyle,
																}}>
																{titleI.map((item, index) => (
																	<MenuItem key={index} value={item}>
																		{item}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infant.${index}.firstname`}
																type='text'
																variant='outlined'
																// error={errors.first_nameC1}
																label='Enter First Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infant.${index}.lastname`}
																type='text'
																variant='outlined'
																// error={errors.last_nameC1}
																label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infant.${index}.dob`}
																type='date'
																variant='outlined'
																// placeholder="Select DOB"
																// error={errors.last_nameC1}
																label='Select DOB'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														{selectedOrigin.country_code === 'IN' &&
														selectedDepart.country_code === 'IN' ? (
															''
														) : (
															<>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`infant.${index}.passportnumber`}
																		type='text'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`infant.${index}.expiry`}
																		type='date'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Select Expiry Date'
																		// value=''
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
															</>
														)}
													</Grid>
												</Box>
											</>
										))}
									</div>
								);
							}}
						/>
						<Box
							mt={'10px'}
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<TitleText
								textStyle={{
									fontFamily: 'unset',
									fontWeight: 500,
									fontSize: 20,
								}}>
								Payment Method
							</TitleText>
						</Box>
						<Box
							style={{
								padding: 15,
								background: 'white',
								color: 'gray',
								border: '1px solid #e6e6e6',
								margin: '0 0 20px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							{agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.role === 'Agent' ? (
								<>
									<FormControlLabel
										style={{ marginRight: 0 }}
										value='offline'
										control={
											<Radio
												checked={offline}
												onClick={handleHold}
												disabled={
													Number(
														Math.round(walletagent.result.balanceAmount)
													) <=
													Number(
														Math.round(
															Number(location.state.item.Total_Amount) -
																Number(location.state.item.MFB_Discount)
														)
													)
														? true
														: false
												}
											/>
										}
									/>
									<span
										style={{
											opacity:
												Number(Math.round(walletagent.result.balanceAmount)) <=
												Number(
													Math.round(
														Number(location.state.item.Total_Amount) -
															Number(location.state.item.MFB_Discount)
													)
												)
													? 0.3
													: '',
										}}>
										Offline Amount
									</span>
									<span
										style={{
											marginLeft: 5,
											color: 'blue',
											opacity:
												Number(Math.round(walletagent.result.balanceAmount)) <=
												Number(
													Math.round(
														Number(location.state.item.Total_Amount) -
															Number(location.state.item.MFB_Discount)
													)
												)
													? 0.3
													: '',
										}}>
										<span style={{ marginRight: 5, marginLeft: 5 }}>{'₹'}</span>
										{Number(
											Math.round(
												Number(location.state.item.Total_Amount) -
													Number(location.state.item.MFB_Discount)
											)
										)}
									</span>
								</>
							) : (
								''
							)}
							<FormControlLabel
								style={{ marginRight: 0, marginLeft: 10 }}
								value='easebuzz'
								control={
									<Radio
										checked={easebuzz}
										onClick={handleHold}
										disabled={false}
									/>
								}
							/>
							<span
								style={
									{
										// opacity: 0.3
									}
								}>
								Pay With EaseBuzz
							</span>
							<span
								style={{
									marginLeft: 5,
									color: 'blue',
									// opacity: 0.3
								}}>
								USD {''}
								{Number(
									Math.round(
										Number(currency.conversion_rates.USD) *
											Number(
												Number(
													Math.round(
														Number(location.state.item.Total_Amount) -
															Number(location.state.item.MFB_Discount)
													)
												) +
													Number(
														Math.round(
															Number(
																Math.round(
																	Number(location.state.item.Total_Amount) -
																		Number(location.state.item.MFB_Discount)
																)
															) * 2.5
														) / 100
													)
											)
									)
								)}
								{/* <span style={{ marginRight: 5, marginLeft: 5 }}>{'₹'}</span>
								{Number(
									Math.round(
										Number(location.state.item.Total_Amount) -
											Number(location.state.item.MFB_Discount)
									)
								) +
									Number(
										Math.round(
											Number(
												Math.round(
													Number(location.state.item.Total_Amount) -
														Number(location.state.item.MFB_Discount)
												)
											) * 2.5
										) / 100
									)} */}
							</span>
						</Box>
						<Grid
							container
							spacing={4}
							alignItems='center'
							style={{ marginBottom: 20 }}>
							<Grid item xs={3}></Grid>
							<Grid item xs={12} lg={4}>
								<button
									type='submit'
									className={classes.createButton}
									disabled={offline || easebuzz ? false : true}
									style={{
										marginTop: 10,
										fontSize: 16,
										cursor: 'pointer',
										background: offline || easebuzz ? '' : 'grey',
										opacity: offline || easebuzz ? '' : 0.3,
									}}>
									Procced To Payment
								</button>
							</Grid>
							<Grid item xs={4}></Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default HotelSummaryForm;
