import React, { useState } from 'react';
import {
	Avatar,
	Box,
	Hidden,
	Divider,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BodyText from './BodyText';
// import '../../pages/Desktop/Homepage2.css';
import { useDispatch, useSelector } from 'react-redux';
import SuccessModal from './SuccessModal';
import {
	setForgot,
	setSection,
	toggleLoginModal,
	toggleRegisterModal,
	toggleRegisterTravellerModal,
} from '../../reducers/UiReducer';
import {
	agentloginDispatch,
	travellerloginDispatch,
	agentloginSuccess,
} from '../../reducers/UserReducer';
import ErrorModal from './ErrorModal';
import RegisterTravellerModal from './RegisterTravellerModal';
import Login from './Login';
const HeaderSection = ({ id }) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleTheme = () => {
		let themeBtn = document.querySelector('#theme-btn');
		themeBtn.classList.toggle('fa-sun');

		if (themeBtn.classList.contains('fa-sun')) {
			document.body.classList.add('active');
		} else {
			document.body.classList.remove('active');
		}
	};

	const [user, setUser] = useState({
		username: '',
		password: '',
	});
	const { username, password } = user;
	const onInputChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};
	const onSubmit = async (e) => {
		e.preventDefault();
		var dat = user;
		var formData = JSON.stringify(dat);
		dispatch(agentloginDispatch(formData, history));
	};
	const onSubmit2 = async (e) => {
		e.preventDefault();
		var dat = user;
		var formData = JSON.stringify(dat);
		dispatch(travellerloginDispatch(formData, history));
	};
	const history = useHistory();
	const handleChange = (e) => {
		console.log(e.target.name[0]);
	};
	const { section } = useSelector((state) => state.ui);
	const { logo, admin, currency } = useSelector((state) => state.home);
	const { travellerlogin, agentlogin, walletagent, wallet, agentlogoget } =
		useSelector((state) => state.user);
	return (
		<>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					paddingRight: 15,
					paddingLeft: 15,
					marginRight: 'auto',
					marginLeft: 'auto',
				}}>
				<Box style={{ paddingBottom: 4.5, paddingTop: 4.5 }}>
					<i
						class='fas fa-blender-phone'
						style={{ fontSize: matches ? 12 : '' }}></i>
					<span style={{ color: '#065999', fontSize: 13 }}>
						{admin?.result[0]?.mobile_no}{' '}
					</span>
					<span style={{ display: 'inline-block', width: '1px' }}></span>
					<i
						class='fa fa-fw fa-envelope'
						style={{ fontSize: matches ? 12 : '' }}></i>
					<span style={{ color: '#065999', fontSize: 13 }}>
						{admin?.result[0]?.email}
					</span>
					{/* <i
						onClick={() => history.push('/bankdetail')}
						className='fas fa-bank me-2'
						style={{ marginLeft: 12, cursor: 'pointer' }}></i>
					<span
						style={{ color: '#065999', fontSize: 13, cursor: 'pointer' }}
						onClick={() => history.push('/bankdetail')}>
						Bank Details
					</span> */}
				</Box>

				<Box style={{ marginRight: 15, cursor: 'pointer' }}>
					<span
						style={{ marginRight: 10 }}
						onClick={() => {
							history.push('/dashboard');
						}}>
						{agentlogin && agentlogin.status === 200 ? 'Dashboard' : ''}
					</span>
					<span
						onClick={() => {
							dispatch(agentloginSuccess(null));
							sessionStorage.removeItem('userInfoAgent');
							history.push('/');
						}}>
						{agentlogin && agentlogin.status === 200 ? 'Signout' : ''}
					</span>
				</Box>
			</Box>
			<header
				style={{
					alignItems: 'center',
					background: '#fff',
					// boxShadow: '0 0.5rem 1.5rem rgb(0 0 0 / 10%)',
					boxShadow: '0 0.5rem 1.5rem rgba(0,0,0,.1)',
					display: 'flex',
					justifyContent: 'space-between',
					left: 0,
					padding: 5,
					position: 'absolute',
					right: 0,
					top: 30,
					zIndex: 1000,
				}}
				id='headerticket'>
				<img
					style={{
						cursor: 'pointer',
						height: matches ? 30 : 45,
						width: 150,
					}}
					src={logo?.result[0].logo}
					onClick={() => history.push('/')}></img>
				{/* </a> */}
				<Hidden only={['xs']}>
					<Box display='flex'>
						<Box
							display='flex'
							alignItems='center'
							onClick={() => {
								dispatch(setSection('flight'));
								history.push('/');
							}}>
							<i
								class='fas fa-plane-departure'
								style={{
									color: section === 'flight' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>
							<span
								style={{
									color: section === 'flight' ? 'orange' : '#0A95FF',
									marginRight: 10,
									cursor: 'pointer',
								}}>
								Flight
							</span>
						</Box>
						{/* <Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							mr={'20px'}
							onClick={() => {
								dispatch(setSection('bus'));
								history.push('/');
							}}>
							<i
								class='fas fa-bus'
								style={{
									color: section === 'bus' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>
							<span
								style={{
									color: section === 'bus' ? 'orange' : '#0A95FF',
									marginLeft: 5,
									cursor: 'pointer',
								}}>
								Buses
							</span>
						</Box> */}
						{/* <Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							mr={'10px'}
							onClick={() => {
								dispatch(setSection('hotel'));
								history.push('/');
							}}>
							<i
								class='fas fa-building'
								style={{
									color: section === 'hotel' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>

							<span
								style={{
									color: section === 'hotel' ? 'orange' : '#0A95FF',
									marginLeft: 5,
									cursor: 'pointer',
								}}>
								Hotels
							</span>
						</Box> */}

						{/* <Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							mr={'30px'}
							onClick={() => history.push('/')}>
							<a
								href='https://www.marinersforex.com/holiday-packages'
								target='_blank'>
								<i
									class='fas fa-umbrella-beach'
									style={{ color: '#0A95FF', cursor: 'pointer' }}></i>
								<span
									style={{
										color: '#0A95FF',
										marginLeft: 5,
										cursor: 'pointer',
									}}>
									Holidays
								</span>
							</a>
						</Box> */}
						<Hidden only={['xs']}>
							<Box display='flex' alignItems='center'>
								{travellerlogin && travellerlogin.status === 200 ? (
									<Box
										ml={'10px'}
										display='flex'
										alignItems='center'
										style={{ cursor: 'pointer' }}
										onClick={() => history.push('/dashboard')}>
										<Avatar
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
											}}>
											{travellerlogin &&
												travellerlogin.result &&
												travellerlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : agentlogin && agentlogin.status === 200 ? (
									<Box style={{ alignItems: 'center' }} display='flex'>
										<Box
											style={{
												marginRight: 10,
												fontSize: 19,
												cursor: 'pointer',
											}}
											onClick={() => history.push('/dashboard')}>
											{`Welcome ${
												agentlogin ? agentlogin?.result.result.agencyName : ''
											}[Agent Code : FLY0000${
												agentlogin ? agentlogin?.result.result.id : ''
											}]`}
										</Box>

										<Box style={{ fontSize: 20, marginRight: 15 }}>
											USD{' '}
											{walletagent
												? Number(
														Math.round(
															Number(currency?.conversion_rates?.USD) *
																Number(walletagent?.result.balanceAmount)
														)
												  )
												: ''}
										</Box>
										<Avatar
											onClick={() => history.push('/dashboard')}
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
												marginRight: 15,
												cursor: 'pointer',
											}}>
											{agentlogin &&
												agentlogin.result &&
												agentlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : (
									<>
										<Box
											onClick={() => dispatch(toggleLoginModal(true))}
											style={{
												background: 'rgb(0, 55, 114)',
												color: 'white',
												padding: '8px',
												fontWeight: 'bold',
												fontSize: '.8em',
												cursor: 'pointer',
											}}>
											Login or Create Account
										</Box>
									</>
								)}
							</Box>
						</Hidden>
					</Box>
				</Hidden>
				<Hidden only={['lg', 'xl', 'sm', 'md']}>
					<div className='icons'>
						{agentlogin && agentlogin.status === 200 ? (
							<Avatar
								onClick={() => history.push('/dashboard')}
								style={{
									color: 'white',
									background: 'rgb(239, 51, 57)',
									marginRight: 15,
									cursor: 'pointer',
									borderRadius: 15,
								}}>
								{agentlogin &&
									agentlogin.result &&
									agentlogin.result.result.name
										.match(/(\b\S)?/g)
										.join('')
										.match(/(^\S|\S$)?/g)
										.join('')
										.toUpperCase()}
							</Avatar>
						) : (
							<Box
								onClick={() => dispatch(toggleLoginModal(true))}
								style={{
									background: 'rgb(0, 55, 114)',
									color: 'white',
									// padding: '8px',
									fontWeight: 'bold',
									fontSize: '10px',
									cursor: 'pointer',
									width: '100%',
									paddingLeft: 5,
									paddingRight: 5,
								}}>
								<span>Login or Create Account</span>
							</Box>
						)}
					</div>
				</Hidden>
			</header>
			<RegisterTravellerModal />
			<SuccessModal />
			<ErrorModal />
			<Login />
		</>
	);
};

export default HeaderSection;
