import React from 'react';
import {
	Box,
	Container,
	Grid,
	Drawer,
	makeStyles,
	Divider,
	Avatar,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Chip,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import { toggleFlightModal } from '../../reducers/UiReducer';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
	setReturnFlightId,
	setSelectedselectedReturn,
} from '../../reducers/UiReducer';
const CardFlightReturn = ({ item, index, selectedReturn, matches }) => {
	const { RflightId, curr } = useSelector((state) => state.ui);
	const { loading, currency } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	function convertH2M(timeInHour) {
		console.log(timeInHour);
		var timeParts = timeInHour.split(':');
		return Number(timeParts[0]) * 60 + Number(timeParts[1]);
	}
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	return (
		<>
			<Box
				onClick={() => dispatch(setReturnFlightId(index))}
				style={{
					padding: 10,
					cursor: 'pointer',
					boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
					background: index === RflightId ? 'rgba(57,201,187,.14)' : 'white',
					border: index === RflightId ? '1px solid #39c9bb' : '',
					color: index === RflightId ? '#666' : '',
				}}>
				<Box display='flex' justifyContent='space-between'>
					<Box display='flex' alignItems='center'>
						<img
							src={`https://nitish.musafirbazar.com/static/media/${
								item.AirlineCode
									? item.AirlineCode
									: item.Airline_Code
									? item.Airline_Code
									: item.Supplier === 'TJK'
									? item.sI[0].fD.aI.code
									: ''
							}.gif`}
							width='40px'
							height='25px'></img>
						{item.AirlineCode
							? ''
							: item.Airline_Code
							? '*'
							: item.Supplier === 'TJK'
							? '**'
							: ''}
						<Box alignItems='center' ml={'5px'}>
							<span
								style={{
									fontFamily: 'Josefin Sans, sans-serif',
									fontWeight: 500,
									color: '#3c3c3c',
									fontSize: matches ? 8 : '',
								}}>
								{/* {item.Airline_Code
									? item.Segments[0].Airline_Name
									: item.AirlineCode
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.AirlineName;
									  })
									: ''} */}
								{loading ? (
									<Skeleton variant='text' width='60px' />
								) : item.Airline_Code ? (
									item.Segments[0].Airline_Name
								) : item.AirlineCode ? (
									item.Segments[0].slice(0, 1).map((i) => {
										return i.Airline.AirlineName;
									})
								) : item.Supplier === 'TJK' ? (
									item.sI[0].fD.aI.name
								) : (
									''
								)}
							</span>
							<BodyText
								three
								textStyle={{
									fontFamily: 'Roboto Serif, sans-serif',
									// color: '#8f8f8f',
									fontSize: matches ? 10 : '',
									fontWeight: 600,
								}}>
								{item.Airline_Code
									? item.Segments[0].Airline_Code
									: item.AirlineCode
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.AirlineCode;
									  })
									: item.Supplier === 'TJK'
									? item.sI[0].fD.aI.code
									: ''}
								-
								{item.Airline_Code
									? item.Segments[0].Flight_Number
									: item.AirlineCode
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.FlightNumber;
									  })
									: item.Supplier === 'TJK'
									? item.sI[0].fD.fN
									: ''}
							</BodyText>
						</Box>
					</Box>
					<Box alignItems='center' display='flex'>
						<TitleText
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								fontSize: matches ? 16 : 22,
							}}>
							<span style={{ marginRight: 5 }}>{'USD'}</span>
							{item.Airline_Code
								? Number(
										Math.round(Number(Math.round(item.Fares.Total_Amount)))
								  )
								: item.AirlineCode
								? Number(
										Math.round(
											Number(currency?.conversion_rates?.USD) *
												Number(
													Math.round(
														Number(
															Number(Math.round(item.Fare.Total_Amount)) -
																Number(Math.round(item.Fare.MFB_Discount))
														)
													)
												)
										)
								  )
								: item.Supplier === 'TJK'
								? Number(
										Math.round(
											Number(Math.round(item.totalPriceList.fd.Total_Amount))
										)
								  )
								: ''}
						</TitleText>
						{/* <MoreVertIcon /> */}
					</Box>
				</Box>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Box>
						<span
							style={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 600,
								fontSize: matches ? 10 : 16,
							}}>
							{item.Origin
								? item.Origin
								: item.AirlineCode
								? item.FareRules[0].Origin
								: item.Supplier === 'TJK'
								? item.sI[0].da.cityCode
								: ''}
						</span>
						<TitleText three textStyle={{ fontSize: matches ? 10 : 14 }}>
							{item.Airline_Code
								? item.Segments[0].Departure_DateTime.substr(11, 5)
								: item.AirlineCode
								? item.Segments[0].slice(0, 1).map((i) => {
										return i.Origin.DepTime.substr(11, 5);
								  })
								: item.Supplier === 'TJK'
								? item.sI[0].dt.substr(11, 5)
								: ''}
						</TitleText>
					</Box>
					<Box alignItems='center' textAlign='center'>
						<BodyText
							textStyle={{
								color: 'rgb(239, 51, 57)',
								fontWeight: 700,
								fontSize: matches ? 10 : 16,
							}}>
							{timeConvert(
								item.Airline_Code
									? item.Segments.length === 2
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 1).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: item.Segments.length === 1
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: item.Segments.length === 3
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 1).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 2).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: ''
									: item.AirlineCode
									? item.Segments[0].length == 2
										? Number(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Duration;
													})
										  )
										: item.Segments[0].length == 1
										? item.Segments[0].map((i) => i.Duration)
										: item.Segments[0].length == 3
										? Number(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Duration;
													})
										  )
										: ''
									: item.Supplier === 'TJK'
									? item.sI.length == 2
										? Number(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.duration;
													})
										  )
										: item.sI.length == 1
										? item.sI.map((i) => i.duration)
										: item.sI.length == 3
										? Number(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.duration;
													})
										  )
										: ''
									: ''
							)}
						</BodyText>
						<BodyText
							two
							textStyle={{
								color: 'green',
								fontWeight: 'bold',
								fontSize: matches ? 10 : '14px',
							}}>
							{item.AirlineCode
								? item.Segments[0].length === 1
									? 'Non Stop'
									: item.Segments[0].length === 2
									? '1 Stop'
									: item.Segments[0].length === 3
									? '2 Stop'
									: ''
								: item.Airline_Code
								? item.Segments.length === 1
									? 'Non Stop'
									: item.Segments.length === 2
									? '1 Stop'
									: item.Segments.length === 3
									? '2 Stop'
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? 'Non Stop'
									: item.sI.length === 2
									? '1 Stop'
									: item.sI.length === 3
									? '2 Stop'
									: ''
								: ''}
						</BodyText>
					</Box>
					<Box>
						<span
							style={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 600,
								fontSize: matches ? 10 : 16,
							}}>
							{item.Airline_Code
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Destination;
											}
									  )
									: ''
								: item.AirlineCode
								? item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 1
									? item.Segments[0].map((it) => {
											return it.Destination.Airport.AirportCode;
									  })
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? item.sI
											.filter((i, index) => index === 0)
											.map((it) => {
												return it.aa.cityCode;
											})
									: item.sI.length === 2
									? item.sI
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.aa.cityCode;
											})
									: item.sI.length === 3
									? item.sI
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.aa.cityCode;
											})
									: ''
								: ''}
						</span>
						<TitleText three textStyle={{ fontSize: matches ? 10 : 14 }}>
							{item.Airline_Code
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: ''
								: item.AirlineCode
								? item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 1
									? item.Segments[0]
											.filter((i, index) => index === 0)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? item.sI
											.filter((i, index) => index === 0)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: item.sI.length === 2
									? item.sI
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: item.sI.length === 3
									? item.sI
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: ''
								: ''}
						</TitleText>
					</Box>
				</Box>
			</Box>
			<Box
				style={{
					padding: '10px 10px',
					background: '#f5f6f6',
					borderBottomLeftRadius: '4px',
					borderBottomRightRadius: '4px',
					// font-size: var(--fnt-11);
					borderTop: '1px solid #e2e2e2',
					// font-size: var(--fnt-12);
					color: '#666',
				}}>
				<Box justifyContent='space-between' alignItems='center' display='flex'>
					<Box display='flex' alignItems='center'>
						{loading ? (
							<Skeleton variant='text' width='60px' height='40px' />
						) : (
							<Box
								style={{
									padding: '2px 10px',
									background: item.AirlineCode
										? item.FareClassification
											? item.FareClassification.Color
											: ''
										: '#f56d6d',
									borderRadius: 4,
									boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
								}}>
								<BodyText
									one
									textStyle={{
										color: item.AirlineCode
											? item.FareClassification
												? item.FareClassification.Color === 'Blue'
													? 'white'
													: 'black'
												: 'white'
											: 'white',
									}}>
									{item.AirlineCode
										? item.FareClassification?.Type
										: item.Airline_Code
										? item.Fares.Warning === null || item.Fares.Warning === ''
											? item.Fares.FareDetails[0].FareClasses[0].Class_Desc
											: 'MBFARE'
										: item.Supplier === 'TJK'
										? item.totalPriceList.fareIdentifier
										: ''}
								</BodyText>
							</Box>
						)}
						{item.Supplier == 'TBO' ? (
							item.IsRefundable ? (
								<span
									style={{
										color: 'white',
										marginLeft: 15,
										background: 'green',
										padding: '2px 10px',
										borderRadius: 5,
										fontSize: matches ? 6 : 12,
									}}>
									R
								</span>
							) : (
								<span
									style={{
										color: 'white',
										marginLeft: 15,
										background: 'red',
										padding: '2px 10px',
										borderRadius: 5,
										fontSize: matches ? 6 : 12,
									}}>
									N
								</span>
							)
						) : item.Supplier == 'ETR' ? (
							item.Fares.Refundable ? (
								<span
									style={{
										color: 'white',
										marginLeft: 15,
										background: 'green',
										padding: '2px 10px',
										borderRadius: 5,
										fontSize: matches ? 6 : 12,
									}}>
									R
								</span>
							) : (
								<span
									style={{
										color: 'white',
										marginLeft: 15,
										background: 'red',
										padding: '2px 10px',
										borderRadius: 5,
										fontSize: matches ? 6 : 12,
									}}>
									N
								</span>
							)
						) : (
							''
						)}
					</Box>
					<Box
						onClick={() => {
							dispatch(toggleFlightModal(true));
							dispatch(
								setReturnFlightId(
									item.Airline_Code
										? item.Fares.Fare_Id
										: item.AirlineCode
										? item.ResultIndex
										: item.Supplier === 'TJK'
										? item.totalPriceList.id
										: ''
								)
							);
						}}
						display='flex'>
						<span style={{ fontSize: 10, cursor: 'pointer' }}>
							Flight Details
						</span>
						<ArrowDropDownIcon />
					</Box>
				</Box>
			</Box>
			<Box>
				<span
					style={{
						fontSize: matches ? 10 : 11,
						color: 'rgb(239, 51, 57)',
						fontWeight: 600,
					}}>
					{/* {item.AirlineCode
						? item.FareClassification?.Type
						: item.Fares[0].Warning === null || item.Fares[0].Warning === ''
						? ''
						: 'SPECIAL FARE. IT MAY TAKE 20-30 MINS FOR ISSUANCE. ISSUANCE DEPENDS UPON AVAILABILITY. PASSENGER NAME WILL BE UPDATED 12 TO 24 HOURS PRIOR TO DEPARTURE ON AIRLINE WEBSITE'} */}
				</span>
			</Box>
		</>
	);
};

export default CardFlightReturn;
